import { IOrderBy } from "../../../ui/custom-table/interface/interface.custom";
import { AccountProps, ActiveAccountsProps } from '../../../../interface';

export interface IFilterAccountsField {
	active: ActiveAccountsProps;
	balance: {
		from: number;
		to: number;
	},
	name: string;
}

export const initialStateFilterAccount: IFilterAccountsField = {
	active: {
		isActive: false,
		isBlock: false
	},
	balance: {
		from: 0,
		to: 0
	},
	name: ''
}

export interface IFilterApply {
	/**
	 * Искомій массив
	 */
	accounts: AccountProps[];
	/**
	 * поля фильтра
	 */
	filter: IFilterAccountsField;
	/**
	 * Сортировка по полю и метод (asc, desc)
	 */
	orderBy: IOrderBy;
}
