import { layoutReducer } from './reducers/layoutReducer';
import { modalReducer } from './reducers/modalReducer';

export { setupStore } from './store';
export type { AppStore, AppDispatch, RootState } from './store';

export { modalReducer } from './reducers/modalReducer';
export { layoutReducer, logOutAsync as logOut } from './reducers/layoutReducer';


export const {
  //logOut,
  saveConfig,
  setActiveHolderId,
  saveActiveRole,
  setLoading,
  setErrorMessage,
  setAuthorize,
} = layoutReducer.actions;


export const {
  showModal,
  removeModal,
} = modalReducer.actions;
