import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { Box, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import FilterAccounts from './module/FilterAccount';
import { IFilterAccountsField } from '../../module/Account/filter/interface.filter';
import  { lazy, Suspense, useMemo, useState } from 'react';
import FilterCards from './module/FilterCards';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../../hooks';
import { Loading } from '..';

const Modal = lazy( () => import('../modal/Modal') );

interface IFilterTable {
  type: 'accounts' | 'cards';

  onFilterChange(newFilter: IFilterAccountsField | any): void;

  onFilterClear?(): void;

  onFilterCommit(): void;
}

export default function FilterTable({ type, onFilterClear, onFilterChange, onFilterCommit }: IFilterTable) {
  const [ isOpenModal, setOpenModal ] = useState( false );
  const { t } = useTranslation();

  const handleFilterClear = () => {
    onFilterClear && onFilterClear();
  };

  const handleOpenFilter = () => {
    setOpenModal( true );
  };

  const handleCommitFilter = () => {
    onFilterCommit();
    setOpenModal( false );
  };


  const returnFilter = useMemo(() => {
    switch (type) {
      case 'cards': {
        return <FilterCards onFilterChange={onFilterChange} />;
      }
      case 'accounts': {
        return <FilterAccounts onFilterChange={onFilterChange} />;
      }
      default : {
        return <>Фільтр не налаштован належним чином!</>;
      }
    }
  }, [type]);

  return (
    <Box sx={{ marginLeft: '2rem' }}>

      <Tooltip title={t('module.common.table.clearFilter')} placement='top-end'>
        <IconButton color={'primary'} onClick={handleFilterClear}> <FilterAltOffIcon /> </IconButton>
      </Tooltip>

      <Tooltip title={t('module.common.table.filter')} placement='top-end'>
        <IconButton color={'primary'} onClick={handleOpenFilter}> <FilterAltIcon /> </IconButton>
      </Tooltip>
      { isOpenModal && <Suspense fallback={ <Loading type={ 'fullScreen' }/> }>
        <Modal
          handleBtnOk={ handleCommitFilter }
          isOpen={ isOpenModal }
          handleBtnCancel={ setOpenModal }
        >
          { returnFilter }
        </Modal>
      </Suspense> }
    </Box>
  );

}
