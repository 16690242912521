import { IModalAddLimit } from '../limits.interface';
import { Box, Checkbox, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import React, { useEffect, useMemo, useState } from 'react';
import { InputCheckbox, InputFilled, Modal } from '../../../../../ui';
import { PurseItemProps, RequestCardDataCreateProps } from '../../../../../../interface';
import { limitsCardService, pursesListService, cardLimitByAccountSubTypeService, cardMutation, transformResponseError } from '../../../../../../services';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';


interface IAddLimit extends PurseItemProps {
  checked?: boolean;
}

export default function ModalAddLimit({ title, isShow = false, cardId, handleClose, accountSubType }: IModalAddLimit) {
  const { t } = useTranslation();

  /* Получаем адренсную строку */
  const params = useParams<{ cardId: string, accountId: string, holderId: string }>();

  /* Получаем карту из адресной строки */
  const pCardId = useMemo(() => {return params?.cardId ?? '1';}, [params?.cardId]);

  /* Получаем все доступные кошельки карт */
  const { data: cardPurses = { purses: [] } } = pursesListService.useGetPursesQuery({});

  const { data: limitTypes = { types: [] } } = cardLimitByAccountSubTypeService.useGetCardLimitBySubTypeQuery({ accountSubType });

  /* Получаем список счетов выбранного клиента */
  const [getLimits] = limitsCardService.useLazyGetCardLimitsQuery();

  const [purses, setPurses] = useState<IAddLimit[]>([]);
  const [selectedType, setSelectedType] = React.useState('');
  const [selectedLimit, setSelectedLimit] = React.useState('');
  const [limitAmount, setLimitAmount] = useState<number | string>('');

  const [createLimit] = cardMutation.useCreateLimitMutation();

  useEffect(() => {
    if (limitTypes.types.length > 0) {
      setSelectedType(limitTypes.types[0].remark);
      setSelectedLimit(limitTypes.types[0].items[0].remark);
      setPurses(cardPurses.purses);
    }
  }, [limitTypes]);

  /* Нажатие на ОК при создании лимита */
  const handleBtnCreateLimit = () => {


    const newLimitIds = purses.filter(p => p.checked).map(p => ({ purseId: p.purseId }));
    const findType = limitTypes.types.find(type => type.remark === selectedType);

    const findLimit = findType && findType.items.find(limit => limit.remark === selectedLimit) || { id: -1 };

    const newLimitAmount: number = limitAmount === '' ? 0 : +limitAmount;

    const newLimit: RequestCardDataCreateProps = {
      limitType: findLimit.id, limitAmount: newLimitAmount, attachedPurses: newLimitIds,
    };

    createLimit({ cardId: pCardId, request: newLimit })
      .then(({ error }: any) => {
        const isError = !!error;
        transformResponseError({ isError, error: String(error), successToast: true });
        !isError && handleClose();
      });
  };

  const handleSelectAll = (e: any) => {
    const { checked, id } = e.target;
    /*
     * Проверяем литровая, гривневая, смешаная схема выбрана устанавливаем на них
     * чекбоксы активными
     */
    const newPurses = purses && purses.map(purse => {
      if (accountSubType === 0) return { ...purse, checked: checked };

      if (accountSubType === 101) {
        if (purse.purseTypeId === 0) return { ...purse, checked: checked };
      }

      if (accountSubType === 102) {
        if (purse.purseTypeId === 1) return { ...purse, checked: checked };
      }
      return purse;
    });


    setPurses([...newPurses]);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    const idx = purses && purses.findIndex(p => p.purseId === +id);

    const newPurses = [...purses];
    newPurses[idx] = { ...newPurses[idx], checked };

    setPurses(newPurses);
  };

  const renderPursesCommon = useMemo(() => {
    return purses && purses.filter(purse => {
      if (accountSubType === 101) return purse.purseTypeId === 0;
      if (accountSubType === 102) return purse.purseTypeId === 1;
    }).map(purse => (<InputCheckbox
      key={purse.purseId}
      onChange={handleChange}
      label={purse.remark}
      id={`${purse.purseId}`}
      defaultChecked={purse.checked}
    />));
  }, [purses, accountSubType]);

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedType(event.target.value);
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLimit(event.target.value);
  };

  const renderType = useMemo(() => {
    return <RadioGroup onChange={handleTypeChange}>
      {limitTypes.types.map((type, idx) => (
        <FormControlLabel key={type.remark} name={'radio-button-type'} value={type.remark}
                          control={<Radio checked={selectedType === type.remark} />}
                          label={type.remark} />))}
    </RadioGroup>;
  }, [limitTypes, selectedType, purses]);

  const renderLimit = useMemo(() => {
    /* Если есть хотя бы один не топливный кошелек не выводим лимит литры */
    if (purses.findIndex(purse => !purse.isFuel && purse.checked) !== -1) {
      return <RadioGroup onChange={handleLimitChange}>
        {limitTypes.types.length > 0 && limitTypes.types[0].items.filter(item => item.itemType !== 1).map((item, idx) => (
          <FormControlLabel key={item.remark} name={'radio-button-limit'} value={item.remark}
                            control={<Radio checked={selectedLimit === item.remark} />}
                            label={item.remark} />))}
      </RadioGroup>;
    } else {
      return <RadioGroup onChange={handleLimitChange}>
        {limitTypes.types.length > 0 && limitTypes.types[0].items.map((item, idx) => (
          <FormControlLabel key={item.remark} name={'radio-button-limit'} value={item.remark}
                            control={<Radio checked={selectedLimit === item.remark} />}
                            label={item.remark} />))}
      </RadioGroup>;
    }
  }, [limitTypes, selectedLimit, purses]);

  return <Modal
    handleBtnOk={handleBtnCreateLimit}
    isOpen={isShow}
    handleBtnCancel={handleClose}
  >
    <>
      <Typography style={{ marginBottom: '.5rem', fontWeight: '800', textAlign: 'center' }}>{title}</Typography>
      <Grid display={'flex'} alignItems={'start'}>
        <Grid sm={4} xs={12} sx={{ borderRight: '1px solid #ccc', minWidth: '330px', minHeight: '350px' }}>
          <Grid sx={{ display: 'flex' }}>

            <FormControlLabel control={<Checkbox
              id={'common'}
              icon={<IndeterminateCheckBoxIcon />} checkedIcon={<LibraryAddCheckIcon />}
              onClick={handleSelectAll} />}
                              label={t('module.cards.limits.titlePurses')}
            />
          </Grid>
          <Grid item sx={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
            {renderPursesCommon}
          </Grid>
        </Grid>
        <Grid sm={4} xs={12}
              sx={{ borderRight: '1px solid #ccc', minWidth: '250px', minHeight: '350px', paddingLeft: '.7rem' }}>
          <Typography align={'center'}>{t('module.cards.limits.titleType')}</Typography>
          {renderType}
        </Grid>
        <Grid sm={4} xs={12} sx={{
          minWidth: '250px',
          height: '350px',
          paddingLeft: '.7rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
          <Box>
            <Typography
              align={'center'}>{t('module.cards.limits.titleLimitAmount')}</Typography>
            {renderLimit}
          </Box>
          <InputFilled
            placeholder={'0'}
            type={'number'}
            label={t('module.cards.limits.newLimit.titleLimitAmount')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLimitAmount(e.target.value)}
            value={limitAmount}
          />
        </Grid>
      </Grid>
    </>
  </Modal>;
}
