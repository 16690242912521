import i18next from 'i18next';
import { CheckResponseProps } from '../../../interface';

/**  Перехват ошибок для лицензий */
export const checkNetworkErrors = ({ result, meta }: CheckResponseProps) => {
  /**
   * Проверяем ошибки сети по которым запрос не удался
   */
  const { error } = result;
  /**
   *  Если ошибка существует пробрасываем её дальше
   */
  if (error) {
    const { status, error: errorMessage } = error;

    catchStatusError(status, { result, meta });

    console.info(`META: ${JSON.stringify(meta, null, 3)}`);
    console.info(`result: ${JSON.stringify(result, null, 3)}`);
    console.info(`Помилка мережі: "status: ${status}, message: ${errorMessage}"`);

    const errorCode: any = result?.error?.data?.errorCode || -1;

    if (errorCode !== -1) {
      throw {
        errorCode: errorCode,
        errorDescription: result?.error?.data?.errorDescription,
      };
    } else {
      throw {
        errorCode: status,
        errorDescription: i18next.t(`error.${status}`, errorMessage),
      };
    }


  }
};

const catchStatusError = (status: number, { result }: CheckResponseProps) => {
  switch (status) {
    case 400:
    case 401: {
      const errorCode: any = result?.error?.data?.errorCode || -1;
      if (errorCode) {
        throw {
          errorCode: status,
          errorDescription: i18next.t(`error.${errorCode}`, 'error.UNKNOWN_ERROR'),
        };
      }
      break;
    }
    case 429 : {
      throw {
        errorCode: status,
        errorDescription: i18next.t(`error.TOO_MANY_REQUEST`, 'error.UNKNOWN_ERROR'),
      };
    }
  }
};
