import i18n from 'i18next';
import { IRow } from '../../../../../../ui/custom-table/components/Rows/Rows';
import { IColumn } from '../../../../../../ui/custom-table/components/Columns/Columns';
import React from 'react';


interface IGetColumnsCard {
  arrayChecked: boolean[];
  onCheck(cardId: string, checked: boolean): void;

  onCheckAll(checked: boolean): void;
}

export const getColumnsCard = ({ arrayChecked, onCheck, onCheckAll }: IGetColumnsCard): IColumn[] => {

  const onCheckCard = (row: IRow, e: React.ChangeEvent<HTMLInputElement>) => {
    onCheck(row.id as string, e.target.checked);
  };

  return [
    {
      field: 'checked',
      label: '',

      header: { format: 'checked', onChange: onCheckAll, arrayChecked: arrayChecked, sx: { padding: 0 } },
      cell: { format: 'check-box', onCheck: onCheckCard },
      align: 'center',
      alignData: 'center',
      width: 10,
    },
    {
      field: 'hasLimits',
      label: '',
      formatHeader: 'empty',
      header: { sx: { padding: 0 } },
      cell: { format: 'check-card-limit', tooltip: i18n.t('module.cards.tooltipNoLimit') },
      alignData: 'center',
      width: 10,
    },
    {
      field: 'cardId',
      label: i18n.t('module.cards.titleID'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'left',
      alignData: 'left',
      width: 130,
    },
    {
      field: 'fio',
      label: i18n.t('module.cards.titleName'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'left',
      alignData: 'left',
      width: 200,
    },
    {
      field: 'vin',
      label: i18n.t('module.cards.titleVin'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'left',
      alignData: 'left',
      width: 100,
    },
    {
      field: 'mobile',
      label: i18n.t('module.cards.titlePhone'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'left',
      alignData: 'left',
      width: 100,
    },
  ];
};
