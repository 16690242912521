import { styled } from '@mui/material/styles';
import { BoxProps } from '@mui/material/Box/Box';
import { Box } from '@mui/material';

type HolderCardContainerType = BoxProps & { selected: boolean };

export const HolderCardContainer = styled((props: HolderCardContainerType) => (
  <Box {...props} sx={{
    backgroundColor: props.selected
      ? 'secondary.main'
      : 'inherit',
  }} />
))(({ theme }) => ({
  position: 'relative',
  cursor: 'pointer',
  width: '97%',
  border: `1px solid ${theme.palette.primary.dark}`,
  borderRadius: 5,
  padding: '.2rem',
  marginBottom: '.4rem',
}));
