import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { transformCatchError } from '../api/errors/transform-catch-error';
import { LinkRegisterType } from '../../helpers';
import { ReqRegisterOrForgotServiceProps } from '../../interface';


export const registerOrForgotService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    registerOrForgot: builder.query<any, ReqRegisterOrForgotServiceProps>({
      async queryFn({ type, ...rest }, {}, extraOptions, fetchWithBQ) {
        try {
          const result = await fetchWithBQ({
            url: `/common/users${type === LinkRegisterType.Register ? `/registration` : `/password/change`}`,
            params: { lang: getLangIso(), ...rest },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),
  }),
});
