import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from './redux';
import i18next from 'i18next';
import { logOut, saveActiveRole, setActiveHolderId } from '../store';
import { getStorage, holdersListService, setStorage } from '../services';
import { toast } from '../utils';
import { Storage } from '../config';
import { ActiveHolderProps } from '../interface/hooks/ActiveHolderProps';
import { HolderProps } from '../interface';
import { Roles } from '../helpers';
import { useActiveRoutes } from './useActiveRoutes';

export const useActiveHolder = (type?: 'init'): ActiveHolderProps => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  /* Холдер из адресной строки */
  const { holderId, accountId } = useParams<{ holderId: string, accountId?: string }>();
  /* Холдер из локального хранилища */
  const localStorageActiveHolderId = Number(getStorage(Storage.activeHolderId));
  /* Холдер из состояния */
  const { activeHolderId, activeRole } = useAppSelector(state => state.layoutReducer);

  if (activeHolderId < 0 && localStorageActiveHolderId > 0) {
    /* Меняем состояние */
    dispatch(setActiveHolderId({ holderId: localStorageActiveHolderId }));
  }

  useEffect(() => {
    /* Если это инициализация приложения */
    if (type === 'init' || isNaN(Number(holderId)) || activeHolderId < 0) {

      /* Если холдер не записан в состояние записуем */
      if (activeHolderId < 0) {
        /* Проверяем есть ли холдер в адресной строке */
        if (Number(holderId) && Number(holderId) !== -1) {
          /* Меняем состояния и локальное хранилище */
          changeHolderId(Number(holderId));
          return;
        }

        /* Если нет пытаемся взять из локального хранилища */
        if (localStorageActiveHolderId) {
          /* Меняем состояния и локальное хранилище */
          changeHolderId(localStorageActiveHolderId);


        } else {
          /* Ошибка получения холдера по умолчанию */
          toast({ type: 'error', message: i18next.t('error.ERROR_GET_ACTIVE_HOLDER_ID') });
          dispatch(logOut());
        }
      }


      // if (isNaN(Number(holderId))) {
      //   console.log('navigate', isNaN(Number(holderId)))
      //   navigate(`holder/${localStorageActiveHolderId}`, { replace: true });
      // }
    }

  }, []);

  const changeHolderId = (newHolderId: number) => {
    /* Получаем текущий путь и параметры из адреса */
    let currentPath = window.location.pathname;

    currentPath = process.env.PUBLIC_URL
      ? currentPath.replace(process.env.PUBLIC_URL, '')
      : currentPath;

    /* Строим новый URL с измененным holderId */
    let newUrl = currentPath.replace(/\/holder\/[-\d]+/, `/holder/${newHolderId}`);

    /* Если в адресе еще есть счет его обнуляем */
    const regExp = new RegExp(/\/account\/[-\d]+/);

    if (regExp.test(newUrl)) {
      newUrl = newUrl.replace(/\/account\/[-\d]+/, `/account/0`);
    }

    /* Меняем состояние */
    dispatch(setActiveHolderId({ holderId: newHolderId }));
    /* Меняем локальное хранилище */
    setStorage(Storage.activeHolderId, newHolderId);

    /* Меняем адрес */
    if (currentPath === '/') {
      navigate(`holder/${newHolderId}`, { replace: true });
    } else
      navigate(newUrl, { replace: true });
  };

  const { data: dataHolders = { holders: [] } } = holdersListService.useGetHoldersQuery('');

  const activeHolder = useMemo(() => {
    return dataHolders.holders.find(holder => holder.holderId === activeHolderId) as HolderProps;
  }, [dataHolders.holders.length, activeHolderId]);

  if (activeHolder !== undefined) {
    /* Устанавливаем активную роль */
    dispatch(saveActiveRole({ role: activeHolder.apiRole as Roles }));
  }

  return { changeHolderId, activeHolderId, activeHolder, activeRole };
};
