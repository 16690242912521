import { Box, Card, CardMedia, Typography } from '@mui/material';
import { formatDriverCard } from '../../../utils';
import { Loading } from '../../ui';
import QRCard from './components/QRCard';
import ReactCardFlip from 'react-card-flip';
import { useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useAppSelector } from '../../../hooks';
import { qrCardService } from '../../../services/query/card.qr.service';
import { CardsProps, CardStatusProps } from '../../../interface';
import { cardStatusService } from '../../../services/query/card.status.service';

const Container = styled(Box)(() => ({
  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
  width: 310,
  height: 186,
  margin: '0 auto',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
}));

const NumCard = styled((props: any) => <Typography {...props} />)((props) => ({
  color: props.color, // "#9b9b9b",
  position: 'absolute', bottom: '30%', right: 10,
}));

const FIOCard = styled((props: any) => <Typography {...props} />)((props) => ({
  color: props.color, //"#9b9b9b",
  position: 'absolute', bottom: '5%', right: 10,
}));

const StatusCard = styled((props: any) => <Typography {...props} />)((props) => {
  const { status } = props;

  return ({
    color: status === 0 ? '#a6ec92' : '#bb0109', position: 'absolute', top: 7, left: 18,
  });
});


interface ISmartCardFlip {
  dataCard?: CardsProps;
}


export default function SmartCardFlip({ dataCard }: ISmartCardFlip) {

  const [isFrontSide, setFrontSide] = useState(false);

  const [getQR, { data: dataQR, isLoading: isLoadingQR }] = qrCardService.useLazyGetCardQRQuery();


  const { config } = useAppSelector(state => state.layoutReducer);

  const handleGetQR = async () => {
    dataCard && getQR({ cardId: dataCard.cardId });
    setFrontSide(true);
  };

  const handleFlipToFront = () => {
    setFrontSide(false);
  };

  const getStatusName = (id: number) => {
    const status = cardStatuses.statuses.find(s => s.id === id) as CardStatusProps;
    return status && status.remark;
  };

  /* Получаем все доступные статусы карт */
  const { data: cardStatuses = { statuses: [] } } = cardStatusService.useGetCardStatusesQuery('');


  const fioDriver = useMemo(() => {
    if (dataCard) {
      const { lastName = '', firstName = '' } = dataCard;
      return `${lastName} ${firstName}`;
    }
    return '';
  }, [dataCard]);


  return (<ReactCardFlip isFlipped={isFrontSide} flipDirection='horizontal' containerStyle={{ padding: '0.3rem' }}>

    <Container>
      <Card onClick={handleGetQR}>
        <CardMedia
          component='img'
          image='./img/template-card.png'
        />
      </Card>
      <StatusCard
        status={dataCard && dataCard.status}>{dataCard && getStatusName(dataCard.status)}</StatusCard>
      <NumCard color={config.driverCard.textColor}> {dataCard && formatDriverCard(dataCard.cardId)} </NumCard>
      <FIOCard color={config.driverCard.textColor}>{fioDriver}</FIOCard>
      { /*  <ContextMenu> <SmartCardContextMenu/> </ContextMenu> */}
    </Container>

    <Container onClick={handleFlipToFront}>
      {isLoadingQR ? <Loading type={'big'} /> : dataQR &&
        <QRCard qr={dataQR.qr} lifeTime={dataQR.lifeTime} flipCard={handleFlipToFront} />}
    </Container>


  </ReactCardFlip>);
}
