import i18next from 'i18next';
import { IColumn } from '../../../../../../ui/custom-table/components/Columns/Columns';
import React from 'react';
import { IRow } from '../../../../../../ui/custom-table/components/Rows/Rows';


interface CardsColumnsProps {
  disabled?: boolean;
  handleChangeCards(accountId: number, cardId: string, e: React.ChangeEvent<HTMLInputElement>): void;
}

export const getColumnsCard = ({ handleChangeCards, disabled = false }: CardsColumnsProps): IColumn[] => {


  const onCheck = (row: IRow, e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeCards(row.accountId, row.cardId, e)
  };

  return [
    {
      field: 'checked',
      label: '',
      formatHeader: 'default',
      cell: { format: 'check-box', onCheck, disabled },
      align: 'center',
      alignData: 'center',
      width: 30,
    },
    {
      field: 'cardId',
      label: i18next.t('module.cards.titleID'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'left',
      alignData: 'left',
      sortable: false,
      width: 130,
    },
    {
      field: 'fio',
      label: i18next.t('module.cards.titleName'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'left',
      alignData: 'left',
      sortable: false,
    },
    {
      field: 'vin',
      label: i18next.t('module.cards.titleVin'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'left',
      alignData: 'left',
      sortable: false,
    },
    {
      field: 'mobile',
      label: i18next.t('module.cards.titlePhone'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'left',
      alignData: 'left',
      sortable: false,
      width: 100,
    },
  ];
};
