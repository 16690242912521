import { memo, useEffect, useMemo } from 'react';
import { ContainerRow } from '../../ui';
import { InnerContainer } from '../../common';
import { Grid } from '@mui/material';
import { HolderDetailInfo, HolderAccounts } from './components';
import { holdersListService } from '../../../services';
import { HolderProps } from '../../../interface';
import { useActiveHolder } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { HolderAttributes } from './components/HolderAttributes';


export const Profile = memo(() => {
  const { t } = useTranslation();
  const { activeHolderId } = useActiveHolder();

  const  { data: dataHolders = { holders: [] } } = holdersListService.useGetHoldersQuery('');

  const activeHolder = useMemo(() => {
    return dataHolders.holders.find(holder => holder.holderId === activeHolderId) as HolderProps;
  }, [dataHolders.holders.length, activeHolderId]);

  return (
    <InnerContainer show={true}>
      <ContainerRow>
        <Grid item xs={12} md={9} style={{ flexDirection: 'column', padding: '1rem' }}>
          {/* Инфо клиента*/}
          <HolderDetailInfo holder={activeHolder} />

          {/* Финансы и счета */}
          <HolderAccounts />

          {/* Дополнительные сведения о клиенте */ }
          <HolderAttributes holder={activeHolder}/>

          {/* Дополнительные сведения о клиенте (аналитика) */ }
          <HolderAttributes holder={ activeHolder } isAnalytic/>

        </Grid>
      </ContainerRow>
    </InnerContainer>
  );
});

