// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import NumberToCyrillic from 'number-to-cyrillic';

/**
 * Конвертирование цифр в текст прописью
 */
interface INumberToCyrillic {
  /**
   * Текстовое представление после запятой
   */
  convertedFractional: string;
  /**
   *  Текстовое представление до запятой
   */
  convertedInteger: string;
  /**
   * Цифровое представление после запятой
   */
  fractional: number;
  /**
   * копійок
   */
  fractionalCurrency: string;
  /**
   *  "00"
   */
  fractionalString: string;
  /**
   * Цифровое представление
   */
  integer: number;
  /**
   * Валюта (гривень)
   */
  integerCurrency: string;
  /**
   * Короткое название валюты (грн)
   */
  shortName: string;
}

export const getNumberToString = (number: number): INumberToCyrillic => {
  return NumberToCyrillic.convert(number, { capitalize: true });
};
