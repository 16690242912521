import { Workbook } from 'exceljs';

/**
 * Получаем строки из xls шаблона Постачальник
 */
export const getSupplierBVS = (wb: Workbook) => {
  const ws = wb.worksheets[0];
  const supplier = [];
  /* Получаем данные 4,5 строки 3 столбца шаблона */
  let strIdx = 4;
  while (strIdx <= 5) {
    supplier.push(ws.getCell(strIdx, 3).value as string);
    strIdx++;
  }
  return supplier;
};
