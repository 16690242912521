import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>( () => ( {
  btnRegister: {
    "&:hover ": {
      color: "#fff",
      cursor: "pointer"
    }
  },
  container: {
    display: "flex",
    position: 'absolute',
    top: 0, left: 0, right: 0, bottom: 0,
    justifyContent: "center",
    alignItems: "center"
  }
} ) );
