import i18next from 'i18next';
import { IColumn } from '../../../../../../ui/custom-table/components/Columns/Columns';
import { IRow } from '../../../../../../ui/custom-table/components/Rows/Rows';
import React from 'react';

interface AccountColumnsProps {
  disabled?: boolean;
  handleEditAccount(accountId: number, e: React.ChangeEvent<HTMLInputElement>): void;
}

export const getColumns = ({ handleEditAccount, disabled = false}: AccountColumnsProps): IColumn[] => {

  const onCheck = (row: IRow, e: React.ChangeEvent<HTMLInputElement>) => {
    handleEditAccount(row.id as number, e);
  };

  return [
    {
      field: 'checked',
      label: '',
      formatHeader: 'default',
      cell: { format: 'check-box', onCheck, disabled },
      align: 'center',
      alignData: 'center',
      width: 30,
    },
    {
      field: 'accountId',
      label: i18next.t('module.setting.users.titleAccountId'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      width: 80,
    },
    {
      field: 'remark',
      label: i18next.t('module.setting.users.titleAccountRemark'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
    },

  ];
};
