import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo, useState } from 'react';
import { SmartCardFlip } from '../../../common';
import LimitsCardDriver from './LimitsCardDriver';
import Carousel from 'nuka-carousel';
import LastTrnForDriver from './LastTrnForDriver';
import { Center, Loading } from '../../../ui';
import { cardDriverService } from '../../../../services';
import { useTranslation } from 'react-i18next';


const WrapperCard = styled(Box)(() => ({
  '.slider-frame': {
    height: '196px !important',
  },
}));


export default function SmartCard() {
  const { t } = useTranslation();
  const { data: dataCard, isLoading: isLoadingData } = cardDriverService.useGetCardDriverQuery('');

  const [selectCardIdx, setCardIdx] = useState<number>(0);

  const renderLimits = useMemo(() => {
    const newCard = dataCard && dataCard.cards && dataCard.cards[selectCardIdx];
    return newCard && <LimitsCardDriver cardId={newCard.cardId} />;
  }, [dataCard, selectCardIdx]);

  const renderCardsList = useMemo(() => {
    return dataCard && dataCard.cards && dataCard.cards.map(card => {
      return <SmartCardFlip key={card.cardId} dataCard={card} />;
    });
  }, [dataCard]);

  const renderLastTrn = useMemo(() => {
    return dataCard && dataCard.cards && <LastTrnForDriver cardId={dataCard.cards[selectCardIdx]?.cardId} />;
  }, [dataCard, selectCardIdx]);

  if (isLoadingData) return <Loading type={'fullScreen'} />;


  if (dataCard && dataCard.cards && dataCard.cards.length === 0) {
    return <Center> <Typography sx={{
      whiteSpace: 'pre-line',
      textAlign: 'center',
    }}>{t('module.cards.qr.notExistCard')}</Typography></Center>;
  }

  return (<Box>
    <WrapperCard>
      <Carousel adaptiveHeight={true}
                afterSlide={(idx) => setCardIdx(idx)}
                swiping={true}

                withoutControls={false}
                defaultControlsConfig={{
                  prevButtonText: <Typography>&lt;</Typography>, nextButtonText: <Typography>&gt;</Typography>,
                }}
      >
        {renderCardsList}
      </Carousel>
    </WrapperCard>
    {
      <Box>

        <Carousel
          adaptiveHeight={false}
          swiping={true}
          withoutControls={false}
          defaultControlsConfig={{
            prevButtonText: <Typography>&lt;</Typography>, nextButtonText: <Typography>&gt;</Typography>,
          }}
        >
          {renderLimits}
          {renderLastTrn}
        </Carousel>

      </Box>
    }
  </Box>);
}
