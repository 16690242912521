import { makeStyles } from '@mui/styles';
import * as CSS from "csstype";
import { alpha, styled, Theme } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";

export interface CustomTheme extends Theme {
	font: any;
}

export const global: any = {


	// return {
	// 	/*  Шрифт по умолчанию  */
	// 	fontFamilyDefault: "Roboto",
	//
	// 	/*  Шрифт по умолчанию для заголовков */
	// 	fontFamilyLabelRegular: "BrutalType",
	// 	fontFamilyLabelBold: "BrutalType-Bold",
	// }
}


export const hoverLink: CSS.Properties = {
	color: "#9b9b9b"
}

export const useCustomStyles = makeStyles( ( theme: any ) => {

	const fontFamilyDefault = theme?.font?.fontFamilyDefault || 'inherit';
	const fontFamilyLabelBold = theme?.font?.fontFamilyLabelBold || 'inherit';

	return {
		titleDefault: {
			fontFamily: fontFamilyDefault,
			color: "#000",
			fontWeight: "600",
			fontSize: "16px",
			lineHeight: "21px",
			letterSpacing: "0.01em"
		},

		textDefault: {
			fontFamily: fontFamilyDefault,
			color: "#777",
			fontWeight: "500",
			fontSize: "16px",
			lineHeight: "21px",
			letterSpacing: "0.01em"
		},


		titleBold: {
			fontFamily: fontFamilyLabelBold,
			color: "#000",
			fontWeight: "700",
			fontSize: "22px",
			lineHeight: "27px"
		},

		fontDefault: {
			fontFamily: fontFamilyDefault,
		}

	}
} );

export const TextFieldFilled = styled( ( props: TextFieldProps ) => (
	<TextField
		InputProps={ { disableUnderline: true } as Partial<OutlinedInputProps> }
		sx={ { ...props.sx } }
		{ ...props }
	/>
) )( ( { theme } ) => {
	return ( {
		'& .MuiFilledInput-root': {
			border: '1px solid #e2e2e1',
			overflow: 'hidden',
			borderRadius: 4,
			backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
			transition: theme.transitions.create( [
				'border-color',
				'background-color',
				'box-shadow',
			] ),
			'&:hover': {
				border: '1px solid #000',
				backgroundColor: 'transparent',
			},
			'&.Mui-focused': {
				backgroundColor: 'transparent',
				boxShadow: `${ alpha( theme.palette.primary.main, 0.25 ) } 0 0 0 2px`,
				borderColor: theme.palette.primary.main,
			},
			'& .MuiFilledInput-input': {
				fontFamily: theme.typography.fontFamily,
			}
		},
	} )
} );


