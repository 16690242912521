import * as React from 'react';
import { useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../table/components/CustomPagination';
import Columns from './components/Columns/Columns';
import Rows from './components/Rows/Rows';
import { ICustomTable } from './interface/interface.table';
import { IOrderBy } from './interface/interface.custom';

import CustomTableContext from './helpers/table.context';
import { Loading } from '../loading';
import { Box } from '@mui/material';


export default function CustomTable({
                                      columns,
                                      rows,
                                      isLoading = false,
                                      isSimple = false,
                                      topPagination = false,
                                      topComponent,
                                      count,
                                      rowsPerPageOptions = [25, 50, 100],
                                      rowsPerPageDefault = rowsPerPageOptions[0],
                                      labelEmptyRows = 'No rows',
                                      fnRefreshData,
                                      onDoubleClick,
                                      onClick,
                                      pageNum = 0,
                                      sx,
                                      sxBody = {},
                                      selectedRow,
                                      ...rest
                                    }: ICustomTable) {

  /* Сортировка таблицы */
  const [orderBy, setOrderBy] = useState<IOrderBy>({ field: '', order: 'asc' });
  const handleChangeOrderBy = (orderBy: IOrderBy) => {
    setOrderBy(orderBy);
    fnRefreshData && fnRefreshData({ page, orderBy });
  };

  const [page, setPage] = useState<number>(1);
  const handleChangePageNum = (pageNum: number) => {
    setPage(pageNum);
    //cbChangePage && cbChangePage(pageNum);
    fnRefreshData && fnRefreshData({ page: pageNum, orderBy });
  };

  /* Подготовка полей для таблицы */
  const prepareRows = useMemo(() => {
    return rows;
  }, [rows]);

  //	<FilterContext.Provider value={ { filter } }>
  return (<CustomTableContext.Provider value={{ param: { ...rest } }}>
    <Box sx={{ width: '100%' }}>
      {
        topPagination &&
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          {topComponent}
          <CustomPagination
            count={count}
            pageNum={pageNum}
            rowsPerPageOptions={rowsPerPageOptions}
            rowsPerPageDefault={rowsPerPageDefault}
            onChangePage={handleChangePageNum}
          />
        </Box>
      }
      <TableContainer sx={{ minHeight: '90px', ...sx }}>

        <Table size={'small'} stickyHeader>
          <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
            <TableRow>
              <Columns columns={columns} orderBy={orderBy} rows={prepareRows} onChangeOrderBy={handleChangeOrderBy} />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              isLoading
                ? <Loading />
                : <Rows rows={prepareRows} columns={columns} labelEmptyRows={labelEmptyRows}
                        onDoubleClick={onDoubleClick}
                        onClick={onClick} selectedRow={selectedRow} />
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        !isSimple && <CustomPagination
          count={count}
          pageNum={pageNum}
          rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPageDefault={rowsPerPageDefault}
          onChangePage={handleChangePageNum}
        />
      }
    </Box>
  </CustomTableContext.Provider>);
}
