import { getI18n } from 'react-i18next';

export const getLangIso = () => {
  const i18n = getI18n();
  switch (i18n.language) {
    case 'ua' : {
      return 'uk';
    }
    default: {
      return i18n.language;
    }
  }
};
