import * as React from 'react';
import { ChangeEvent, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { InputFilled } from '../../../ui';
import Button from '@mui/material/Button';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { Document, Page, pdfjs } from 'react-pdf';
import { getTemplatePDF, getXLSFile, savePDFFile } from './commonMethods';
import { TTypeBill } from './Template/interface.billing';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useActiveHolder, useAppSelector } from '../../../../hooks';


const ContainerBilling = styled(Box)(() => ({
  padding: '1rem', maxWidth: '500px',
}));


export default function Billing() {
  const { t } = useTranslation();
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const { config } = useAppSelector(state => state.layoutReducer);
  const { activeHolder } = useActiveHolder();
  /**
   * Тип счета
   */
  const [selectedType, setSelectedType] = React.useState<TTypeBill>('fuel');
  const handleChangeBillType = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedType(e.target.value as TTypeBill);
  };

  /**
   * Сумма счета
   */
  const [sum, setSum] = useState<number>(0);
  const handleChangeSum = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSum(+e.target.value);
  };

  const generatePreviewPDF = (dd: TDocumentDefinitions): Promise<string> => {
    return new Promise(resolve => {
      pdfMake.createPdf(dd).getDataUrl((data) => {
        resolve(data);
      });
    });
  };

  const generateBufferPDF = async (dd: TDocumentDefinitions): Promise<Buffer> => {
    return new Promise(resolve => {
      pdfMake.createPdf(dd).getBuffer((buffer) => {
        resolve(buffer);
      });
    });
  };


  const typeBillVar = useMemo(async () => {
    let tax, shortTypeGoods, typeGoods;
    if (selectedType === 'fuel') {
      tax = config.bills.billTax.fuel;
      typeGoods = t('module.documents.billing.titleTypeFuel');
      shortTypeGoods = t('module.documents.billing.shortTitleTypeFuel');
    } else {
      tax = config.bills.billTax.goods;
      typeGoods = t('module.documents.billing.titleTypeGoods');
      shortTypeGoods = t('module.documents.billing.shortTitleTypeGoods');
    }
    return { tax, shortTypeGoods, typeGoods };
  }, [selectedType, config]);

  const [numBill, setNumBill] = useState<string>('');
  const [previewPDF, setPreviewPDF] = useState<string>('');
  /**
   * Отображаем превью пдф
   */
  const handleGetPreviewPDF = async () => {
    /* Получаем общие зависящие от типа данные */
    const { tax, shortTypeGoods, typeGoods } = await typeBillVar;
    const numBill = `${config.bills.prefix}-${moment().format('YYYYMMDDHHmmss')}`;
    setNumBill(numBill);
    const dd = await getTemplatePDF({
      numBill,
      supplier: [''],
      holder: activeHolder,
      tax,
      typeGoods,
      shortTypeGoods,
      sum,
      clientBills: config.bills.client,
    } as any);

    const filePDF = await generatePreviewPDF(dd);

    setPreviewPDF(filePDF as string);
  };

  const handleGetFilePDF = async () => {
    /* Получаем общие зависящие от типа данные */
    const { tax, shortTypeGoods, typeGoods } = await typeBillVar;
    const dd = await getTemplatePDF({
      numBill,
      supplier: [''],
      holder: activeHolder,
      tax,
      typeGoods,
      shortTypeGoods,
      sum,
      clientBills: config.bills.client,
    } as any);
    const bufferPDF = await generateBufferPDF(dd);

    bufferPDF && await savePDFFile(bufferPDF, numBill);
  };

  const handleGetFileXLS = async () => {
    /* Получаем общие зависящие от типа данные */
    const { tax, shortTypeGoods, typeGoods } = await typeBillVar;
    await getXLSFile({
      numBill, supplier: [''], holder: activeHolder, tax, typeGoods, shortTypeGoods, sum, clientBills: config.bills.client,
    } as any);
  };

  return <Grid sx={{ display: 'flex', flexWrap: 'wrap' }}>
    <Grid>
      <ContainerBilling>
        <RadioGroup onChange={handleChangeBillType} style={{ margin: '1rem 0 0 1rem' }}>
          <FormControlLabel name={'radio-button-type-bill'} value={'fuel'}
                            control={<Radio checked={selectedType === 'fuel'} />}
                            label={t('module.documents.billing.typeFuel')} />
          <FormControlLabel name={'radio-button-type-bill'} value={'goods'}
                            control={<Radio checked={selectedType === 'goods'} />}
                            label={t('module.documents.billing.typeGoods')} />
        </RadioGroup>
        <InputFilled label={t('module.common.title.titleSum')}
                     onChange={handleChangeSum} value={sum}
                     type={'number'} sx={{ margin: '2rem 0 1rem 0 !important' }} />
        <Button
          variant={'contained'}
          sx={{ width: '100%' }}
          onClick={handleGetPreviewPDF}
          disabled={sum === 0}
        >{t('module.documents.billing.titleBtnCreateInvoice')}</Button>
        {previewPDF !== '' && <>
          <Box display={'flex'} justifyContent={'space-between'} marginTop={'1rem'}>
            <Button
              variant={'contained'}

              onClick={handleGetFileXLS}
            >{t('module.documents.billing.titleBtnSaveXLSX')}</Button>
            <Button
              variant={'contained'}

              onClick={handleGetFilePDF}
            >{t('module.documents.billing.titleBtnSavePDF')}</Button>
          </Box>
        </>}


      </ContainerBilling>
    </Grid>
    <Grid overflow={'auto'}>
      {previewPDF !== '' && <Box style={{ border: '1px solid #eee' }}>
        <Document file={previewPDF}
                  loading={t('module.documents.billing.titleLoadingFile')}>
          <Page pageNumber={1} width={700} />
        </Document>
      </Box>}
    </Grid>
  </Grid>;

}
