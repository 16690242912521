import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { LimitsServiceProps } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';


export const limitListService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getLimits: builder.query<LimitsServiceProps, any>({
      async queryFn({}, {}, extraOptions, fetchWithBQ) {
        try {
          const result = await fetchWithBQ({
            url: `/common/limits/types`,
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),
  }),
});
