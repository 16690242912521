/* Форматирование номера карты для водителя */
export const formatDriverCard = (card: string) => {
  const space = 4;
  let newCard = '';

  for (let i = 0; i < card.length; i++) {
    if (i % space === 0) newCard += ' ';
    newCard += card[i];
  }

  return newCard;
};
