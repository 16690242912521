import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { HolderInfo } from '../HolderInfo';
import { useTranslation } from 'react-i18next';
import { HolderCard } from '../HolderCard';
import { HolderListButton } from '../HolderListButton';
import { useActiveHolder, useActiveRoutes, useAppDispatch, useCheckPermission } from '../../../../../../../hooks';
import { filterObject } from '../../../../../../../utils';
import { InputSearchLine, Loading } from '../../../../../../ui';
import { HolderInfoProps, HolderProps, ResAuthApiServiceProps } from '../../../../../../../interface';
import { holdersListService, setStorage, tokenService } from '../../../../../../../services';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes, Storage } from '../../../../../../../config';
import { saveActiveRole, setActiveHolderId } from '../../../../../../../store';


export const HolderList: FC<HolderInfoProps> = memo((props) => {
  const { t } = useTranslation();
  const { changeHolderId, activeRole, activeHolderId } = useActiveHolder();


  const { data: dataHolders = { holders: [] }, isLoading } = holdersListService.useGetHoldersQuery('');
  const [selectedHolder, setSelectedHolder] = useState(Number(props.id));
  const [searchStr, setSearchStr] = useState('');
  const [firstRender, setFirstRender] = useState(true);
  const [retakeToken, { isLoading: isLoadingRetakeToken }] = tokenService.useLazyRetakeTokenQuery();
  const handleOnChangeSearchStr = ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => { setSearchStr(value); };

  const allowRoutes = useActiveRoutes();
  const location = useLocation();
  const { checkPermissionRoute } = useCheckPermission();
  const navigate = useNavigate();

  useEffect(() => {

    if (!firstRender) {
      const foundedLink = routes.find(route => {
        const regExpString = route.link.replace(/\/:\w+/g, '/.*');
        const regExp = new RegExp('^' + regExpString + '(?:\\?.*)?$');
        return regExp.test(location.pathname);
      });

      /*
       * Находим текущий линк проверяем его параметр на обновление страницы, в случае перехода на другого
       * проверяем его параметр, а также доступность линка для текущей роли
       */
      const isNeedRefresh = foundedLink && foundedLink.isRefreshOnChangeHolder && checkPermissionRoute(foundedLink);

      if (!isNeedRefresh) {
        const link = allowRoutes[0].link.replace(':holderId', activeHolderId.toString());
        navigate(link, { replace: true });
      }

    }
  }, [activeHolderId, activeRole, firstRender]);

  const handleOnSelect = async () => {
    setFirstRender(false);
    await retakeToken({ holderId: selectedHolder })
      .then(() => {
        changeHolderId(selectedHolder);
        props.onClose && props.onClose();
      });
  };

  const handleOnClick = (holderId: number) => { setSelectedHolder(holderId); };

  const rows = useMemo(() => {
    return filterObject({ arr: dataHolders.holders, fields: ['okpo', 'name'], searchStr });
  }, [dataHolders, searchStr]);

  const renderHolders = useMemo(() => {
    return rows.map((holder: HolderProps) => <HolderCard key={holder.id} holder={holder} selectedId={selectedHolder}
                                                         onClick={handleOnClick} />);
  }, [rows, props.id, handleOnClick]);


  return (
    <Box sx={{ padding: '1rem', display: 'flex', flexDirection: 'column', width: '300px' }}>
      <Box sx={{ display: 'flex', width: '300px' }}>
        <HolderInfo {...props} />
      </Box>
      { /* Если фирм больше 4 отображаем поисковую строку */
        dataHolders.holders.length > 4 &&
        <InputSearchLine placeholder={t('module.common.title.titleSearchStr')} onChange={handleOnChangeSearchStr} />
      }

      <Box
        sx={{
          display: 'block',
          flexDirection: 'column',
          marginTop: '1rem',
          padding: '.4rem .2rem',
          height: '215px',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}>

        {
          isLoading
            ? <Loading type={'small'} />
            : renderHolders
        }

      </Box>
      <HolderListButton onSelect={handleOnSelect} onClose={() => props.onClose && props.onClose()}
                        isLoading={isLoadingRetakeToken} />
    </Box>
  );
});
