import { QRCodeSVG } from 'qrcode.react';
import { Box, Card } from '@mui/material';
import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { ProgressTimer } from '../../../ui';


const TimerBox = styled(Box)(() => ({
  position: 'absolute',
  right: 5, top: 2,
  zIndex: 1,
}));

interface IQRCard {
  qr: string;
  lifeTime: number;

  flipCard(): void;
}

export default function QRCard({ qr, lifeTime, flipCard }: IQRCard) {

  const handleTimeoutShowing = () => {
    flipCard();
  };

  const dataQR = useMemo(() => {
    return <ProgressTimer timer={lifeTime} type={'medium'} isUpdateTimer={qr} onStopTimer={handleTimeoutShowing} />;
  }, [qr, lifeTime]);

  return (
    <Card sx={{ boxSizing: 'border-box', padding: '10px 0 10px 0', boxShadow: 'none' }}
          onClick={flipCard}>
      <QRCodeSVG value={qr} />
      <TimerBox>{dataQR}</TimerBox>
    </Card>
  );
}
