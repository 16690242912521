import { RouteProps } from '../interface';
import { routes } from '../config';
import { useCheckPermission } from './useCheckPermission';

export const useActiveRoutes = (): RouteProps[] => {
  const { checkPermissionRoute } = useCheckPermission();

  return routes
    /* Проверяем что это просто ссылка для создания route */
    .filter(route => route.title !== undefined)
    /* По-другому проверяем разрешение */
    .filter(route => checkPermissionRoute(route));
};
