import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const SCBoxContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 600,
  whiteSpace: 'pre-line',
}));
