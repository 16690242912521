import { Grid } from '@mui/material';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { holdersListService, transformResponseError, userProfileService } from '../../../../../../services';
import { InputFilled, Loading } from '../../../../../ui';
import InputPhone from '../../../../../ui/input/InputPhone';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import ComboBox from '../../../../../ui/input/ComboBox/ComboBox';
import { usersMutation } from '../../../../../../services/mutation/users.service';
import { initialUser, UserProps } from '../../../../Users/components/ModalUserEditor/interface';
import { RequestUsersProps } from '../../../../../../interface';

export const EditCurrentProfile = memo(() => {
  const { t } = useTranslation();
  const {
    data: dataProfile = {
      user: {
        lastName: '',
        firstName: '',
        phoneNumber: '',
        email: '',
        userCanEdit: false,
        commonHolderId: -1,
        isReadOnlyProfile: false,
      },
    },
    isLoading: isLoadingProfile,
  } = userProfileService.useGetUserProfileQuery({});

  const { data: dataHolders = { holders: [] } } = holdersListService.useGetHoldersQuery({ withEmptyLine: false });

  const [user, setUser] = useState<UserProps>(initialUser);

  const [updateUserProfile, { isLoading: isLoadingUpdating }] = usersMutation.useCreateOrUpdateUsersMutation();

  useEffect(() => {
    if (dataProfile.user && dataHolders.holders.length > 0) {
      /* Если основной клиент присутствует в списке доступных */
      if (dataHolders.holders.findIndex(holder => holder.holderId === dataProfile.user.commonHolderId) !== -1) {
        setUser(dataProfile.user);
      } else {
        /* Если его нет выбираем, первого по списку из доступных */
        setUser({ ...dataProfile.user, commonHolderId: dataHolders.holders[0].holderId });
      }
    }
  }, [dataProfile, dataHolders]);

  const handleChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleChangeDataCommonHolderId = (newId: number) => {
    setUser(prev => ({ ...prev, commonHolderId: newId }));
  };

  const isDisabledEdit = useMemo(() => {
    return dataProfile.user.isReadOnlyProfile;
  }, [dataProfile]);

  const handleUpdateUserProfile = () => {
    const prepareDataForUpdateProfile: any = {
      id: user.id || -1,
      lastName: user.lastName,
      firstName: user.firstName,
      phoneNumber: user.phoneNumber,
      email: user.email,
      commonHolderId: user.commonHolderId,
    };

    updateUserProfile(prepareDataForUpdateProfile as RequestUsersProps)
      .then(({ error }: any) => {
        const isError = !!error;
        transformResponseError({ isError, error: String(error), successToast: true });
      });

  };

  /* Находим холдера по умолчанию */
  const commonHolderId = useMemo(() => {
    const cHolderId = dataHolders.holders.find(holder => holder.holderId === user.commonHolderId);
    /* Если холдера не нашли то выводим -1 (Не обрано), в дргуих случаяъ реального холдера */
    return !cHolderId || cHolderId.id === -1 ? -1 : user.commonHolderId;
  }, [user.commonHolderId, dataHolders.holders]);

  if (isLoadingProfile) {
    return <Loading type={'medium'} />;
  }

  return (
    <Grid item xs={12} md={6}>
      <Grid item xs={12}>
        <Grid mb={1} display={'flex'}>
          <InputFilled
            disabled={isDisabledEdit}
            name={'lastName'}
            label={t('module.setting.users.titleLastName')}
            onChange={handleChangeData}
            value={user.lastName}
            sx={{ marginRight: '3px' }}
          />
          <InputFilled
            disabled={isDisabledEdit}
            name={'firstName'}
            label={t('module.setting.users.titleFirstName')}
            onChange={handleChangeData}
            value={user.firstName}
          />
        </Grid>
        <Grid display={'flex'}>
          <InputPhone
            disabled={isDisabledEdit}
            name={'phoneNumber'}
            sx={{ marginRight: '3px', '& .Mui-disabled': { backgroundColor: 'transparent !important' } }}
            inputType={'filled'}
            value={user.phoneNumber}
            onChange={handleChangeData}
            label={t('module.setting.users.titlePhone')}
          />

          <InputFilled
            disabled={isDisabledEdit}
            name={'email'}
            label={t('module.setting.users.titleEmail')}
            onChange={handleChangeData}
            value={user.email}
          />
        </Grid>
        <Grid display={'flex'}>
          <Grid item xs={6} pt={1} sx={{ marginRight: '2px' }}>
            <ComboBox
              name={'commonHolderId'}
              textStyle={{ fontWeight: '700' }}
              data={dataHolders.holders}
              defaultValue={commonHolderId}
              fieldLabelOption={'name'}
              label={t('module.setting.users.titleHolderDefault')}
              fnOnChange={handleChangeDataCommonHolderId}
              disabled={isDisabledEdit}
            />
          </Grid>
        </Grid>
        <Grid>
          <LoadingButton
            disabled={isDisabledEdit}
            loading={isLoadingUpdating}
            variant='contained'
            style={{ width: '100%', marginTop: '1rem', padding: '.7rem 0' }}
            onClick={handleUpdateUserProfile}>
            {t('module.setting.titleBtnChangeProfile')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
});
