import i18next from 'i18next';
import { LayoutReducerState } from '../../../interface';
import { Roles } from '../../../helpers';


export const initialLayoutState: LayoutReducerState = {
  isAuth: false,
  isLoading: false,
  activeHolderId: -1,
  activeRole: Roles.Empty,
  activeMenuId: -1,
  errorMessage: undefined,
  config: {
    locale: {
      auth: {
        enterCRM: i18next.t('config.locale.auth.enterCRM'),
      },
      language: [
        {
          langISO: 'uk',
          label: 'UA',
        },
        {
          langISO: 'en',
          label: 'EN',
        },
        {
          langISO: 'ru',
          label: 'RU',
        },
      ],
    },
    driverCard: {
      textColor: '#ccc',
    },
    menu: {
      link: {
        color: {
          text: '#cdcdcd',
          hover: '#9b9b9b',
          bgActiveLink: 'rgba(101,101,101,0.5)',
        },
      },
    },
    createVirtualCard: false,
    moneyTransfer: true,
    accessControl: false,
    showPublicApiKey: true,
    documents: true,
    certificatesOfWorkPerformed: true,
    loginFormat: 1,
    companyInfoAfterLoginLocated: '<div style="text-align: center">сайт кабінету - <a href="https://demo.itbi.com.ua/smart-cards/">https://demo.itbi.com.ua/smart-cards/</a>\nтелефон гарячої лінії - 0 800 000 000\nпошта для звернень з будь яких питань - <a href="mailto:hotline@itbi.com.ua">hotline@itbi.com.ua</a></div>',
    bills: {
      client: 'Socar',
      prefix: 'СК',
      billTax: {
        fuel: 7,
        goods: 20,
      },
    },
  },
  files: {},
  links: [
    {
      title: 'Ссылка',
      url: 'https://google.com',
    },
    {
      title: 'Ссылка',
      url: 'https://google.com',
    },
  ],
  profile: {
    palette: {
      mode: 'light',
      primary: {
        main: '#000',
        light: '#fff',
        dark: '#999',
        contrastText: '#d3d3d3',
      },
      secondary: {
        main: '#858585',
        light: '#6ea6ff',
        dark: '#004da7',
        contrastText: '#fdfdfd',
      },
      contrastThreshold: 3,
      divider: '#000',
    },
    typography: {
      fontFamily: 'Comfortaa-Regular',
    },
    font: {
      fontFamilyDefault: 'Comfortaa-Regular',
      fontFamilyLabelBold: 'Comfortaa-Bold',
    },
  },
};
