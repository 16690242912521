import React, { ChangeEvent, memo, useEffect, useMemo, useState } from 'react';
import { accountsService, moneyTransferMutation, purseService, transformResponseError } from '../../../../../services';
import { useTranslation } from 'react-i18next';
import { useActiveHolder, useModal } from '../../../../../hooks';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { LoadingButton } from '@mui/lab';
import { AccountProps, PurseProps, RequestMoneyTransferProps } from '../../../../../interface';
import { initialMoneyTransfer, initialMoneyTransferTempProps, MoneyTransferTempProps } from '../../config';
import { ButtonType, IconButton, ComboBoxIcon, ComboBox, InputSum } from '../../../../ui';
import { ModalButton } from '../../../../../config';
import { Cards } from '../Cards';


export const Accounts = memo(() => {
  const { t } = useTranslation();
  const notSelected = useMemo(() => ([{ id: -1, label: t('module.common.filter.titleNotSelected') }]), [t]);

  const { activeHolderId } = useActiveHolder();

  const [request, setRequest] = useState<RequestMoneyTransferProps>(initialMoneyTransfer);
  const [options, setOptionTransfer] = useState<MoneyTransferTempProps>(initialMoneyTransferTempProps);

  const [setMoneyTransfer, { isLoading: isLoadingMoneyTransfer }] = moneyTransferMutation.useSetMoneyTransferMutation();
  const handleTransferMoney = async () => {
    await setMoneyTransfer({ transferData: request, holderId: activeHolderId })
      .then(({ error }: any) => {
        const isError = !!error;
        transformResponseError({ isError, error: String(error), successToast: true });
        if (!isError) {
          setRequest(initialMoneyTransfer);
          setOptionTransfer(initialMoneyTransferTempProps);
        }
      });
  };

  const handleConfirmTransfer = () => {
    showModal({
      buttonType: ModalButton.OkCancel,
      children: t('module.transfer.confirmTransfer'),
      callbackOk: handleTransferMoney,
    });
  };


  const {
    data: dataAccounts = { accounts: [] },
  } = accountsService.useGetAccountsByHolderIdQuery({ holderId: activeHolderId });

  const [getPurses, { data: dataPurses = { purses: [] } }] = purseService.useLazyGetPursesByAccountIdQuery();


  /* Получаем кошельки в зависимости от выбора счета отправителя */
  useEffect(() => {
    request.sourceAccountId !== -1 && getPurses({ accountId: request.sourceAccountId || -1 });
  }, [request.sourceAccountId]);

  /**
   * Список счетов
   */
  const accountList: any[] = useMemo(() => {
    return [...notSelected,
      ...dataAccounts.accounts.map(account => {

        let icon = 'icons-default.png';
        if (account.accountSubType === 101) icon = 'icons-money.png';
        if (account.accountSubType === 102) icon = 'icons-drop.png';

        let accountIdStr = account.accountId.toString();
        for (let i = accountIdStr.length; i <= 4; i++) {
          accountIdStr = '0' + accountIdStr;
        }

        return ({ label: `[${accountIdStr}] ${account.remark}`, icon: `/transfer/${icon}`, ...account });
      })];
  }, [dataAccounts, notSelected]);

  const purseList = useMemo(() => {

    /* Поле с гривнами по умолчанию */
    const defaultMoney = { id: 0, label: t('module.transfer.defaultMoney') };

    let defaultPurse: any[] = [];

    if ([0, 101].indexOf(options.sourceAccountSubType) !== -1) {
      defaultPurse = [...defaultPurse, defaultMoney];
    }

    /* Если счет не гривневый добавляем виды топлива */
    if ([101].indexOf(options.sourceAccountSubType) === -1) {

      defaultPurse = [...defaultPurse, ...dataPurses.purses.map((purse: PurseProps) => ({
        ...purse, label: purse.remark,
      }))];

    }


    return [...notSelected, ...defaultPurse];
  }, [dataPurses, notSelected, options, t]);

  const handleChangeDestination = (newValue: any) => {
    setRequest(prev => ({ ...prev, destinationAccountId: newValue.accountId }));
    setOptionTransfer(prev => ({ ...prev, destinationAccountSubType: newValue.accountSubType }));
  };

  const handleChangeSource = (newValue: any) => {
    setRequest(prev => ({ ...prev, destinationAccountId: -1, purseId: -1, value: 0, sourceAccountId: newValue.id }));
    setOptionTransfer(prev => ({ ...prev, sourceAccountSubType: newValue.accountSubType }));
  };

  const handleSumChange = (e: ChangeEvent<HTMLInputElement>) => {
    const sum = e.target.value;
    setRequest(prev => ({ ...prev, value: +sum }));
  };

  const handlePurseChange = (newPurseId: number) => {
    setRequest(prev => ({ ...prev, purseId: newPurseId }));
  };


  /**
   * Поиск счета по карте
   */
  const { showModal, closeModal } = useModal();

  const handleOpenFindAccountByCard = (name: string) => {

    const handleRequestChange = (name: string, value: any) => {

      const selectedAccountByCard = accountList.find(account => account.accountId === value);

      if (name === 'sourceAccountId') {
        handleChangeSource(selectedAccountByCard);
      }

      if (name === 'destinationAccountId') {
        handleChangeDestination(selectedAccountByCard);
      }

    };

    showModal({
      buttonType: ModalButton.Empty,
      children: <Cards onSelect={(value) => handleRequestChange(name, value)} onClose={closeModal}
                       accountListDestination={name === 'destinationAccountId' ? accountListDestination : []} />,
    });
  };

  /* Отображаем доступную сумму по выбранному счету/кошельку */
  const renderSumSource: number = useMemo(() => {
    if (request.sourceAccountId === -1) return 0;

    /* Если это гривневый счет тогда выводим сумму на счету основную */
    if (request.purseId !== 0 && dataPurses) {
      return dataPurses && dataPurses.purses.find((purse: PurseProps) => purse.id === request.purseId)?.amount || 0;
    }

    return accountList.find((account: AccountProps) => account.accountId === request.sourceAccountId).totalBalanceWithoutOverdraft;

  }, [accountList, request, dataPurses]);

  /**
   * Список аккаунтов получателя с фильтрами отображения
   */
  const accountListDestination = useMemo(() => {
    return accountList.map(account => {

      if (account.id === -1) return { ...account };

      /* Если не выбран вид топлива тогда все неактивные */
      if (request.purseId === -1) return ({ ...account, disabled: true });

      /* Если тип счета смешаный, тогда проверяем какая выбрана валюта */
      if (options.sourceAccountSubType === 0) {
        /* Если выбрано перевод с общего счета тогда для него подходят только 101 и 0 */
        if (request.purseId === 0) {
          if ([0, 101].indexOf(account.accountSubType) === -1) {
            return ({ ...account, disabled: true });
          }
        } else {
          if ([0, 102].indexOf(account.accountSubType) === -1) {
            return ({ ...account, disabled: true });
          }
        }
      }


      /* Если тип счета денежный, для него подходят только 0 и 101 */
      if (options.sourceAccountSubType === 101) {
        /* Если выбрано перевод с общего счета тогда для него подходят только 101 и 0 */
        if ([0, 101].indexOf(account.accountSubType) === -1) {
          return ({ ...account, disabled: true });
        }
      }

      /* Если тип счета денежный, для него подходят только 0 и 102 */
      if (options.sourceAccountSubType === 102) {
        /* Если выбрано перевод с общего счета тогда для него подходят только 102 и 0 */
        if ([0, 102].indexOf(account.accountSubType) === -1) {
          return ({ ...account, disabled: true });
        }
      }

      if (request.sourceAccountId === account.accountId) {
        return ({ ...account, disabled: true });
      }

      return { ...account };

    });
  }, [options, request, t]);

  const disabledButton = useMemo(() => {
    /* Если не корректная сумма */
    if (request.value <= 0) return true;

    /* Если не заполнено отправитель/получатель  */
    if (request.sourceAccountId === -1 || request.destinationAccountId === -1) return true;

    /* Нельзя переводить между одного и того счета */
    if (request.sourceAccountId === request.destinationAccountId) return true;

    if (renderSumSource - request.value < 0) return true;

  }, [request, renderSumSource]);

  /* Отображение label отправителя средств */
  const renderLabelSourceAccount = useMemo(() => {

    /* Если кошелек не выбран */
    if (request.purseId === -1) return t('module.transfer.labelRestOfMoneyBeforeChooseAccount');

    /* Если сумма на кошельке минус или 0 */
    if (renderSumSource <= 0) return t('module.transfer.notEnoughMoney');

    return t('module.transfer.labelRestOfMoney', { sum: renderSumSource?.formatNumber(2) });

  }, [renderSumSource, request]);


  return (
    <Grid item xs={12} md={5}>
      <Card sx={{ marginTop: '1rem', padding: '1rem', border: '1px solid #ccc' }}>

        <Typography>{t('module.transfer.sourceAccountName')}:</Typography>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>

            <IconButton type={ButtonType.Search} onClick={handleOpenFindAccountByCard.bind(null, 'sourceAccountId')} />

            <ComboBoxIcon
              textStyle={{ fontWeight: '700' }}
              data={accountList}
              fieldLabelOption={'label'}
              label={renderLabelSourceAccount}
              fnOnChange={handleChangeSource}
              callbackType={'fullData'}
              defaultValue={request.sourceAccountId}
              error={request.purseId !== -1 && renderSumSource <= 0}
            />

          </Box>

          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginTop: '1rem',
            paddingLeft: '2rem',
          }}>

            <InputSum
              key={`${request.sourceAccountId}_${request.purseId}`}
              label={t('module.common.title.titleSum')}
              sx={{ '& .MuiInputBase-input': { textAlign: 'right' }, marginRight: '.2rem' }}
              placeholder={'0.00'}
              onChange={handleSumChange}
              disabled={request.purseId === -1 || renderSumSource <= 0}
              error={renderSumSource > 0 && renderSumSource - request.value < 0}
            />

            <ComboBox
              textStyle={{ fontWeight: '700' }}
              data={purseList}
              fieldLabelOption={'label'}
              label={`${request.sourceAccountId === -1
                ? t('module.transfer.labelMoneyBeforeChooseAccount')
                : t('module.transfer.labelMoney')}`
              }
              defaultValue={request.purseId}
              fnOnChange={handlePurseChange}
              disabled={request.sourceAccountId === -1}
            />


          </Box>
        </CardContent>
      </Card>

      <Box sx={{ width: '100%', textAlign: 'center', marginTop: '.5rem' }}>
        <KeyboardDoubleArrowDownIcon sx={{ fontSize: '2rem' }} />
      </Box>

      <Card sx={{ padding: '1rem', border: '1px solid #ccc' }}>

        <Typography>{t('module.transfer.destinationAccountName')}:</Typography>

        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>

            <IconButton type={ButtonType.Search}
                        disabled={request.purseId === -1 || renderSumSource <= 0}
                        onClick={handleOpenFindAccountByCard.bind(null, 'destinationAccountId')} />

            <ComboBoxIcon
              textStyle={{ fontWeight: '700' }}
              data={accountListDestination}
              fieldLabelOption={'label'}
              label={t('module.common.title.titleAccountComboBox')}
              fnOnChange={handleChangeDestination}
              callbackType={'fullData'}
              defaultValue={request.destinationAccountId}
              disabled={request.purseId === -1 || renderSumSource <= 0}
            />

          </Box>

        </CardContent>
      </Card>

      <LoadingButton sx={{ marginTop: '1rem', padding: '10px 12px', width: '100%' }} disabled={disabledButton}
                     variant={'contained'}
                     loading={isLoadingMoneyTransfer}
                     onClick={handleConfirmTransfer}
      >{t('module.transfer.titleTransferButton')}</LoadingButton>
    </Grid>
  );
});
