import { CustomTable, DatePicker, OutlineBox } from '../../../ui';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getColumns } from './certificatesOfWorkPerformed.columns';
import { IFilter, ISingleRow } from '../../../ui/custom-table/interface/interface.custom';
import { REPORT_COUNT_PER_ON_PAGE } from '../../../../config';
import { useActiveHolder, useMediaQuery } from '../../../../hooks';
import { useTranslation } from 'react-i18next';
import { documentsListService, downloadFileMutation } from '../../../../services';
import AutorenewIcon from '@mui/icons-material/Autorenew';


/* Кол-во строк в таблице счетов, на странице инфо клиента */
const countPerOnPage: number = REPORT_COUNT_PER_ON_PAGE ? +REPORT_COUNT_PER_ON_PAGE : 5;

interface IFilterLocalState {
  fromDate: string;
  toDate: string;
  holderId: number;
  page: number;
  limit: number;
}

const initialStateFilter = {
  fromDate: moment().format('YYYY-MM-01'),
  toDate: moment().format('YYYY-MM-DD'),
  holderId: -1,
  page: 0,
  limit: countPerOnPage,
};

export default function CertificatesOfWorkPerformed() {
  const { t } = useTranslation();
  const { activeHolderId } = useActiveHolder();
  /* Инициализация медиа  */
  const matches = useMediaQuery();

  const [filter, setFilterState] = useState<IFilterLocalState>(initialStateFilter);

  const [getDocuments, {
    data: dataDocs = { docs: [], countOfRows: countPerOnPage }, isFetching: isLoadingDocs,
  }] = documentsListService.useLazyGetDocumentsQuery();

  useEffect(() => { getDocuments({ ...filter, holderId: activeHolderId }); }, [activeHolderId]);

  const [getDownloadFile] = downloadFileMutation.useLazyDownloadFileQuery();


  const dateTimePicker = useMemo(() => {

    const dateStart = moment(filter.fromDate).format('YYYY-MM-DD');
    const dateEnd = moment(filter.toDate).format('YYYY-MM-DD');

    const picker: [string, string] = [dateStart, dateEnd];

    return picker;

  }, [filter]);

  const handleCommitFilter = useCallback(() => {
    getDocuments({ ...filter, holderId: activeHolderId });
  }, [filter, activeHolderId]);

  /* Текущая страница счетов */
  const handleChangePage = ({ page, orderBy }: IFilter) => {

    const customFilter = filter.holderId === -1
      ? { ...filter, holderId: activeHolderId }
      : { ...filter };

    getDocuments({ ...customFilter, page: page - 1, limit: countPerOnPage });
  };

  const handleDownloadFile = async ({ row }: ISingleRow) => {
    const fileName = row.fileName ? row.fileName : moment().format('YYYYMMDDHHmmss');
    await getDownloadFile({ url: `/common/docs/${row.id}/file`, fileName });
  };

  /* Заполняем табличные заголовки */
  const columns = useMemo(() => {
    return getColumns({ onDownloadFileExcel: handleDownloadFile });
  }, [handleDownloadFile]);

  /* Фильтр по столбцам таблицы  */
  const rows = useMemo(() => {
    const count = 10;
    const filteredArr: any[] = dataDocs.docs;

    return { arr: filteredArr, count };
  }, [dataDocs]);


  const handleChangeRangeOfDate = (dateType: 'fromDate' | 'toDate', rangeDate: any) => {

    let filterDateRange = {};
    if (rangeDate.length !== 2) {

      filterDateRange = {
        ...filter,
        [dateType]: moment(rangeDate).format('YYYY-MM-DD'),
      };

    } else {

      filterDateRange = {
        ...filter,
        fromDate: moment(rangeDate[0]).format('YYYY-MM-DD'),
        toDate: moment(rangeDate[1]).format('YYYY-MM-DD'),
      };


    }


    const newFilter = {
      ...filter, ...filterDateRange,
    };


    setFilterState(newFilter);

  };

  return <Box style={{ maxWidth: `${matches ? '100%' : '60%'}` }}>
    <Grid container sx={{ display: 'flex', marginBottom: '.5rem' }}>

      <Grid item xs={12} md={6} display={'flex'} flexDirection={matches ? 'column' : 'row'}>
        <Grid item xs={9} md={6}>
          <DatePicker
            labelInput={t('module.common.title.dateFrom')}
            onChange={handleChangeRangeOfDate.bind(null, 'fromDate')}
            pickerValue={moment(dateTimePicker[0])}
          />
        </Grid>
        <Grid item xs={9} md={6}>
          <DatePicker
            labelInput={t('module.common.title.dateTo')}
            onChange={handleChangeRangeOfDate.bind(null, 'toDate')}
            pickerValue={moment(dateTimePicker[1])}
          />
        </Grid>
      </Grid>
      <Grid item xs={9} md={1} textAlign={'center'}>
        <OutlineBox style={{ padding: '2.1px', textAlign: 'center' }}>
          <IconButton color={'primary'} onClick={handleCommitFilter}>
            <Tooltip title={t('module.common.title.titleExecute')}>
              <AutorenewIcon fontSize={'large'} />
            </Tooltip>
          </IconButton>
        </OutlineBox>


      </Grid>

    </Grid>
    <Grid item xs={12}>
      <CustomTable
        rows={rows.arr}
        columns={columns}
        isLoading={isLoadingDocs}
        count={dataDocs.countOfRows}
        labelEmptyRows={t('module.common.table.emptyRows')}
        rowsPerPageOptions={[countPerOnPage]}
        fnRefreshData={handleChangePage}
      />
    </Grid>
  </Box>;

}
