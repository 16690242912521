import * as React from 'react';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import { Grid } from '@mui/material';
import { TabContext } from '@mui/lab';
import Billing from './Billing/Billing';
import CertificatesOfWorkPerformed from './CertificatesOfWorkPerformed/CertificatesOfWorkPerformed';
import { useAppSelector } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { InnerContainer } from '../../common';


export default function Documents() {
  const { t } = useTranslation();
  const [selectedTab, setTab] = React.useState('1');

  const { config } = useAppSelector(state => state.layoutReducer);

  const handleChangeTab = (event: React.SyntheticEvent, newTab: string) => {
    setTab(newTab);
  };

  return <>
    <InnerContainer direction={'left'} show={true}>
      <Grid container style={{ flexDirection: 'column' }}>

          <TabContext value={selectedTab}>
            <TabList onChange={handleChangeTab} aria-label='lab API tabs example'>
              <Tab label={t('module.documents.billing.titleTab')} value='1' />
              {
                config.certificatesOfWorkPerformed &&
                <Tab label={t('module.documents.certificates.titleTab')}
                     value='2' />
              }
            </TabList>
            <TabPanel value='1'>
              <Billing />
            </TabPanel>
            <TabPanel value='2'>
              <CertificatesOfWorkPerformed />
            </TabPanel>
          </TabContext>

      </Grid>
    </InnerContainer>
  </>;
}
