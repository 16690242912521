import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { UserItemProps, UserListServiceProps } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';


export const userListService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getUserList: builder.query<UserListServiceProps, { phoneNumber?: string }>({
      async queryFn({ phoneNumber = '' }, {}, extraOptions, fetchWithBQ) {
        try {

          const result = await fetchWithBQ({
            url: `/users`,
            params: { phoneNumber, lang: getLangIso() },
          });

          const data = result && checkErrors<any>({ result, meta: result.meta });

          /* Суммируем все массивы и объединяем ФИО */
          data.users = data.users?.map((user: UserItemProps) => {
            return {
              ...user,
              /* ФИО */
              fio: `${user?.lastName || ''} ${user?.firstName || ''}`,
              /* Кол-во холдеров привязанных к пользователю */
              holdersLength: user.holders?.length,
              holders: user?.holders?.map(holder => ({
                ...holder,
                accessAccountsLength: holder.accessAccounts?.length,
                accessAccounts: holder?.accessAccounts?.map(account => ({
                  ...account,
                  accessCardsLength: account.accessCards?.length,
                })),
              })),
            };
          });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      providesTags: () => ['Users'],
    }),
  }),
});
