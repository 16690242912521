import * as React from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';
import { useCustomStyles } from '../../../styles';
import { useTranslation } from 'react-i18next';
import { Button as Btn } from '../button/Button';
import { LoadingButtonProps } from '@mui/lab/LoadingButton/LoadingButton';


export type IModalType = 'error' | 'default' | 'withoutBtn';

export interface IModalMessage {
  isOpen: boolean;
  loading?: boolean;
  type?: IModalType;
  children?: React.ReactElement<any, any>;
  btnOkTitle?: string;
  btnCancelTitle?: string;
  disabled?: boolean;
  sx?: CSSProperties,

  test?(): void;

  handleBtnOk?(): void;

  handleBtnCancel?(state: boolean): void;
}

const CustomDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    maxWidth: 'initial', borderRadius: '7px',
  },
}));

const CustomDialogContent = styled(DialogContent)(() => ({
  overflowY: 'auto', flex: '1 1 auto', whiteSpace: 'pre-wrap',
}));

const Button = styled((props: LoadingButtonProps) => (
  <Btn  {...props} variant={'contained'} />))(({ theme }) => ({
  justifyContent: 'right',
  backgroundColor: theme.palette.primary.main,
}));


export default function Modal({
                                isOpen = false,
                                type = 'default',
                                children = <></>,
                                btnOkTitle = 'Так',
                                btnCancelTitle = 'Відміна',
                                handleBtnOk,
                                disabled = false,
                                handleBtnCancel,
                                loading = false,
                                sx,
                                test,
                              }: IModalMessage) {

  const { t } = useTranslation();
  const classes = useCustomStyles();

  const handleClose = () => {
    handleBtnCancel && handleBtnCancel(false);
  };

  const handleSubmit = () => {
    type !== 'error' && handleBtnOk && handleBtnOk();

  };

  return (

    <CustomDialog
      open={isOpen}
      onClose={() => handleClose()}
      className={classes.titleDefault}
      scroll={'paper'}
    >

      <CustomDialogContent sx={sx}>{children}</CustomDialogContent>

      {type === 'withoutBtn' ? <></> : <DialogActions>
        {test && <Button onClick={() => {
          test();
        }}>LOG</Button>}

        <Button loading={loading} variant={'contained'} color={'secondary'} disabled={disabled} onClick={() => {
          handleSubmit();
        }}>{t('module.common.title.titleOk')}</Button>

        {['error'].indexOf(type) === -1 &&
          <Button disabled={loading} variant={'contained'} color={'secondary'} onClick={() => {
            handleClose();
          }}>{t('module.common.title.titleCancel')}</Button>}
      </DialogActions>}

    </CustomDialog>);
}
