import { styled } from '@mui/material/styles';
import { NavLink as NavLinkM, NavLinkProps } from 'react-router-dom';

type NavLinkCustom = NavLinkProps & ({ colorText: string, colorHoverText: string, bgActiveLink: string, isActiveLink: boolean })

export const NavLink = styled(({
                                 colorText = '#ccc',
                                 colorHoverText = '#9b9b9b',
                                 bgActiveLink = 'rgba(200,200,200,0.2)',
                                 isActiveLink = false,
                                 ...props
                               }: NavLinkCustom) => (
  <NavLinkM {...props} />))(({ colorText, colorHoverText, bgActiveLink, isActiveLink }) => ({
    display: 'flex',
    width: '100%',
    textDecoration: 'inherit',
    backgroundColor: isActiveLink ? bgActiveLink : 'inherit',
    color: colorText,
    '&:hover': {
      color: colorHoverText,
    },
  }),
);
