import { saveActiveRole, setActiveHolderId, setAuthorize } from '../../store';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { transformCatchError } from '../api/errors/transform-catch-error';
import { ReqAuthApiServiceProps, ResAuthApiServiceProps, TokenProps } from '../../interface';
import { setStorage } from '../storage/storage.service';
import { Storage } from '../../config';


export const authorizationService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    authorization: builder.query<ResAuthApiServiceProps, ReqAuthApiServiceProps>({
      async queryFn({ username, password }, { getState, dispatch }, extraOptions, fetchWithBQ) {
        try {
          const result: any = await fetchWithBQ({
            url: `/common/users/authorization`,
            method: 'get',
            params: { username, password },
          });

          const data = checkErrors<ResAuthApiServiceProps>({ result, meta: result.meta });

          /* Получаем даные по токену */
          const objToken: TokenProps = JSON.parse(atob(data.token.split('.')[1]));
          setStorage(Storage.userId, objToken.CrmUserId);

          /* Авторизуемся */
          dispatch(setAuthorize({ isAuth: true }));
          setStorage(Storage.userName, data.username);
          setStorage(Storage.token, data.token);
          setStorage(Storage.refreshToken, data.refreshToken);


          /* Устанавливаем холдера по умолчанию */
          dispatch(setActiveHolderId({ holderId: data.currentHolderId }));
          /* Устанавливаем активную роль */
          dispatch(saveActiveRole({ role: data.role }));

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),

  }),
});
