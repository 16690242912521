import { styled } from '@mui/material/styles';
import { ButtonProps } from '@mui/material/Button/Button';
import { Button as Btn } from '../../../../../ui';

type ButtonCustom = ButtonProps & { open: boolean }

export const Button = styled((props: ButtonCustom) => {
    return <Btn  {...props} sx={{
      '&:after': {
        borderTop: !props.open ? '5px solid #ccc' : '0px',
        borderBottom: props.open ? '5px solid #ccc' : '0px',
      },
    }} />;
  },
)(({ theme }) => ({
  minWidth: '300px',
  justifyContent: 'flex-start',
  padding: '2px 8px',
  position: 'relative',
  '&:hover': {
    backgroundColor: theme.palette.grey[900],
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    top: '50%', // Положение относительно родительского элемента
    right: '5%', // Расположение справа от центра
    transform: 'translateX(50%)', // Центрирование псевдоэлемента относительно родительского элемента
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent', // Левая граница
    borderRight: '5px solid transparent', // Правая граница
  },
  [theme.breakpoints.down('md')]: {
    minWidth: 'inherit',
    width: '100%',
  },
}));
