import { memo } from 'react';
import { Box, CardMedia, Grid } from '@mui/material';
import { useStylesCardMedia } from './style/cardMedia';
import { AuthRoutes } from './routes/AuthRoutes';
import { CredentialsEnter } from './style/CredentialsEnter';
import { CompanyInfo } from './style/CompanyInfo';

import { ChangeLanguage } from './components/ChangeLanguage';
import { useConfig } from '../../../hooks';

export const Credentials = memo(() => {

  const cardMedia = useStylesCardMedia();
  const config = useConfig();

  return (
    <Box>
      <Grid item xs={12}>
        <Grid item xs={8}>
          <CardMedia className={cardMedia.loginImage}
                     component='img'
                     image='./img/img_login.jpg'
                     alt='logoImage'
          />
        </Grid>
      </Grid>
      <CredentialsEnter item xs={3}>
        <AuthRoutes />

        <CompanyInfo dangerouslySetInnerHTML={{ __html: config.companyInfoAfterLoginLocated }} />
        <ChangeLanguage sx={{ marginTop: '1rem' }} />
      </CredentialsEnter>
    </Box>
  );
});
