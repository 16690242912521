import { accessRole } from '../config';
import { GetAccessProps, PermissionProps, RouteProps } from '../interface';
import { useActiveHolder } from './useActiveHolder';
import { useAppSelector } from './redux';


export const useCheckPermission = () => {
  /* Активная роль с помощь которой выбирается разрешение */
  const { activeHolderId, activeHolder, activeRole } = useActiveHolder();
  const { config } = useAppSelector(state => state.layoutReducer);

  const permission = accessRole[activeRole];

  /* Проверяем получение разрешения на пути */
  const checkPermissionRoute = (route: RouteProps) => {
    /* Проверяем что это просто ссылка для создания route */
    if (route.title === undefined) return true;

    const permType = route.type as keyof PermissionProps;

    /*
     * Проверка доступности из конфига
     */
    if (permType === 'documents' && config && !config.documents) return false;
    if (permType === 'exchange' && config && !config.moneyTransfer) return false;

    const localPermission = permission[permType] as { isAllowMenuItem: boolean };
    return localPermission.isAllowMenuItem;
  };


  /* Проверка уровня доступа  */
  const checkPermission = <T>({ accessPoint }: Pick<GetAccessProps, 'accessPoint'>): T => {
    if (activeHolder?.apiRole) {
      //console.log(' accessRole[activeHolder.role as Roles]', accessRole[activeHolder.role as Roles]);
      // return accessRole[Roles.Admin]

      const properties = (activeHolder?.apiRole + '.' + accessPoint).split('.');
      let current = accessRole as any;

      for (const prop of properties) {
        if (Object.prototype.hasOwnProperty.call(current, prop)) {

          current = current[prop];

        } else {
          return false as T; // Возвращаем undefined, если свойство не найдено
        }
      }
      return current as T;
    }


    return false as T;
  };

  return {
    checkPermissionRoute, checkPermission,
  };

};
