import React, { FC, memo } from 'react';
import { Button } from '../../style';
import { HolderInfo } from '../HolderInfo';
import { HolderButtonProps } from '../../../../../../../interface';
import { useMediaQuery } from '../../../../../../../hooks';


export const HolderButton: FC<HolderButtonProps> = memo(({ okpo, firmName, open, onClick }) => {
  const matches = useMediaQuery();
  return (
    <Button onClick={onClick} open={open}>
      <HolderInfo firmName={firmName} okpo={okpo} fontWeight={800} maxLength={matches ? 20 : 50} />
    </Button>
  );
});
