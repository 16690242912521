import React, { memo, ReactNode, useMemo, useState } from 'react';
import { Title } from '../styles/Title';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { LoadingButton } from '../styles/LoadingButton';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { registerOtpService, transformResponseError } from '../../../../services';
import { InputPhone } from '../../../ui';
import { LinkRegisterType } from '../../../../helpers';
import { RegisterProps } from '../../../../interface';
import { useCustomStyles } from '../../../../styles';
import { toast } from '../../../../utils';
import { CenterContainer, LinkRegister, FooterContainerButton } from '../..';



export const Register = memo(({ screenType }: RegisterProps) => {
  const { t } = useTranslation();
  const global = useCustomStyles();
  const navigation = useNavigate();

  const [phone, setPhone] = useState('');

  const validationSchema = yup.object().shape({
    phone: yup.string().required(t('error.REQUIRED_FIELD')),
  });

  const {
    register, handleSubmit, formState: { errors }, setValue,
  } = useForm({ resolver: yupResolver(validationSchema) });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('phone', e.target.value.toString());
    setPhone(e.target.value);
  };

  const handleClickLinkEnter = () => {
    navigation('/login', { replace: true });
  };

  const renderTitle = useMemo(() => {
    return screenType === LinkRegisterType.Register
      ? t('module.register.registerCRM')
      : t('module.register.updateCRM');
  }, [screenType, t]);

  const [sendOTP, { isLoading }] = registerOtpService.useLazySendOTPQuery();
  const handleSendOtp = () => {
    sendOTP({ phone }).then(({ isError, error }) => {
      transformResponseError({ isError, error: String(error) });
      if (!isError) {
        toast({ type: 'success', message: t('error.SEND_OTP_SUCCESS', { phone }) });
        if (screenType === LinkRegisterType.Register) {
          navigation(`/register/${String(phone)}/otp/`, { replace: true });
        } else {
          navigation(`/forgot-password/${String(phone)}/otp/`, { replace: true });
        }
      }
    });
  };

  return (
    <>
      <CenterContainer item className={global.titleBold}>
        <Title item className={global.titleBold}>{renderTitle}</Title>
        <Grid item className={global.textDefault}
              sx={{ marginBottom: '1rem' }}>{t('module.register.welcomeTextPhone')}</Grid>
        <InputPhone
          inputType={'filled'}
          value={phone}
          onChange={handleOnChange}
          label={t('module.register.titlePhone')}
          inputProps={register('phone')}
          error={!!errors.phone}
          helperText={errors.phone ? (errors?.phone?.message as ReactNode) : ' '}
        />
        <LoadingButton variant='contained' loading={isLoading}
                       onClick={handleSubmit(handleSendOtp)}>{t('module.register.btnRegisterPhone')}</LoadingButton>
      </CenterContainer>
      <FooterContainerButton>
        <LinkRegister className={global.textDefault}
                      onClick={handleClickLinkEnter}>
          {t('module.login.btnEnter')}
        </LinkRegister>
      </FooterContainerButton>
    </>);
});
