import { IColumn } from '../../../../ui/custom-table/components/Columns/Columns';
import { ISingleRow } from '../../../../ui/custom-table/interface/interface.custom';
import i18next from 'i18next';
import { GetAccessProps } from '../../../../../interface';


interface IGetColumnsCard {
  handleDeleteLimit(limitId: number): Promise<boolean>;

  handleUpdateLimit(limit: number, limitAmount: number): Promise<boolean>;

  /**
   *  Проверяем доступность к методам
   */
  checkPermission<T>({ accessPoint }: Pick<GetAccessProps, 'accessPoint'>): T;
}

export const getColumnsLimits = ({
                                   handleDeleteLimit,
                                   handleUpdateLimit,
                                   checkPermission,
                                 }: IGetColumnsCard): IColumn[] => {

  const onChangeField = async ({ row }: ISingleRow) => {
    return await handleUpdateLimit(Number(row.id), Number(row.limitAmount));
  };

  const onClickDelete = async ({ row }: ISingleRow) => {
    return await handleDeleteLimit(Number(row.id));
  };

  const returnColumns: IColumn[] = [
    {
      field: 'typeSchema',
      label: i18next.t('module.cards.limits.titleSchemaType'),
      formatHeader: 'default',
      cell: { zeroCheckField: 'rest', format: 'default-or-red-zero-field' },
      align: 'center',
      alignData: 'center',
      sortable: false,
      width: 200,
    },
    {
      field: 'purses',
      label: i18next.t('module.cards.limits.titlePurses'),
      formatHeader: 'default',
      cell: { zeroCheckField: 'rest', format: 'default-or-red-zero-field' },
      align: 'center',
      alignData: 'center',
      sortable: false,
      width: 200,
    },
    {
      field: 'type',
      label: i18next.t('module.cards.limits.titleType'),
      formatHeader: 'default',
      cell: { zeroCheckField: 'rest', format: 'default-or-red-zero-field' },
      align: 'center',
      alignData: 'center',
      sortable: false,
      width: 200,
    },
    {
      field: 'qtyLimit',
      label: i18next.t('module.cards.limits.titleLimitAmount'),
      formatHeader: 'default',
      cell: {
        zeroCheckField: 'rest',
        format: checkPermission<boolean>({ accessPoint: 'cards.access.limit.edit' }) ? 'text-edit-limits-or-red-zero-field' : 'default-or-red-zero-field',
        onChangeField,
      },
      align: 'center',
      alignData: 'center',
      width: 130,
      sortable: false,
    },
    {
      field: 'balance',
      label: i18next.t('module.cards.limits.titleBalance'),
      formatHeader: 'default',
      cell: { zeroCheckField: 'rest', format: 'default-or-red-zero-field' },
      align: 'center',
      alignData: 'center',
      width: 130,
      sortable: false,
    },
    {
      field: 'rest',
      label: i18next.t('module.cards.limits.titleRest'),
      formatHeader: 'default',
      cell: {
        zeroCheckField: 'rest', format: 'default-or-red-zero-field',
      },
      align: 'center',
      alignData: 'center',
      width: 130,
      sortable: false,
    },
  ];

  if (checkPermission<boolean>({ accessPoint: 'cards.access.limit.delete' })) {
    returnColumns.push({
      field: 'btnAccount',
      label: '',
      formatHeader: 'default',
      cell: {
        format: 'icon-delete',
        onClickDelete,
        labelConfirm: i18next.t('module.common.modal.labelConfirmation'),
      },
      align: 'center',
      alignData: 'center',
      width: 10,
    });
  }


  return [...returnColumns];
};
