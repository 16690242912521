import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DownloadLink } from '../../ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks';

const BoxContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  fontWeight: 600,
  gap: 15,
}));


export default function HelperLinks() {
  const { t } = useTranslation();

  const { files, links } = useAppSelector(state => state.layoutReducer);

  const renderFiles = useMemo(() => {
    const arrLink = [];
    for (const key in files) {
      arrLink.push({ link: key, title: files[key] });
    }

    return arrLink.map((item, idx) => (
      <Box key={item.link}>
        <Typography sx={{ display: 'inline' }}>{idx + 1}. </Typography>
        <DownloadLink url={`./info/${item.link}`} title={`${item.title}`} />
      </Box>
    ));
  }, [files]);

  const renderLinks = useMemo(() => {
    return links.map((item, idx) => (
      <Box key={item.url}>
        <Typography sx={{ display: 'inline' }}>{idx + 1}. </Typography>
        <DownloadLink url={item.url} title={item.title} target={'_blank'} />
      </Box>
    ));
  }, [links]);

  return <>

    <BoxContainer>
      <Typography textAlign={'center'}>{t('module.common.title.titleDocumentation')}:</Typography> {renderFiles}
      {
        links.length > 0 &&
        <Typography textAlign={'center'}>{t('module.common.title.titleLinks')}:</Typography>
      }
      {renderLinks}
    </BoxContainer>

    <BoxContainer></BoxContainer>
  </>;
}
