import { memo, useMemo } from 'react';

import { HolderLine } from '../HolderLine';
import { HolderCardContainer } from '../../style';
import { HolderCardProps } from '../../../../../../../interface';
import { useTranslation } from 'react-i18next';
import { ContainerRow } from '../../../../../../ui';

export const HolderCard = memo(({ holder, selectedId, onClick }: HolderCardProps) => {
  const { t } = useTranslation();
  const handleOnClick = () => {
    onClick(holder.holderId);
  };

  const renderOKPO = useMemo(() => {
    if (holder.okpo && holder.okpo !== '') {
      return holder.okpo;
    }
    return '-';
  }, [holder]);

  const renderFirmName = useMemo(() => {
    if (holder.name && holder.name !== '') {
      return holder.name;
    }
    return '-';
  }, []);

  const renderApiRole = useMemo(() => {
    if (holder.apiRole) {
      return t(`roles.${holder.apiRole}` as any);
    }
  }, []);

  return (
    <HolderCardContainer selected={selectedId === holder.holderId} onClick={handleOnClick}>
      <ContainerRow sx={{ justifyContent: 'space-between' }}>
        <HolderLine text={renderOKPO} color={'primary'} fontSize={'.8rem'} fontWeight={800} />
        <HolderLine text={renderApiRole} color={'primary'} fontSize={'.55rem'} />
      </ContainerRow>

      <HolderLine text={renderFirmName} color={'primary'} fontSize={'.8rem'} />


    </HolderCardContainer>
  );
});
