import { styled } from '@mui/material/styles';
import { GridProps } from '@mui/material/Grid/Grid';
import { Grid } from '@mui/material';

export const OutlineContainer = styled((props: GridProps) => (<Grid {...props} />))(() => ({
  padding: '1rem',
  border: '1px solid rgba(0,0,0,.2)',
  borderRadius: '5px',
  position: 'relative',
  '&:hover': {
    cursor: ' pointer',
    borderColor: ' rgba(0,0,0,.5)',
  },
}));
