import { useAppDispatch } from './redux';
import { setErrorMessage } from '../store';
/**
 *  Глобальное отобржение ошибки
 */
export const useGlobalError = () => {
  const dispatch = useAppDispatch();

  const showGlobalMessage = (errorMessage: string | undefined) => {
    dispatch(setErrorMessage({ errorMessage }));
  };

  const clearGlobalMessage = () => {
    dispatch(setErrorMessage({ errorMessage: undefined }));
  };


  return { showGlobalMessage, clearGlobalMessage };
};
