import { InputPhone } from '../../../../../ui';
import React, { ReactNode, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getStorage } from '../../../../../../services';
import { Storage } from '../../../../../../config';
import { LoadingButton } from '@mui/lab';

interface ISendOTP {
  value: string;
  isLoadingSendOTP: boolean;

  onChange(e: React.ChangeEvent<HTMLInputElement>): void;

  onSendOTP(): void;
}

export default function SendOTP({ value, onChange, onSendOTP, isLoadingSendOTP }: ISendOTP) {
  const { t } = useTranslation();
  const [phone, setPhone] = useState<string>('');
  const [isEditField, setActiveEditField] = useState(false);

  useEffect(() => {
    if (value !== '') {
      setPhone(value);
    } else {
      const username = getStorage<string>(Storage.userName);
      if (username) {
        setPhone(username);
        setActiveEditField(true);
      }
    }
  }, [value]);

  return <>
    <InputPhone
      disabled={isEditField}
      sx={{ marginTop: '1.2rem', '& .Mui-disabled': { backgroundColor: 'transparent !important' } }}
      inputType={'filled'}
      value={phone}
      onChange={onChange}
      label={t('module.register.titlePhone')}
    />

    <LoadingButton
      loading={isLoadingSendOTP}
      variant='contained'
      style={{ width: '100%', marginTop: '1rem', padding: '.7rem 0' }}
      onClick={onSendOTP}>
      {t('module.register.btnRegisterPhone')}
    </LoadingButton>
  </>;
}
