import { UserProfileProps } from '../../../../../../interface';

export interface AccessCardsProps {
  cardId: string;
}

export interface AccessAccountsProps {
  accountId: number;
  accessCards?: AccessCardsProps[];
}

export interface AttributesProps {
  id: number;
  attributeId: number;
  attributeRemark: string;
  value: string;
  isDynamic: boolean;
}

export interface UserHoldersProps {
  holderId: number;
  name: string;
  apiRole: string;
  isDeleted?: boolean;
  attributes?: AttributesProps[];
  accessAccounts?: AccessAccountsProps[];
  limitAccounts?: boolean;
  limitCards?: boolean;
}

export interface UserProps {
  id?: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  commonHolderId?: number;
  isReadOnlyProfile: boolean;
  userCanEdit: boolean;
  holders?: UserHoldersProps[];
}

export const initialUser: UserProfileProps = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  userCanEdit: false,
  active: true,
  commonHolderId: -1,
  id: -1,
  isReadOnlyProfile: false,
  personalAccountUserId: -1,
  holders: [],
};
