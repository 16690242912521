import Excel, { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { ClientBills } from './client-bills/clientBills';
import { IBillingDoc } from './Template/interface.billing';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { genSocarPDF } from './Template/Socar/PDFSocar';
import { getSupplierSocar } from './Template/Socar/prepareData';
import { getSocarXLSXFile } from './Template/Socar/XLSXSocar';
import { getSupplierBVS } from './Template/BVS/prepareData';
import { genBvsPDF } from './Template/BVS/PDFBvs';
import { getBvsXLSXFile } from './Template/BVS/XLSXBvs';

/**
 * Получаем Workbook
 */
export const getWorkBook = async (clientBills: ClientBills): Promise<Workbook> => {
  /**
   * Вибираем правильный шаблон
   */
  let fileTemplate = '';
  switch (clientBills) {
    case ClientBills.BVS: {
      fileTemplate = 'bill_bvs.xlsx';
      break;
    }
    default: {
      fileTemplate = 'bill.xlsx';
      break;
    }

  }
  /**
   * Получаем файл из урл линка
   */
  const file = await getFileFromUrl(`./billing/${fileTemplate}`, `${fileTemplate}`);
  /**
   * Получаем буффер из файла
   */
  const buffer = await getBufferFromFile(file);
  /**
   * Конвертируем буффер в workbook Excel
   */
  return await getWorkbookFromBuffer(buffer);

};

export const getFileFromUrl = async (url: string, outFileName: string): Promise<File> => {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
  return new File([data], outFileName, metadata);
};

export const getBufferFromFile = async (file: File): Promise<Buffer> => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const buffer = reader.result;
      resolve(buffer as Buffer);
    };
  });
};

export const getWorkbookFromBuffer = async (buffer: Buffer): Promise<Workbook> => {
  return new Promise(resolve => {
    const wb = new Excel.Workbook();
    wb.xlsx.load(buffer).then(workbook => {
      resolve(workbook);
    });
  });
};


export const saveXLSFile = async (wb: Workbook, name: string) => {
  await wb.xlsx.writeBuffer().then((data: any) => {
    saveAs(new Blob([data]), `${name}.xlsx`);
  }).catch((err: any) => console.log('err', err));
};

export const savePDFFile = async (pdfBuffer: Buffer, name: string) => {
  saveAs(new Blob([pdfBuffer]), `${name}.pdf`);
};


interface IPDV {
  pdv: number;
  bezPdv: number;
}

export const getPDV = (sum: number, percent: number): IPDV => {
  const bezPdv = parseFloat((sum / (percent / 100 + 1)).toFixed(2));
  const pdv = parseFloat((sum - bezPdv).toFixed(2));

  return { pdv, bezPdv };
};

type ITemplate = IBillingDoc & { clientBills: ClientBills };

export const getTemplatePDF = async (props: ITemplate): Promise<TDocumentDefinitions> => {

  switch (props.clientBills) {
    case ClientBills.BVS: {
      const wb = await getWorkBook(props.clientBills);
      const supplier = getSupplierBVS(wb);
      return genBvsPDF({ ...props, supplier } as IBillingDoc);
    }
    default : {
      const wb = await getWorkBook(props.clientBills);
      const supplier = getSupplierSocar(wb);

      return genSocarPDF({ ...props, supplier } as IBillingDoc);
    }
  }


};

export const getXLSFile = async (props: ITemplate): Promise<void> => {
  switch (props.clientBills) {
    case ClientBills.BVS: {
      await getBvsXLSXFile({ ...props });
      break;
    }
    default : {
      await getSocarXLSXFile({ ...props });
    }
  }
};

