import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { LoadingButtonProps } from '@mui/lab/LoadingButton/LoadingButton';

export const Button = styled((props: LoadingButtonProps) => (
  <LoadingButton  {...props} />))(({ theme }) => ({
  minWidth: '100px',
  justifyContent: 'flex-start',
  padding: '2px 8px',
  '&:hover': {
    backgroundColor: theme.palette.grey[900],
  },
}));
