import { Box, CardMedia, Typography } from '@mui/material';

import React, { useEffect, useMemo, useState } from 'react';
import { Loading } from '../../loading';
import { Autocomplete } from './style/Autocomplete';


interface IData {
  id: number;
  label: string;
}

interface IProps {
  data: any;
  fieldLabelOption: string;

  fnOnChange(key: any): void;

  label: string;
  defaultValue?: number | string;
  disabled?: boolean;
  textStyle: React.CSSProperties;
  callbackType?: 'fullData' | 'onlyId';
  error?: boolean;

  [key: string]: any;
}

interface IOptions {
  id: number;
  label: string;
  iconType?: string;
}

export default function ComboBoxIcon({
                                       data,
                                       fieldLabelOption,
                                       fnOnChange,
                                       label,
                                       disabled = false,
                                       defaultValue = 0,
                                       textStyle = {},
                                       callbackType = 'onlyId',
                                       error = false,
                                       ...other
                                     }: IProps) {

  const [val, setVal] = useState<any>();
  const [options, setOptions] = useState<IOptions[]>([]);

  useEffect(() => {

    const newOptions: IData[] = data.map((item: any) => ({
      id: item.id,
      label: item[fieldLabelOption],
      ...item,
    }));
    const defaultOption: IData = newOptions.filter(o => o.id === ((defaultValue === 0 || defaultValue === -1) ? newOptions[0].id : defaultValue))[0];

    setVal(defaultOption);
    setOptions(newOptions);

  }, [defaultValue, data]);

  const haveInDataMinus = useMemo(() => {
    return !!data.find((item: any) => item.id === -1);
  }, [data]);

  if (!val) {
    return <Typography textAlign={'center'}><Loading type={'medium'} /></Typography>;
  }

  const getIcon = (iconType: string) => {
    switch (iconType) {
      case 'excel': {
        return 'icons-excel.png';
      }
      default: {
        return 'icons-report.png';
      }
    }
  };


  const renderOptionLabel = (props: any, option: any) => {

    if (Object.hasOwn(option, 'icon')) {
      return <Box component='li' sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }} {...props}>
        <CardMedia
          style={{ width: '20px', marginRight: '3px' }}
          component='img'
          image={`./img/${option.icon}`}
        />
        <Typography>{option.label}</Typography>
      </Box>;
    }

    return (
      <Box component='li' sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }} {...props}>
        <CardMedia
          style={{ width: '20px', marginRight: '3px' }}
          component='img'
          image={`./img/${option.id < 3 ? getIcon('default') : getIcon('excel')}`}
        />
        <Typography>{option.label}</Typography>
      </Box>
    );
  };


  return (
    <>
      <Autocomplete
        disabled={disabled}
        defaultValue={defaultValue}
        value={val}
        label={label}
        options={options}
        isOptionEqualToValue={(option, value) => {
          return (option.id === value.id && option.label === value.label);
        }}
        getOptionDisabled={(option) => (option?.disabled)}
        renderOption={renderOptionLabel}
        onChange={(event: any, value: any): void => {
          if (value.id >= 0 || haveInDataMinus) {
            setVal(value);
            callbackType === 'onlyId' && fnOnChange(value.id);
            callbackType === 'fullData' && fnOnChange(value);
          }
        }}
        {...other}
        error={error}
      />
    </>);
}
