import React, { FC, memo } from 'react';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Avatar, ButtonContainer } from '../../style';
import { HolderLine } from '../HolderLine';
import { Box } from '@mui/material';
import { Loading, Center } from '../../../../../../ui';
import { HolderInfoProps } from '../../../../../../../interface';


export const HolderInfo: FC<HolderInfoProps> = memo(({ okpo, maxLength, fontWeight, firmName, color }) => {
  return (
    <Box display={'flex'} alignItems={'center'} flex={1}>
      <Avatar sx={{ marginRight: '.5rem' }}><AccountBalanceIcon /></Avatar>
      {
        okpo === '' && firmName === ''
          ? <Center><Loading type={'small'} /></Center>
          : <ButtonContainer>
            <HolderLine text={okpo} fontWeight={fontWeight} color={color} />
            <HolderLine text={firmName} maxLength={maxLength} color={color} />
          </ButtonContainer>
      }

    </Box>
  );
});
