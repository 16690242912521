import React from 'react';
import { IMaskInput } from 'react-imask';
import { TextFieldProps } from '@mui/material/TextField';

import { BaseTextFieldProps } from '@mui/material/TextField/TextField';
import InputFilled from './InputFilled';

interface CustomProps {
  onChange: (e: { target: { name: string; value: string; } }) => void;
  placeholder: string;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref) {
  const { onChange, placeholder, ...other } = props;

  const onAccept = (value: any, mask: any) => {
    onChange({ target: { name: props.name, value: mask._unmaskedValue } });
  };

  return (<IMaskInput
    {...other}
    mask={Number}
    radix='.'  // Разделитель для десятичных знаков
    onAccept={onAccept}
    max={9999999999}  // Максимальное значение
    scale={2}  // Количество десятичных знаков
    thousandsSeparator=' '  // Разделитель тысячных
    mapToRadix={['.']}  // Разрешённые десятичные разделители
    definitions={{ 'x': /\d/ }}
    placeholder={placeholder}
    overwrite
  />);
});

type IInputMask = BaseTextFieldProps & TextFieldProps & {
  InputProps?: any;
  label?: string;
  value?: string;
  sx?: any;
}

export default function InputSum({ value, label = '', sx = {}, onChange, InputProps = {}, ...other }: IInputMask) {

  return (
    <InputFilled
      value={value}
      label={label}
      onChange={onChange}
      InputProps={{ ...InputProps, inputComponent: TextMaskCustom as any }}

      sx={{ '& .MuiFilledInput-root:before, .MuiFilledInput-root:after': { display: 'none' }, ...sx }}
      {...other}
    />);
}
