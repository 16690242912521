import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { transformCatchError } from '../api/errors/transform-catch-error';


export const apiKeyMutation = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    createApiKey: builder.mutation<void, any>({
      async queryFn({}, {}, extraOptions, fetchWithBQ) {
        try {
          const result = await fetchWithBQ({
            url: `common/users/keys`,
            method: 'POST',
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      invalidatesTags: ['ApiKey'],
    }),

    deleteApiKey: builder.mutation<void, any>({
      async queryFn({}, {}, extraOptions, fetchWithBQ) {
        try {
          const result = await fetchWithBQ({
            url: `common/users/keys`,
            method: 'DELETE',
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      invalidatesTags: ['ApiKey'],
    }),
  }),
});
