import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";

interface IDownloadLink {
	url: string;
	title: string;
	[key: string] : any;
}

const LinkCustom = styled( Link )( (props) => ( {
	color: props.theme.palette.secondary.main,
	"&:hover": {
		color: props.theme.palette.secondary.light
	}
} ) );

export default function DownloadLink ( { url, title, ...other }: IDownloadLink ) {
	return <>
		<LinkCustom href={ url } underline="none" {...other} >{ title }</LinkCustom>
	</>
}
