import { List, ListItemIcon, ListItemText } from '@mui/material';
import { FC, memo, useMemo } from 'react';
import { NavLink } from '../style';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { useActiveHolder, useAppSelector } from '../../../../../../hooks';
import { MenuItemProps } from '../../../../../../interface';


const Item = styled(List)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '.3rem',
}));

const ItemIcon = styled(ListItemIcon)(() => ({
  minWidth: '25px',
  margin: '0 .7rem',
  color: 'inherit',
}));

const ItemText = styled(ListItemText)(() => ({
  color: 'inherit',
}));


export const MenuItem: FC<MenuItemProps> = memo(({ linkTo, icon, title }) => {
  const location = useLocation();

  const { activeHolderId } = useActiveHolder();

  const isActiveLink = useMemo(() => {
    const regExp = new RegExp(linkTo.replace(/\/:\w+/g, '\\/.*\\') + '(?:\\?.*)?$');
    return regExp.test(location.pathname);
  }, [location.pathname, linkTo]);

  const generateLink = useMemo(() => {
    return linkTo.replace(/:holderId/g, String(activeHolderId)).replace(/:\w+/g, String(0));
  }, [linkTo, activeHolderId]);

  const { config } = useAppSelector(state => state.layoutReducer);
  const { text, bgActiveLink, hover } = config.menu.link.color;

  return (
    <NavLink to={generateLink} colorText={text} colorHoverText={hover} isActiveLink={isActiveLink}
             bgActiveLink={bgActiveLink}>
      <Item>
        <ItemIcon>
          {icon}
        </ItemIcon>
        <ItemText primary={title} />
      </Item>
    </NavLink>
  );
});
