import React, { memo, useMemo, useState } from 'react';
import { Popover } from '@mui/material';
import { HolderButton, HolderList } from './components';
import { HolderContainer } from './style';
import { useActiveHolder } from '../../../../../hooks';
import { Loading } from '../../../../ui';
import { holdersListService } from '../../../../../services';


export const CurrentHolder = memo(() => {
  const {
    data: dataHolders = { holders: [] }, isLoading,
  } = holdersListService.useGetHoldersQuery('');
  const {activeHolderId} = useActiveHolder();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const firmData = useMemo(() => {
    const selectedHolder = dataHolders.holders.find(holder => holder.holderId === activeHolderId);
    return selectedHolder ?? { holderId: -1, name: '', okpo: '' };
  }, [dataHolders.holders, activeHolderId]);

  return (
    <HolderContainer>
      {
        isLoading && !firmData
          ? <Loading type={'small'} />
          : <HolderButton onClick={handleClick}
                          id={firmData.holderId}
                          firmName={firmData.name}
                          okpo={firmData.okpo}
                          open={open} />
      }

      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <HolderList id={firmData.holderId} firmName={firmData.name} okpo={firmData.okpo} color={'primary'}
                    onClose={handleClose} />
      </Popover>
    </HolderContainer>
  );
});
