import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { CardScheduleListServiceProps, CardScheduleProps, QRCardPropsService } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';


export const cardScheduleService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getCardSchedule: builder.query<CardScheduleListServiceProps, { cardId: string }>({
      async queryFn({ cardId }, {}, extraOptions, fetchWithBQ) {
        try {

          const result = await fetchWithBQ({
            url: `/cards/${cardId}/schedules`,
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          data.schedule = data.schedule.map((item: CardScheduleProps) => ({ ...item, isValid: true }));

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      providesTags: ['CardSchedule']
    }),
  }),
});
