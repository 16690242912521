import { initialStateActivityReportFilter, initialStateReportTrn } from './filter/interface.filter';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ComboBox, ComboBoxIcon, CustomTable, DatePicker, Loading } from '../../../ui';
import { getColumns } from './report.columns';
import { IFilter } from '../../../ui/custom-table/interface/interface.custom';
import moment from 'moment';
import { REPORT_COUNT_PER_ON_PAGE } from '../../../../config';
import { useActiveHolder, useMediaQuery } from '../../../../hooks';
import { AccountProps, KindProps, RequestReportFileTransactionProps, RequestReportTransactionProps, StateActivityReportFilterProps } from '../../../../interface';
import { useTranslation } from 'react-i18next';
import { accountsService, downloadFileMutation, reportService, transactionKindService } from '../../../../services';
import { InnerContainer } from '../../../common';
import { getAccountIdFromParams } from '../../Cards/CardList/utils/utils';
import { CardSelect, PurseSelect } from './filter';
import { ContainerCard, ContainerForDate, ContainerPurse } from './components';
import { ButtonClear, ButtonCommit } from './components/Buttons';
import { toast } from '../../../../utils';

/* Кол-во строк в таблице счетов, на странице инфо клиента */
const countPerOnPage: number = REPORT_COUNT_PER_ON_PAGE ? +REPORT_COUNT_PER_ON_PAGE : 5;

export default function ReportTrn() {
  const { t } = useTranslation();
  /* Управляем линками */
  const navigate = useNavigate();
  /* Инициализация медиа  */
  const matches = useMediaQuery();
  /* Получаем адресную строку */
  const params = useParams<{ holderId: string, accountId: string }>();

  /* Получаем список счетов выбранного клиента */
  const { activeHolderId } = useActiveHolder();
  const pAccountId = useMemo(() => (getAccountIdFromParams(params)), [params?.accountId]);

  const { data: dataAccount = { accounts: [] } } = accountsService.useGetAccountsByHolderIdQuery({ holderId: activeHolderId });

  /* Фильтр таблицы */
  const [filter, setFilterState] = useState<RequestReportTransactionProps>(initialStateReportTrn);
  const [filterActivity, setFilterActivity] = useState<StateActivityReportFilterProps>(initialStateActivityReportFilter);

  useEffect(() => {
    if (pAccountId !== 0) {
      setFilterState(prev => ({ ...prev, accountId: pAccountId }));
    }
  }, [pAccountId]);

  useEffect(() => {
    setFilterState(initialStateReportTrn);
    setFilterActivity(initialStateActivityReportFilter);
    handleCommitFilter();
  }, [dataAccount.accounts]);

  /* Текст на кнопке */
  const [titleBtn, setTitleBtn] = useState<string>(t('module.common.filter.titleSubmit'));

  const [getReport, {
    data: dataReport = { transactions: [], countOfRows: countPerOnPage }, isFetching: isLoadingReport,
  }] = reportService.useLazyGetReportTransactionQuery();

  /* Получаем виды транзакий */
  const { data: dataTrnKinds = { kinds: [] } } = transactionKindService.useGetReportTypeQuery('');

  /* Скаиваем отчет XLS */
  const [getDownloadFile, { isFetching }] = downloadFileMutation.useLazyDownloadFileQuery();

  /* Меняем состояния клиента */
  const handleChangeAccount = (selectedAccount: any) => {
    navigate(`/holder/${activeHolderId}/account/${selectedAccount}/reports`);

    const newClient = dataAccount.accounts.filter((account: AccountProps) => account.accountId === selectedAccount)[0];
    const newFilter = { ...filter, accountId: newClient ? newClient.accountId : 0, cardId: '' };
    setFilterState(newFilter);
  };

  /* Меняем состояние выбора карт */
  const handleChangeCard = (selectedCard: string) => {
    const newFilter = { ...filter, cardId: selectedCard };
    setFilterState(newFilter);
  };

  /* Меняем тип отчёта */
  const handleChangeKinds = (selectedKind: KindProps) => {

    const newKinds: KindProps = dataTrnKinds.kinds.filter((kind: KindProps) => kind.id === selectedKind.id)[0];
    const newFilter = { ...filter, operation_kind: newKinds.id, filterType: newKinds.type, fileName: newKinds.name };

    if (newKinds.type === 'xlsReport') {

      const newFilterActivity = {
        useHolderSelector: newKinds.useHolderSelector,
        useCardSelector: newKinds.useCardSelector,
        useFromDate: newKinds.useFromDate,
        useToDate: newKinds.useToDate,
        usePurseSelector: newKinds.usePurseSelector,
        useAccountSelector: newKinds.useAccountSelector,
      };

      setTitleBtn(t('module.reports.titleSubmitXLS'));
      setFilterActivity(newFilterActivity as StateActivityReportFilterProps);
    } else {

      setTitleBtn(t('module.common.filter.titleSubmit'));

      setFilterActivity(initialStateActivityReportFilter);
    }

    setFilterState(newFilter);
  };

  const handleChangeRangeOfDate = (dateType: 'fromDate' | 'toDate', rangeDate: any) => {

    let filterDateRange = {};
    if (rangeDate.length !== 2) {

      filterDateRange = {
        ...filter,
        [dateType]: moment(rangeDate).format('YYYY-MM-DD'),
      };

    } else {

      filterDateRange = {
        ...filter,
        fromDate: moment(rangeDate[0]).format('YYYY-MM-DD'),
        toDate: moment(rangeDate[1]).format('YYYY-MM-DD'),
      };
    }
    const newFilter = {
      ...filter, ...filterDateRange,
    };
    setFilterState(newFilter);

  };

  /* Заполняем табличные заголовки */
  const columns = useMemo(() => {
    return getColumns();
  }, [t]);

  const handleChangePurses = (purseIds: number[]) => {
    setFilterState(prev => ({ ...prev, purses: purseIds }));
  };

  /* Текущая страница счетов */
  const handleChangePage = ({ page }: IFilter) => {
    const customFilter =
      filter.holderId === -1
        ? { ...filter, holderId: activeHolderId }
        : { ...filter };


   getReport({ ...customFilter, page: page - 1, limit: countPerOnPage });
  };

  /* Применение фильтра */
  const handleCommitFilter = useCallback(async () => {

    /* Проверка на заполнение фильтров в соответствии с типом запроса */
    if (filter.filterType === 'xlsReport') {
      /* Инициализируем ошибку */
      const error = [];

      const { useAccountSelector, useCardSelector, usePurseSelector } = filterActivity;


      if (useAccountSelector && filter.accountId === 0) error.push(t('validation.EMPTY_ONE_ACCOUNT'));
      if (useCardSelector && filter.cardId === '') error.push(t('validation.EMPTY_ONE_CARDS'));
      if (usePurseSelector && filter.purses.length === 0) error.push(t('validation.EMPTY_ONE_PURSES'));

      if (error.length > 0) {
        toast({ type: 'error', message: t('validation.ERROR_EMPTY_ONE_PARAM', { params: error.join(', ') }) });
        return;
      }
    }

    const customFilter =
      filter.holderId === -1
        ? { ...filter, holderId: activeHolderId }
        : { ...filter };


    /* Проверяем это веб версия отчета или XLS */
    if (customFilter.filterType === 'webReport') {
      getReport({ ...customFilter, page: 0, limit: countPerOnPage });
    } else {

      const reqData: RequestReportFileTransactionProps = {
        accounts: [customFilter.accountId],
        reportId: customFilter.operation_kind,
        holders: [customFilter.holderId],
        cards: [customFilter.cardId],
        purses: [...customFilter.purses],
        fromDate: customFilter.fromDate,
        toDate: moment(customFilter.toDate).format('YYYY-MM-DDT23:59:59'),
        fileName: `${customFilter.fileName}.xlsx`,
      };

      getDownloadFile({ url: '/common/reports/create', fileName: reqData.fileName, body: reqData });
    }
  }, [filter, filterActivity, activeHolderId]);

  const handleClearFilter = useCallback(() => {
    setFilterState(prev => ({
      ...initialStateReportTrn,
      operation_kind: 0,
      holderId: activeHolderId,
      accountId: 0,
      cardId: '',
      fromDate: moment().format('YYYY-MM-01'),
      toDate: moment().format('YYYY-MM-DD'),
    }));
    navigate(`/holder/${activeHolderId}/account/0/reports`);
    setFilterActivity(initialStateActivityReportFilter);

  }, [filter, activeHolderId]);


  /* Фильтр по столбцам таблицы  */
  const rows = useMemo(() => {
    const count = 10;

    const filteredArr = dataReport.transactions;

    return { arr: filteredArr, count };
  }, [dataReport]);

  const dateTimePicker = useMemo(() => {

    const dateStart = moment(filter.fromDate).format('YYYY-MM-DD');
    const dateEnd = moment(filter.toDate).format('YYYY-MM-DD');

    const picker: [string, string] = [dateStart, dateEnd];

    return picker;

  }, [filter]);

  const listAccount = useMemo(() => {
    return [{
      id: 0, accountId: 0, remark: t('module.reports.titleAll'),
    }, ...dataAccount.accounts.map(account => ({
      ...account, remark: account.id === -1 ? account.remark : `[${account.id}] ${account.remark}`,
    }))];
  }, [dataAccount.accounts]);


  const getDisableState = useCallback((param: 'account' | 'card' | 'purse') => {

    switch (param) {
      case 'card': {
        /* Если выбраны веб отчеты */
        if (filter.filterType === 'webReport') return false;

        /* Если есть условие по параметрам отчета */
        return !filterActivity.useCardSelector;
      }
      case 'account': {
        /* Если выбраны веб отчеты */
        if (filter.filterType === 'webReport') return false;
        /* Если есть условие по параметрам отчета */
        return !filterActivity.useAccountSelector;
      }

      case 'purse': {
        /* Если выбраны веб отчеты */
        if (filter.filterType === 'webReport') return true;
        /* Если есть условие по параметрам отчета */
        return !filterActivity.usePurseSelector;
      }

      /* По умолчанию блокировано */
      default:
        return true;
    }

  }, [filter, filterActivity]);

  return <>
    <InnerContainer direction={'left'} show={true}>

      <Grid container style={{ flexDirection: 'row' }}>

        <Grid item xs={12} style={{ flexDirection: 'column', padding: '1rem' }}>


          <Grid container style={{
            display: 'flex', alignItems: 'center', justifyContent: `${matches ? 'center' : 'flex-start'}`,
          }}>

            <Grid item xs={12} md={2}>
              <ComboBoxIcon
                textStyle={{ fontWeight: '700' }}
                data={dataTrnKinds.kinds}
                fieldLabelOption={'name'}
                label={t('module.reports.titleTrnKind')}
                fnOnChange={handleChangeKinds}
                callbackType={'fullData'}
                defaultValue={filter.operation_kind}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <ComboBox
                textStyle={{ fontWeight: '700' }}
                data={listAccount}
                defaultValue={pAccountId}
                fieldLabelOption={'remark'}
                label={t('module.dataGroup.accounts')}
                fnOnChange={handleChangeAccount}
                disabled={getDisableState('account')}
              />
            </Grid>

            <ContainerCard item>
              <CardSelect
                onApplyCardFilter={handleChangeCard}
                cardId={filter.cardId}
                disabled={getDisableState('card')}
              />
            </ContainerCard>
            <ContainerPurse item>
              <PurseSelect
                selectedPurse={filter.purses}
                onChange={handleChangePurses}
                disabled={getDisableState('purse')}
              />
            </ContainerPurse>
            <Grid item xs={12} md={3}>
              <ContainerForDate item xs={12}>
                <Grid item xs={12}>
                  <DatePicker
                    labelInput={t('module.common.title.dateFrom')}
                    onChange={handleChangeRangeOfDate.bind(null, 'fromDate')}
                    pickerValue={moment(dateTimePicker[0])}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    labelInput={t('module.common.title.dateTo')}
                    onChange={handleChangeRangeOfDate.bind(null, 'toDate')}
                    pickerValue={moment(dateTimePicker[1])}
                  />
                </Grid>
              </ContainerForDate>
            </Grid>
            <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid item xs={12} md={6}>
                <ButtonCommit buttonType={filter.filterType} title={titleBtn} onCommit={handleCommitFilter} />
              </Grid>
              <Grid item xs={12} md={6}>
                <ButtonClear onClear={handleClearFilter} />
              </Grid>
            </Grid>

          </Grid>

        </Grid>

        <Grid item xs={12} md={11} style={{ flexDirection: 'column', padding: '1rem' }}>

          {isFetching && <Loading type={'fullScreen'} />}
          {filter.filterType === 'webReport' &&
            <CustomTable
              rows={rows.arr}
              columns={columns}
              isLoading={isLoadingReport}
              count={dataReport.countOfRows}
              labelEmptyRows={t('module.common.table.emptyRows')}
              rowsPerPageOptions={[countPerOnPage]}
              fnRefreshData={handleChangePage}
              holderId={activeHolderId}
            />}
        </Grid>


      </Grid>

    </InnerContainer>
  </>;
}
