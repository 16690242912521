import Modal from '../../../../../ui/modal/Modal';
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getColumns } from './card.columns';
import { CustomTable, InputSearchLine } from '../../../../../ui';
import { filterObject } from '../../../../../../utils';
import { cardListService } from '../../../../../../services/query/cards.list.service';
import { useActiveHolder } from '../../../../../../hooks';
import { getAccountIdFromParams } from '../../../../Cards/CardList/utils/utils';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IModalFilterCard {
  isShow: boolean;

  handleApplyCardFilter(card: string): void;

  handleClose(): void;
}

/* Кол-во строк в таблице счетов, на странице инфо клиента */
const countPerOnPage: number = 10;

export default function ModalFilterCard({
                                          isShow, handleClose, handleApplyCardFilter,
                                        }: IModalFilterCard) {
  const { t } = useTranslation();
  const childRef = useRef<any>(null);
  /* Получаем адресную строку */
  const params = useParams();

  useEffect(() => {
    if (childRef.current) childRef.current.focus();
  }, [childRef.current]);

  const { activeHolderId } = useActiveHolder();
  const pAccountId = useMemo(() => (getAccountIdFromParams(params)), [params?.accountId]);
  const { data: dataCards = { cards: [] }, isLoading } = cardListService.useGetCardListQuery({
    accountId: pAccountId, holderId: activeHolderId,
  });

  /* Текущая страница счетов */
  const [pageNum, setPage] = useState<number>(0);

  const cbChangePage = (page: number) => { setPage(page - 1); };

  /* Строка поиска по всем полям таблицы */
  const [searchStr, setSearchStr] = useState('');
  const [selectedCard, setSelectedCard] = useState('');

  /* Фильтр по столбцам таблицы  */
  const rows = useMemo(() => {
    let filteredArr = dataCards.cards;

    filteredArr = filterObject({ arr: filteredArr, fields: ['lastName', 'firstName', 'mobile', 'cardId'], searchStr });

    /* Обрезаем массив до необходимых размеров отображения на странице */
    const startRow = pageNum === 0 ? 0 : (pageNum * countPerOnPage);
    const stopRow = (pageNum + 1) * countPerOnPage;

    /* Обрезаем по строкам на странице после фильтра */
    filteredArr = filteredArr.slice(startRow, stopRow);

    const count = searchStr !== '' ? filteredArr.length : dataCards.cards.length;

    return { arr: filteredArr, count };
  }, [dataCards, searchStr, pageNum]);

  /* Заполняем табличные заголовки */
  const columns = useMemo(() => (getColumns()), []);

  const handleApplyCard = () => {
    handleApplyCardFilter(selectedCard);
    handleClose();
  };

  return <Modal
    handleBtnOk={handleApplyCard}
    isOpen={isShow}
    handleBtnCancel={handleClose}
    disabled={false}
  >
    <Grid item sx={{
      width: '700px', height: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
    }}>
      <Grid sx={{ marginBottom: '2rem' }} display={'flex'} alignItems={'center'}
            justifyContent={'space-between'}>
        <InputSearchLine
          ref={ref => childRef.current = ref}
          placeholder={t('module.common.title.titleSearchStr')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPage(0);
            setSearchStr(e.currentTarget.value);
          }}
          style={{ borderRadius: '5px' }}
        />
        <Typography>{t('module.common.title.titleSelected')}: {selectedCard || ' - '}</Typography>
      </Grid>
      <Grid>
        {
          <CustomTable
            rows={rows.arr}
            count={rows.count}
            columns={columns}
            isLoading={dataCards.cards.length === 0}
            labelEmptyRows={t('module.common.table.emptyRows')}
            onClick={(row) => setSelectedCard(row.cardId)}
            rowsPerPageOptions={[countPerOnPage]}
            cbChangePage={cbChangePage}
            pageNum={pageNum}
            sx={{ height: '391px' }}
          />
        }
      </Grid>
    </Grid>
  </Modal>;
}
