import moment from 'moment';
import { REPORT_COUNT_PER_ON_PAGE } from '../../../../../config';
import { RequestReportTransactionProps, StateActivityReportFilterProps } from '../../../../../interface';

/* Кол-во строк в таблице счетов, на странице инфо клиента */
const countPerOnPage: number = REPORT_COUNT_PER_ON_PAGE ? +REPORT_COUNT_PER_ON_PAGE : 5;

export const initialStateReportTrn: RequestReportTransactionProps = {
  fromDate: moment().format('YYYY-MM-01'),
  toDate: moment().format('YYYY-MM-DD'),
  accountId: 0,
  holderId: -1,
  cardId: '',
  purses: [],
  page: 0,
  limit: countPerOnPage,
  operation_kind: 0,
  with_cancelation: true,
  filterType: 'webReport',
  fileName: '',
};

export const initialStateActivityReportFilter: StateActivityReportFilterProps = {
  useHolderSelector: false,
  useCardSelector: false,
  useFromDate: false,
  useToDate: false,
  useAccountSelector: false,
  usePurseSelector: false,
};
