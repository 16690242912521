import { memo, useMemo } from 'react';
import { FormControl, InputLabel, MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import { SelectMultiply } from './style';
import { accountsService, pursesListService } from '../../../../../../services';
import { useTranslation } from 'react-i18next';
import { AccountSubType } from '../../../../../../interface';
import { useActiveHolder } from '../../../../../../hooks';
import { getAccountIdFromParams } from '../../../../Cards/CardList/utils/utils';
import { useParams } from 'react-router-dom';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

interface PurseSelectProps {
  selectedPurse: number[];
  disabled: boolean;

  onChange(purseIds: number[]): void;
}

export const PurseSelect = memo(({ selectedPurse, disabled, onChange }: PurseSelectProps) => {
  const { t } = useTranslation();

  /* Получаем адресную строку */
  const params = useParams<{ holderId: string, accountId: string }>();
  /* Получаем список счетов выбранного клиента */
  const { activeHolderId } = useActiveHolder();

  const { data: dataAccount = { accounts: [] } } = accountsService.useGetAccountsByHolderIdQuery({ holderId: activeHolderId });

  const { data: dataPurses = { purses: [] } } = pursesListService.useGetPursesQuery('');


  const handleChange = (event: SelectChangeEvent<any>) => {
    const { target: { value } } = event;
    onChange(value);
  };


  const renderValue = (value: any) => {
    return dataPurses.purses.filter(purse => value.indexOf(purse.purseId) !== -1).map(purse => purse.remark).join(', ');
  };

  const filteredPursesList = useMemo(() => {
    // accountSubType = 0-не встановлений (змішаний), 101-гривневий, 102-літровий (кількісний)
    if (dataAccount.accounts.length === 0) return [];

    /* Если все счета присутствующие у клиента гривневые */
    const isAllMoney = dataAccount.accounts.filter(account => account.accountSubType === 101).length === dataAccount.accounts.length;
    if (isAllMoney) return dataPurses.purses.filter(purse => purse.purseTypeId === 0);

    /* Если все счета присутствующие у клиента литровые */
    const isAllQuantity = dataAccount.accounts.filter(account => account.accountSubType === 102).length === dataAccount.accounts.length;
    if (isAllQuantity) return dataPurses.purses.filter(purse => purse.purseTypeId === 1);

    return dataPurses.purses;

  }, [dataPurses.purses, dataAccount.accounts]);


  return (
    <FormControl variant={'filled'} disabled={disabled}>
      <InputLabel>{t('module.reports.titleFilterPurses').toUpperCase()}</InputLabel>
      <SelectMultiply
        multiple
        value={selectedPurse}
        onChange={handleChange}
        MenuProps={MenuProps}
        renderValue={renderValue}
        disabled={disabled}
      >
        {filteredPursesList.length > 0 && filteredPursesList.map((purse) => (
          <MenuItem key={purse.remark} value={purse.purseId}><Typography
            sx={{ color: purse.color }}>{purse.remark}</Typography></MenuItem>))}
      </SelectMultiply>
    </FormControl>
  );
});
