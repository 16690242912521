import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { transformCatchError } from '../api/errors/transform-catch-error';


export const cardsStatusMutation = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    setCardNewStatus: builder.mutation<void, { cardId: number, newStatus: boolean }>({
      async queryFn({ cardId, newStatus }, {}, extraOptions, fetchWithBQ) {
        try {
          //`/cards/${row.id}/update?lang=${locale}`;

          const request = {

          }

          const result = await fetchWithBQ({
            url: `/cards/${cardId}/update?active=${newStatus}`,
            method: 'POST',
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      invalidatesTags: ['Cards']
    }),
  }),
});
