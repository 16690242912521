import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';

import { transformCatchError } from '../api/errors/transform-catch-error';
import * as util from 'util';
import { isDraft } from '@reduxjs/toolkit';


export const apiKeyService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getApiKey: builder.query<{ publicKey: string }, any>({
      async queryFn({}, { dispatch }, extraOptions, fetchWithBQ) {
        try {
          const result = await fetchWithBQ({
            url: `/common/users/keys`,
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          /* Апи ключа не существует */
          if (err?.errorCode === 13) {
            return { data: { publicKey: '' } };
          }

          return { error: transformCatchError(err) };
        }
      },
      providesTags: () => ['ApiKey'],
    }),
  }),
});
