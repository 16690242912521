import * as React from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Box, Button } from '@mui/material';

type IInputFilled = TextFieldProps & {
  placeholder: string;
}


const TextFieldSearch = styled(TextField)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#EDEDED',
}));

const InputSearchLine = forwardRef(function InputSearchLine({ placeholder, onChange, ...other }: IInputFilled, ref) {
  const inputRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
  }));

  return (
    <Box sx={{
      '& .MuiOutlinedInput-root': { height: '45px' },
      '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
    }}>

      <TextFieldSearch
        inputRef={(instance) => inputRef.current = instance}
        onChange={onChange}
        placeholder={placeholder}
        style={{ marginTop: 11, borderRadius: '5px' }}
        {...other}
      />
    </Box>
  );
});

export default InputSearchLine;
