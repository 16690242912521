import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';

export const useValidation = (validationSchema: any) => {

  const {
    register, handleSubmit, formState: { errors }, setValue, clearErrors, getValues
  } = useForm({ resolver: yupResolver(validationSchema) });

  const validationError = useCallback((name: any) => {
    const field: any = errors[name];
    return ({ error: !!field, helperText: field?.message as any });
  }, [errors]);

  return {
    register, handleSubmit, errors, setValue, getValues, clearErrors, validationError,
  };

};
