import { IColumn } from '../../../ui/custom-table/components/Columns/Columns';
import { ISingleRow } from '../../../ui/custom-table/interface/interface.custom';
import i18n from 'i18next';

interface IGetColumns {
  onDownloadFileExcel({ row }: ISingleRow): void;
}

export const getColumns = ({ onDownloadFileExcel }: IGetColumns): IColumn[] => {


  return [{
    field: 'docNumber',
    label: i18n.t('module.documents.certificates.table.number'),
    formatHeader: 'default',
    cell: { format: 'default' },
    align: 'center',
    alignData: 'center',
  }, {
    field: 'docDate',
    label: i18n.t('module.common.filter.titleDate'),
    formatHeader: 'default',
    cell: { format: 'date-time' },
    align: 'center',
    alignData: 'center',
  }, {
    field: 'amount',
    label: i18n.t('module.documents.certificates.table.sumWithPDV'),
    formatHeader: 'default',
    cell: { format: 'numericalDigit' },
    align: 'right',
    alignData: 'right',
  }, {
    field: 'quantity',
    label: i18n.t('module.documents.certificates.table.qty'),
    formatHeader: 'default',
    cell: { format: 'numericalDigit' },
    align: 'right',
    alignData: 'right',
  }, {
    field: 'btn',
    label: '',
    formatHeader: 'default',
    cell: { format: 'icon-download', onDownloadFile: onDownloadFileExcel },
    align: 'center',
    alignData: 'center',
    width: 20
  }];
};
