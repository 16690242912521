import * as React from 'react';
import { memo, useMemo, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Typography } from '@mui/material';
import { cardStatus } from '../../../../../module';
import { useTranslation } from 'react-i18next';
import { CardsProps } from '../../../../../../interface';
import { MenuItem, StyledMenu } from './style/Popup';
import { ISingleRow } from '../../../interface/interface.custom';
import { Loading } from '../../../../loading';


interface PopupStatusCardProps {
  card: CardsProps;
  allowAccessStatus: number[];

  onChange?({ row }: ISingleRow): Promise<boolean>;
}

export const PopupStatusCard = memo(({ card, allowAccessStatus, onChange }: PopupStatusCardProps) => {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleClickItem = (status: number) => {
      if (onChange) {
        setLoading(true);
        const newRow = { ...card, status } as any;
        onChange({ row: newRow })
          .then((isChanged) => {
            if (isChanged) {
              setLoading(false);
              handleClose();
            }
          });
      }

    };

    const renderStatusMenu = useMemo(() => {
      return cardStatus
        .filter(status => allowAccessStatus.indexOf(status.id) !== -1)
        .map(status => {
          return <MenuItem key={status.id} onClick={handleClickItem.bind(null, status.id)}
                           bordercolor={status.color}>{t(`statusCard.${status.id}`, 'ERROR')}</MenuItem>;
        });
    }, [cardStatus, t, handleClickItem]);

    const renderColor = useMemo(() => {
      const selectedCard = cardStatus.find(status => status.id === card.status) || { color: '#ccc' };
      return selectedCard.color as string;
    }, [card]);

    if (isLoading) return <Typography align={'center'}> <Loading type={'small'} /></Typography>;

    return (
      <div>
        <Typography>{t(`statusCard.${card.status}`, 'ERROR')}
          <IconButton onClick={handleClick} sx={{ padding: 0 }}><MoreVertIcon
            sx={{ color: `${renderColor}` }} /></IconButton>
        </Typography>
        <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {renderStatusMenu}
        </StyledMenu>
      </div>
    );
  },
);

