import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { CardsProps, CardsPropService } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';


export const cardInfoService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getCardInfo: builder.query<CardsPropService, { cardId: string }>({
      async queryFn({ cardId }, {}, extraOptions, fetchWithBQ) {
        try {

          const result = await fetchWithBQ({
            url: `/cards/${cardId}`,
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          data.cards = data && data.cards && data.cards.map((card: CardsProps) => {

            return {
              ...card,
              fio: `${card.cardInfo?.lastName || ''} ${card.cardInfo?.firstName || ''}`,
              cardStatus: card.status === 0,
              firstName: card.cardInfo?.firstName || '',
              lastName: card.cardInfo?.lastName || '',
              mobile: card.cardInfo?.mobile || '',
              email: card.cardInfo?.email || '',
              cabinetEnabled: card.cardInfo?.cabinetEnabled || false,
            };
          });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      providesTags: () => ['CardInfo'],
    }),
  }),
});
