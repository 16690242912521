import { Theme } from '@mui/material/styles/createTheme';
import { makeStyles } from '@mui/styles';

export const useStylesCardMedia = makeStyles<Theme>(() => ({
  loginImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: '100vh',
  },
}));
