import React, { memo, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useCheckPermission } from '../../../../hooks';
import { routes } from '../../../../config';
import { Init } from '../../../module';
import { RouteProps } from '../../../../interface';


const absolutePath = '/holder/:holderId';

export const MainRoutes = memo(() => {

  const { checkPermissionRoute } = useCheckPermission();

  const renderRoutes = useMemo(() => {
    const allowRoutes: RouteProps[] = routes
      .filter(route => checkPermissionRoute(route));

    return allowRoutes.map(route => {
      const [pathname] = route.link.split('?');

      return (
        <Route key={route.link} path={pathname} element={route.components} />
      );
    });
  }, [checkPermissionRoute]);

  return (
    <Routes>

      <Route path={absolutePath}>
        <Route index path={absolutePath} element={<Init />} />
        {renderRoutes}
      </Route>

    </Routes>
  );
});
