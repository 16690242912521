import { memo, useMemo } from 'react';
import { DataItem, HBottomItem, HTopItem, LBottomItem, Logo, LTopItem, MainContainer, RTopItem } from './style';
import { MainChangeLanguage } from './components/ChangeLanguage';
//import { useModal } from '@hooks';
import { MainRoutes } from './routes/MainRoutes';
import { CurrentHolder } from './components/CurrentHolder';
import { Menu, MenuMobile } from './components/Menu';
import { holdersListService } from '../../../services';
//import { encrypt, decrypt } from '../../../utils';
import { useMediaQuery } from '../../../hooks';
import { useTranslation } from 'react-i18next';


export const MainLayout = memo(() => {
  const {t} = useTranslation();
  holdersListService.useGetHoldersQuery('');
  //const { showModal } = useModal();
  const matches = useMediaQuery();

  const handleOnClick = () => {
    console.log('MainLayout');
    //const enc = encrypt('MainLayout');
    //console.log(decrypt(enc));

    //showModal({ title: 'Title', children: 'TEST', callbackOk() {console.log('OK');} });
    //console.log('test', getAccess({ currentRole: Roles.Admin, accessPoint: 'holders.access.profile' }));
  };


  const renderMobile = useMemo(() => {
    return <MainContainer>
      <MenuMobile menu={<Menu />} />
      <HTopItem>
        <RTopItem>
          <CurrentHolder />
        </RTopItem>
      </HTopItem>
      <HBottomItem>
        <DataItem>
          <MainRoutes />
        </DataItem>
      </HBottomItem>

    </MainContainer>;
  }, [t]);

  const renderDesktop = useMemo(() => {
    return <MainContainer>
      <HTopItem>
        <LTopItem>
          <Logo />
        </LTopItem>
        <RTopItem>
          <MainChangeLanguage />
          <CurrentHolder />
        </RTopItem>
      </HTopItem>
      <HBottomItem>
        <LBottomItem>
          <Menu />
        </LBottomItem>
        <DataItem>
          <MainRoutes />
        </DataItem>
      </HBottomItem>
    </MainContainer>;
  }, [t]);

  return (
    matches ? renderMobile : renderDesktop
  );
});
