import { styled } from '@mui/material/styles';
import { BoxProps } from '@mui/material/Box/Box';
import { Box } from '@mui/material';

export const LanguageContainer = styled((props: BoxProps) => (<Box {...props} />))(({ theme }) => ({
  marginRight: '3.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '2rem. 1rem .2rem 1rem',
  width: '250px',
  [theme.breakpoints.down('md')]: {
    width: '200px',
  },
}));
