import { Grid, Typography } from '@mui/material';
import { apiKeyMutation, apiKeyService, transformResponseError } from '../../../../../services';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { Loading } from '../../../../ui';


export default function ApiKey() {
  const { t } = useTranslation();
  const { data: data = { publicKey: '' }, isLoading } = apiKeyService.useGetApiKeyQuery('');
  const [createApiKey, { isLoading: isLoadingCreateApi }] = apiKeyMutation.useCreateApiKeyMutation();
  const [deleteApiKey, { isLoading: isLoadingDeleteApi }] = apiKeyMutation.useDeleteApiKeyMutation();

  const handleCreateApiKey = async () => {
    await createApiKey('').then(({ error }: any) => {
      const isError = !!error;
      transformResponseError({ isError, error: String(error) });
    });
  };

  const handleDeleteApiKey = async () => {
    await deleteApiKey('').then(({ error }: any) => {
      const isError = !!error;
      transformResponseError({ isError, error: String(error) });
    });
  };

  if (isLoading) {
    return <Loading type={'medium'} />;
  }

  return <Grid item>
    {
      data.publicKey !== '' &&
      <Typography style={{ wordWrap: 'break-word' }}>
        <Typography style={{ fontWeight: 600 }}> {t('module.setting.api.titleApi')}</Typography>
        {data.publicKey}
      </Typography>
    }
    {data.publicKey === '' &&
      <LoadingButton
        variant={'contained'}
        sx={{ marginTop: '1rem' }}
        onClick={handleCreateApiKey}
        loading={isLoadingCreateApi}
      >
        {t('module.common.title.titleCreate')}
      </LoadingButton>}

    {data.publicKey !== '' &&
      <LoadingButton
        variant={'contained'}
        sx={{ marginTop: '1rem' }}
        onClick={handleDeleteApiKey}
        loading={isLoadingDeleteApi}
      >
        {t('module.common.title.titleDelete')}
      </LoadingButton>}

  </Grid>;
}
