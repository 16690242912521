import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';



export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


export const useConfig = () => useAppSelector(state => state.layoutReducer.config);
export const useProfile = () => useAppSelector(state => state.layoutReducer.profile);
