import { Grid, IconButton, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import React, { useMemo, useState } from "react";
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

const SelectCustom = styled( Select )( () => ( {
	"& .MuiSelect-select": {
		padding: "0 5rem 0 0",
		whiteSpace: 'break-spaces'
	},
	"& .MuiOutlinedInput-notchedOutline": {
		border: 'none'
	}
} ) );

const IconButtonCustom = styled( IconButton )( ( props ) => ( {
	padding: 0,
	"&:hover": {
		color: props.property === 'initial' ? 'text.secondary' : props.property === 'apply' ? '#4a9a4a' : '#c75b5b'
	}
} ) );

const Container = styled( Grid )( ( props ) => ( {
	position: 'relative',
	paddingRight: '3rem',
	display: 'flex',
	justifyContent: 'flex-end',
	[ props.theme.breakpoints.down( 'md' ) ]: {
		width: '100px'
	}
} ) );

const ContainerBtn = styled( Grid )( () => ( {
	position: 'absolute',
	right: 0,
	top: 0
} ) );

const ContainerField = styled( Grid )( () => ( {
	display: 'flex',
	justifyContent: 'space-between'
} ) );

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

interface ICellComboboxCheckbox {
	array: any[];
	optionName: string;
	fieldId: string;
	selected?: number[];
}

export default function CellComboboxMultiply ( { array, selected, optionName, fieldId }: ICellComboboxCheckbox ) {
	const [ isShowEdit, setShowEdit ] = useState( false );

	const handleSaveEditField = () => {
		console.log( 'handleSave' )
	};

	const handleOpenEditField = () => {
		setShowEdit( true );
	};

	const handleCancelEditField = () => {
		setShowEdit( false );
	}


	const [ selectedArray, setSelectedArray ] = useState<number[]>(  );

	const handleChange = ( event: SelectChangeEvent<any> ) => {
		const { target: { value } } = event;
		value && setSelectedArray( typeof value === 'string' ? value.split( ',' ) : value );
	};


	const renderTextField = useMemo( () => {
		return selectedArray && array.filter(a=>selectedArray.indexOf(a[fieldId]) !== -1 ).map(a=>(`[${a[fieldId]}]${a[optionName]}`)).join(', ');
	}, [ array, selectedArray ] );

	return <>
		{
			isShowEdit
				?
				<Container>
					<SelectCustom
						value={ selectedArray }
						multiple
						onChange={ handleChange }
						renderValue={ ( selected: any ) => selected.join( ', ' ) }
						MenuProps={ MenuProps }
					>
						{
							array.map( ( a ) => (
								<MenuItem key={ a[fieldId] } value={ a[fieldId] }>
									<Checkbox checked={ selectedArray && selectedArray.indexOf( a[fieldId] ) !== -1 }/>
									<ListItemText primary={ `[${a[fieldId]}] ${a[optionName]}` }/>
								</MenuItem>
							) )
						}

					</SelectCustom>
					<ContainerBtn>
						<IconButtonCustom property={ 'apply' } onClick={ handleSaveEditField }><SaveAsIcon/></IconButtonCustom>
						<IconButtonCustom property={ 'cancel' } onClick={ handleCancelEditField }><CancelIcon/></IconButtonCustom>
					</ContainerBtn>
				</Container>
				:
				<ContainerField>
					<>
						<Typography sx={ { flex: "1", fontSize: "inherit" } }>

							{ renderTextField }

						</Typography>
						<IconButtonCustom property={ 'initial' } onClick={ handleOpenEditField }> <EditIcon/>
						</IconButtonCustom>
					</>
				</ContainerField>
		}
	</>
}