import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { UserHoldersProps, UserProfileServiceProps, UserFindProfileService } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';


export const userProfileService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getUserProfile: builder.query<UserProfileServiceProps, { userId?: number, isEdit?: boolean }>({
      async queryFn({ userId = null, isEdit = false }, {}, extraOptions, fetchWithBQ) {
        try {

          const req = userId && userId !== -1
            ? { personalAccountUserId: userId }
            : {};

          const result = await fetchWithBQ({
            url: `/users/profile`,
            params: { isEdit, ...req, lang: getLangIso() },
          });

          const data = result && checkErrors<any>({ result, meta: result.meta });

          /* Суммируем все массивы и объединяем ФИО */
          data.user = {
            ...data.user,
            /* ФИО */
            fio: `${data.user?.lastName || ''} ${data.user?.firstName || ''}`,
            /* Кол-во холдеров привязанных к пользователю */
            holdersLength: data.user.holders?.length,
            holders: data.user?.holders?.map((holder: UserHoldersProps) => ({
              ...holder,
              accessAccountsLength: holder.accessAccounts?.length,
              accessAccounts: holder?.accessAccounts?.map(account => ({
                ...account,
                accessCardsLength: account.accessCards?.length,
              })),
            })),
          };

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      providesTags: () => ['UserProfile'],
    }),
    getFindExistUser: builder.query<UserFindProfileService, { phoneNumber: string }>({
      async queryFn({ phoneNumber }, {}, extraOptions, fetchWithBQ) {
        try {

          const result = await fetchWithBQ({
            url: `/users/find`,
            params: { phoneNumber, lang: getLangIso() },
          });

          const data = result && checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),
  }),
});
