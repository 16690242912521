import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const ContainerPurse = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%'
  },
  [theme.breakpoints.up('md')]: {
    width: '213px'
  },
  '& .MuiInputBase-root>input': {
    paddingRight: 0,
  },
}));
