import { CheckResponseProps } from '../../../interface';
import { checkNetworkErrors } from './check-network-errors';
/**  Перехват ошибок для лицензий */
export const checkErrors = <T>({ result, meta }: CheckResponseProps): T => {
  /**
   * Проверяем ошибки сети
   */
  checkNetworkErrors({ result, meta });

  const {
    response: { status },
  } = meta || {};

  /** Если ошибок нет удаляем объект с ошибками и возвращаем только данные */
  if (status === 200 && result.data.errorCode === 0) {
    delete result.data.errorCode;
    delete result.data.errorDescription;
    return result.data as T;
  }

  /**  Если есть ругаемся */
  if (status === 200 && result.data.errorCode !== 0) {
    throw result.data;
  }

  return result.data as T;
};
