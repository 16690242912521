import i18n from 'i18next';

import { IColumn } from '../../ui/custom-table/components/Columns/Columns';
import { ISingleRow } from '../../ui/custom-table/interface/interface.custom';
import { GetAccessProps } from '../../../interface';


interface IGetColumnsAccount {
  /**
   * Изменения статуса счета
   * @param row
   */
  onToggleSwitch({ row }: ISingleRow): Promise<boolean>;

  /**
   *  Проверяем доступность к методам
   */
  checkPermission<T>({ accessPoint }: Pick<GetAccessProps, 'accessPoint'>): T;
}

export const getColumns = ({ onToggleSwitch, checkPermission }: IGetColumnsAccount): IColumn[] => {

  const onChangeField = async ({ row }: ISingleRow) => {
    console.log('row', row);
    return true;//await handleUpdateLimit(Number(row.id), Number(row.limitAmount));
  };

  const onClickDelete = async ({ row }: ISingleRow) => {
    console.log('row', row);
    return true;//await handleDeleteLimit(Number(row.id));
  };

  const returnColumns: IColumn[] = [
    {
      field: 'collapse',
      label: '',
      formatHeader: 'default',
      cell: { format: 'collapse', type: 'accounts' },
      align: 'right',
      width: 10,
    },
    {
      field: 'accountId',
      label: i18n.t('module.accounts.titleID'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      sortable: true,
      width: 10,
    },
    {
      field: 'remark',
      label: i18n.t('module.accounts.titleRemark'),
      formatHeader: 'default',
      cell: {
        //format: checkPermission<boolean>({ accessPoint: 'cards.access.limit.edit' }) ? 'text-edit' : 'default',
        format: 'default',
        onChangeField,
      },
      align: 'center',
      sortable: true,
    },
    {
      field: 'totalBalance',
      label: i18n.t('module.accounts.titleRest'),
      formatHeader: 'default',
      cell: { format: 'numericalDigit' },
      align: 'center',
      alignData: 'center',
      sortable: true,
      width: 100,
    },
    {
      field: 'totalBalanceWithoutHold',
      label: i18n.t('module.accounts.titleTotalBalanceWithoutHold'),
      hint: i18n.t('module.accounts.titleTotalBalanceWithoutHoldHint'),
      formatHeader: 'default',
      cell: { format: 'numericalDigit' },
      align: 'center',
      alignData: 'center',
      sortable: true,
      width: 100,
    },
    {
      field: 'overdraft',
      label: i18n.t('module.accounts.titleOverdraft'),
      formatHeader: 'default',
      cell: { format: 'numericalDigit' },
      align: 'center',
      alignData: 'center',
      sortable: true,
      width: 100,
    },
    {
      field: 'active',
      label: i18n.t('module.common.table.active'),
      formatHeader: 'default',
      cell: {
        format: checkPermission<boolean>({ accessPoint: 'accounts.access.status.isAllow' }) ? 'popup-status' : 'boolean',
        onToggleSwitch,
        switchTooltip: [i18n.t('module.common.filter.titleIsActive'), i18n.t('module.common.filter.titleIsBlock')],
      },
      align: 'center',
      alignData: 'right',
      sortable: true,
      width: 150,
    },
    {
      field: 'btnAccount',
      label: i18n.t('module.accounts.titleCards'),
      formatHeader: 'default',
      cell: {
        format: checkPermission<boolean>({ accessPoint: 'accounts.access.cards.isAllow' }) ? 'link-account' : 'link-account-readonly',
        label: i18n.t('module.accounts.titleCards'),
      },
      align: 'center',
      alignData: 'center',
      sortable: true,
      width: 100,
    },
  ];

  // if (checkPermission<boolean>({ accessPoint: 'cards.access.limit.delete' })) {
  //   returnColumns.push({
  //     field: 'btnAccount',
  //     label: '',
  //     formatHeader: 'default',
  //     cell: {
  //       format: 'icon-delete',
  //       onClickDelete,
  //       labelConfirm: i18n.t('module.common.modal.labelConfirmation'),
  //     },
  //     align: 'center',
  //     alignData: 'center',
  //     width: 10,
  //   });
  // }

  return returnColumns;

};
