import { styled } from '@mui/material/styles';
import { Box, TableCell } from '@mui/material';

const StyleCell = styled(TableCell)(() => ({
  textAlign: 'center',
  border: 'initial',
  padding: '3rem 0',
}));

const FullScreenBox = styled(Box)(() => ({
  position: 'fixed',
  top: 0, bottom: 0, right: 0, left: 0,
  backgroundColor: 'rgba(122,142,161,0.37)',
  zIndex: 9999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export { StyleCell, FullScreenBox };
