import { IBillingDoc } from '../interface.billing';
import { TDocumentDefinitions } from 'pdfmake/interfaces';

import { getPDV } from '../../commonMethods';
import moment from 'moment';
import { convertMonthToString, getNumberToString } from '../../../../../../utils';

export const genBvsPDF = async ({
                                  numBill, supplier, holder, tax, sum,
                                }: IBillingDoc): Promise<TDocumentDefinitions> => {


  const { convertedInteger, integerCurrency, fractionalString, fractionalCurrency } = getNumberToString(sum);
  const sumToString = `${convertedInteger} ${integerCurrency} ${fractionalString} ${fractionalCurrency}`;
  /**
   * ПДВ и Сумма без ПДВ
   */
  const { pdv, bezPdv } = getPDV(sum, tax);
  /**
   * ПДВ
   */
  const PDV = pdv;

  return {
    info: {
      title: 'Bill', author: 'ТОВ "Промтерміналсервіс"', subject: 'Рахунок',
    },

    pageSize: 'A4', // by default we use portrait, you can change it to landscape if you wish
    pageOrientation: 'portrait', // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [40, 60, 40, 60],

    styles: {
      header: {
        fontSize: 22, bold: true,
      },
      centerBold: {
        bold: true, alignment: 'center',
      },
      titleRow: {
        decoration: 'underline', fontSize: 10, bold: true,

      },
      textBold: {
        fontSize: 10, bold: true,
      },
      textNormal: {
        fontSize: 10,
      },
      textCenter: {
        margin: [0, 35, 0, 0],
        bold: true,
        fontSize: 10,
      },
      columnAlignLeft: {
        alignment: 'left',
      },
      columnAlignRight: {
        alignment: 'right',
      },
      marginColumns: {
        margin: [0, 0, 0, 5],
      },
      centerTableBold: {
        bold: true, alignment: 'center', fontSize: 10, fillColor: '#F4ECC5',
      },
      centerNormal: {
        alignment: 'center', fontSize: 10,
      },
      cellAlignRight: {
        alignment: 'right', fontSize: 10,
      },
      cellAlignLeft: {
        alignment: 'left', fontSize: 10,
      },
    }, /*
     background: function(currentPage, pageSize) {
     return {image: 'sampleImage.jpg', width: 200, absolutePosition: {x: 0, y: 440}, opacity: 0.7}
     },
     */
    content: [

      {
        text: 'УВАГА! ЗАПОВНЮЙТЕ ПРИЗНАЧЕННЯ ПЛАТЕЖУ ТАК, ЯК ВКАЗАНО В РАХУНКУ', style: 'centerBold', fontSize: 11,
      }, {
        text: 'В випадку неточного або неповного вказання номеру рахунку кошти не будуть зараховані на поповнення смарт-карт',
        style: 'centerBold',
        fontSize: 11,
      }, supplier.map((str, idx) => {
        return (idx === 0)
          ?
          {
            columns: [
              { width: 80, text: 'Постачальник', style: 'titleRow' },
              { width: '*', text: str, style: 'textBold' },
            ],
            margin: [0, 20, 0, 0],
            columnGap: 18,
          }
          : {
            columns: [
              { width: 80, text: '' },
              { width: '*', text: str, style: 'textNormal' },
            ],
            columnGap: 18,
          };
      }),

      {
        columns: [
          { width: 80, text: 'Одержувач', style: 'titleRow' },
          { width: '*', text: holder.fullName, style: 'textBold' },
        ],
        margin: [0, 5, 0, 0], columnGap: 18,
      },
      {
        columns: [
          { width: 80, text: '' },
          { width: '*', text: `тел. ${holder.phone}`, style: 'textNormal' },
        ],
        columnGap: 18,
      },

      {
        columns: [
          { width: 80, text: 'Платник', style: 'titleRow' },
          { width: '*', text: 'той самий', style: 'textNormal' },
        ],
        margin: [0, 5, 0, 0],
        columnGap: 18,
      },

      {
        columns: [
          { width: 80, text: 'Договір купівлі-продажу та надання послуг №', style: 'titleRow' },
          { width: '*', text: holder.contractNumber, style: 'textCenter' },
        ],
        margin: [0, 5, 0, 0],
        columnGap: 18,
      },

      {
        columns: [
          { width: 80, text: 'Призн. платежу', style: 'titleRow' },
          {
            width: '*',
            text: `Покупці палива через Мпос, дог.№ ${holder.contractNumber} зг. рах.№ ${numBill} від ${moment().format('DD.MM.YY')}`,
            style: 'textBold',
          },
        ],
        margin: [0, 5, 0, 15],
        columnGap: 18,
      },


      { text: `Рахунок № ${numBill}`, style: 'centerBold', fontSize: 12 },

      {
        columns: [
          { width: 75, text: 'м. Полтава', style: 'columnAlignLeft', fontSize: 11, bold: true },
          {
            width: '*',
            text: `від ${moment().format('DD')} ${convertMonthToString(moment().format('MM'))} ${moment().format('YYYY')} р.`,
            style: 'columnAlignRight',
            fontSize: 11,
            bold: true,
          },
        ],
      },


      {

        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths: [25, '*', 30, 45, 57, 56],
          body: [
            [
              { text: '№', style: 'centerTableBold', margin: [0, 11] },
              { text: 'Номенклатура', style: 'centerTableBold', margin: [0, 11] },
              { text: 'Од.', style: 'centerTableBold', margin: [0, 11] },
              { text: 'Кількість', style: 'centerTableBold', margin: [0, 11] },
              { text: 'Ставка ПДВ', style: 'centerTableBold', margin: [0, 11] },
              { text: 'Сума з ПДВ', style: 'centerTableBold', margin: [0, 11] },
            ],
            [
              { text: '1', style: 'centerNormal' },
              { text: 'Поповнення рахунку', style: 'cellAlignLeft' },
              { text: 'грн', style: 'centerNormal' },
              { text: sum, style: 'cellAlignRight' },
              { text: `${tax}%`, style: 'centerNormal' },
              { text: sum.toFixed(2), style: 'cellAlignRight' },
            ],
            [
              {
                text: 'Разом без ПДВ:',
                colSpan: 5,
                style: 'cellAlignRight',
                bold: true,
                border: [false, false, false, false],
              },
              { text: '', style: 'cellAlignRight' },
              { text: '', style: 'cellAlignRight' },
              { text: '', style: 'cellAlignRight' },
              { text: '', style: 'cellAlignRight' },
              { text: bezPdv, style: 'cellAlignRight', bold: true },
            ],
            [
              { text: 'ПДВ:', colSpan: 5, style: 'cellAlignRight', bold: true, border: [false, false, false, false] },
              { text: '', style: 'cellAlignRight' },
              { text: '', style: 'cellAlignRight' },
              { text: '', style: 'cellAlignRight' },
              { text: '', style: 'cellAlignRight' },
              { text: PDV, style: 'cellAlignRight', bold: true },
            ],
            [
              {
                text: 'Всього з ПДВ:',
                colSpan: 5,
                style: 'cellAlignRight',
                bold: true,
                border: [false, false, false, false],
              },
              { text: '', style: 'cellAlignRight' },
              { text: '', style: 'cellAlignRight' },
              { text: '', style: 'cellAlignRight' },
              { text: '', style: 'cellAlignRight' },
              { text: sum.toFixed(2), style: 'cellAlignRight', bold: true },
            ]],
        },
      },
      { text: 'Всього на суму:', fontSize: 10, margin: [0, 20, 0, 0] },
      { text: sumToString, fontSize: 10, bold: true },
      { text: `ПДВ: ${PDV} грн.`, fontSize: 10 }],
  };
};
