import moment from 'moment';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { NavLink } from "react-router-dom";
import { Bool } from "../../../custom-table/components/Cell/FetchFormats/Bool";


const Link = styled( Typography )( ( { theme } ) => ( {
	overflow: "hidden",
	textOverflow: "ellipsis",
	"&:hover ": {
		cursor: "pointer",
		color: theme.palette.primary.main
	}
} ) );

export enum ct {
	DEFAULT = 'default',
	BOOL = 'boolean',
	IS_DANGER = 'IS_DANGER',
	DATE = 'date',
	DATE_WITH_INFINITY = 'DATE_WITH_INFINITY',
	DATE_FROM_UNIX = 'DATE_FROM_UNIX',
	DATE_TIME = 'DATE_TIME',
	IS_FILE = 'IS_FILE',
	IS_VIEWED = 'IS_VIEWED',
	LINK = 'LINK',
	ROUND_2 = 'ROUND_2',
	LINK_HOVER = 'LINK_HOVER'
}

interface ICellType {
	type?: ct.DEFAULT | ct.BOOL | ct.IS_DANGER | ct.DATE | ct.ROUND_2 | ct.DATE_WITH_INFINITY | ct.DATE_TIME | ct.DATE_FROM_UNIX | ct.IS_FILE | ct.IS_VIEWED | ct.LINK | ct.LINK_HOVER;
	row?: any;

	[ key: string ]: any
}

const CustomWidthTooltip = styled( ( { className, ...props }: TooltipProps ) => (
	<Tooltip classes={ { popper: className } } placement="right"  { ...props } />
) )( {
	[ `& .${ tooltipClasses.tooltip }` ]: {
		maxWidth: 500,
	},
} );

export function CellType ( { type = ct.DEFAULT, value = 'No data', row = {} }: ICellType ): JSX.Element {
	let response: any;
	switch ( type ) {
		/**
		 * Округляем до двух знаков
		 */
		case ct.ROUND_2 : {
			response = value.toLocaleString();
			break;
		}
		/**
		 * Ссылка на файл
		 */
		case ct.LINK : {
			response = <CustomWidthTooltip title={ value }><Link>{ value }</Link></CustomWidthTooltip>;
			break;
		}
		/**
		 * Ссылка с ховером
		 */
		case ct.LINK_HOVER : {
			response =
				<NavLink style={ { ...value.style } } to={ value.link }><Typography
					sx={ { ...value.sx } }>{ value.title }</Typography></NavLink>;
			break;
		}
		/**
		 * Просмотрено/ Не просмотрено
		 */
		case ct.IS_VIEWED : {
			response = value === 0
				? <VisibilityOffIcon fontSize={ "small" } color={ "error" }/>
				: <VisibilityIcon fontSize={ "small" } color={ "success" }/>
			break;
		}

		case ct.BOOL : {
			response = <Bool value={ value } row={ row }/>
			break;
		}

		/* Применил для отображения удален/не удален  */
		case ct.IS_DANGER : {
			response = value && <ReportProblemIcon color={ "error" } fontSize={ "small" }/>;
			break;
		}

		/* Привязаны ли файлы к записи */
		case ct.IS_FILE : {
			response = value && <AttachFileIcon color={ "success" } fontSize={ "small" }/>;
			break;
		}

		case ct.DATE : {
			response = `${ moment( value ).format( 'DD.MM.YYYY' ) }`;
			break;
		}
		case ct.DATE_WITH_INFINITY: {
			if ( value === '2050-01-01T00:00:00' ) {
				response = <Typography color={ "primary" }>Ꝏ</Typography>;
			} else {
				response = `${ moment( value ).format( 'DD.MM.YYYY' ) }`;
			}

			break;
		}

		case ct.DATE_TIME : {
			response = `${ moment( value ).format( 'DD.MM.YYYY HH:mm:ss' ) }`;
			break;
		}

		case ct.DATE_FROM_UNIX : {
			response = `${ moment.unix( value ).format( 'DD.MM.YYYY' ) }`;
			break;
		}


		default : {
			response = <CustomWidthTooltip title={ value }><Box style={ {
				overflow: "hidden",
				textOverflow: "ellipsis"
			} }>{ value }</Box></CustomWidthTooltip>
			break;
		}
	}
	return response
}

export enum hd {
	DEFAULT = 'default'
}

interface IHeaderType {
	type?: hd.DEFAULT;

	[ key: string ]: any
}

export function HeaderType ( { type = hd.DEFAULT, value = 'No data', tooltip = undefined }: IHeaderType ): JSX.Element {
	let response;
	switch ( type ) {
		default : {
			response = <CustomWidthTooltip title={ ( tooltip ) ? tooltip : value } placement="top">
				<Box style={ {
					overflow: "hidden",
					textOverflow: "ellipsis"
				} }>{ value }</Box></CustomWidthTooltip>
			break;
		}
	}
	return response;
}
