import { RequestMoneyTransferProps } from '../../../../interface';


export const initialMoneyTransfer: RequestMoneyTransferProps = {
  sourceAccountId: -1,
  destinationAccountId: -1,
  purseId: -1,
  value: 0,
};

export const initialMoneyTransferTempProps: MoneyTransferTempProps = {
  sourceAccountSubType: -1
};

export interface MoneyTransferTempProps {
  sourceAccountSubType: number;
}
