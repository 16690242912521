import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HolderGroupTitle, InnerContainer } from '../../../common';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Box, Button, Grid } from '@mui/material';
import { ComboBox } from '../../../ui/input';
import { styled } from '@mui/material/styles';

import FilterTable from '../../../ui/filter/FilterTable';
import CustomTable from '../../../ui/custom-table';

import { IFilter, IOrderBy, ISingleRow } from '../../../ui/custom-table/interface/interface.custom';

import { getColumnsCard } from './cards.columns';
import { filterApply } from './filter/filter';
import { IFilterCardsField, initialStateFilterCard } from './filter/interface.filter';
import FilterContext from './filter/context.card';
//import DriverCards from '../../DriverCards/DriverCards';
import { IRow } from '../../../ui/custom-table/components/Rows/Rows';

import { getAccountIdFromParams, getFormatListAccount } from './utils/utils';
import { useActiveHolder, useCheckPermission, useConfig, useMediaQuery, useModal } from '../../../../hooks';
import { accountsService, cardListService, cardMutation, getStorage, setStorage, transformResponseError } from '../../../../services';
import { COUNT_PER_ON_PAGE, ModalButton, Storage } from '../../../../config';
import { useTranslation } from 'react-i18next';
import { ContainerRow } from '../../../ui';
import { RequestCardDataUpdateProps } from '../../../../interface';
import { GetOrderVirtualCard } from './components';

const AccountCombobox = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginLeft: '0',
    marginTop: '1rem',
  },
}));

/* Кол-во строк в таблице */
const countPerOnPage: number = +COUNT_PER_ON_PAGE;

export default function CardsList() {
  const { t } = useTranslation();
  const matches = useMediaQuery();
  /* Получаем адресную строку */
  const params = useParams();

  /* Управляем линками */
  const navigate = useNavigate();
  const config = useConfig();
  const [searchParams, setSearchParams] = useSearchParams();

  /* Получаем список счетов выбранного клиента */
  const { activeHolderId } = useActiveHolder();
  const {
    data: dataAccount = { accounts: [] },
    isLoading: isLoadingAccount,
  } = accountsService.useGetAccountsByHolderIdQuery({ holderId: activeHolderId });

  const pAccountId = useMemo(() => (getAccountIdFromParams(params)), [params?.accountId]);

  const { data: dataCards = { cards: [] }, isLoading } = cardListService.useGetCardListQuery({
    accountId: pAccountId,
    holderId: activeHolderId,
  });

  useEffect(() => {
    if (params) {
      const startPageCardList: number = parseInt(searchParams.get('page') || '1');
      setPage(startPageCardList - 1);

      const filter = searchParams.get('filter') || '';
      if (filter !== '') {
        setFilterState(JSON.parse(filter));
      } else {
        const storageFilter = getStorage(Storage.listCardFilter) as string;
        storageFilter && setFilterState(JSON.parse(storageFilter));
      }
    }
  }, []);

  const handleChangeAccount = (selectedAccount: any) => {
    searchParams.set('page', `1`);
    setSearchParams(searchParams);
    navigate(`/holder/${activeHolderId}/account/${selectedAccount}/cards?${searchParams}`);
    setPage(0);
  };

  /* Фильтр таблицы */
  const [filter, setFilterState] = useState<IFilterCardsField>(initialStateFilterCard);
  const [isFilterApply, setApplyFilter] = useState<boolean>(false);
  /* изменение фильтра */
  const handleChangeFilter = (newFilter: IFilterCardsField) => {
    searchParams.set('filter', JSON.stringify(newFilter));
    setSearchParams(searchParams);
    setStorage(Storage.listCardFilter, JSON.stringify(newFilter));
    setFilterState(newFilter);
    setPage(0);
  };

  const handleClearFilter = () => {
    searchParams.set('filter', '');
    setSearchParams(searchParams);
    setStorage(Storage.listCardFilter, JSON.stringify(initialStateFilterCard));
    setFilterState(initialStateFilterCard);
    handleCommitFilter();
    setPage(0);
  };
  /* Применение фильтра */
  const handleCommitFilter = useCallback(() => {
    setApplyFilter(prev => !prev);
    setPage(0);
  }, [filter]);

  /* Текущая страница счетов */
  const [pageNum, setPage] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<IOrderBy>({ field: '', order: 'asc' });
  const handleChangePage = ({ page, orderBy }: IFilter) => {
    setOrderBy(orderBy);
    searchParams.set('page', `${page}`);
    setSearchParams(searchParams);
    setPage(page - 1);
  };

  const [cardChecked, setCardChecked] = useState<string[]>([]);

  const filteredRows = useMemo(() => {
    const filteredArr = filterApply({ cards: dataCards.cards, filter, orderBy }) || [];

    return filteredArr.map(card => {
      const checked = cardChecked.indexOf(card.cardId) !== -1;
      return { ...card, checked };
    });

  }, [dataCards.cards, filter, orderBy, cardChecked]);

  /* Фильтр по столбцам таблицы  */
  const rows = useMemo(() => {
    /* Применение фильтра */
    let filteredArr = filteredRows;
    const count = filteredArr.length;

    /* Обрезаем массив до необходимых размеров отображения на странице */
    const startRow = pageNum === 0 ? 0 : (pageNum * countPerOnPage);
    const stopRow = (pageNum + 1) * countPerOnPage;

    /* Обрезаем по строкам на странице после фильтра */
    filteredArr = filteredArr.slice(startRow, stopRow);

    return { arr: filteredArr, count };

  }, [pageNum, isFilterApply, filteredRows, params, cardChecked]);

  const [updateCard] = cardMutation.useUpdateCardDataMutation();

  const handleChangeCardStatus = async ({ row }: ISingleRow): Promise<boolean> => {
    const newCardData: RequestCardDataUpdateProps = {
      status: row.status,
      mobile: row.mobile,
      vin: row.vin,
      cardInfo: row.cardInfo,
    };

    return updateCard({ request: newCardData, cardId: row.cardId, isUpdateCard: true })
      .then(({ error }: any) => {
        const isError = !!error;
        transformResponseError({ isError, error: String(error) });
        return !isError;
      });
  };

  const arrayChecked = useMemo(() => {
    return filteredRows.map(item => item.checked);
  }, [filteredRows]);

  const handleOnCheckCard = (cardId: string, checked: boolean) => {
    const isExistCard = !!cardChecked.find(card => card === cardId);
    if (!checked && isExistCard) {
      setCardChecked(prev => prev.filter(i => i !== cardId));
    } else {
      setCardChecked(prev => ([...prev, cardId]));
    }
  };

  const handleOnCheckAllCard = (checked: boolean) => {
    if (checked) {
      setCardChecked(filteredRows.map(card => card.cardId));
    } else setCardChecked([]);
  };

  /* Заполняем табличные заголовки и проверяем права доступа */
  const { checkPermission } = useCheckPermission();

  const columns = useMemo(() => {
    return getColumnsCard({
      arrayChecked,
      onCheck: handleOnCheckCard,
      onCheckAll: handleOnCheckAllCard,
      onToggleSwitch: handleChangeCardStatus,
      checkPermission,
    });
  }, [t, handleChangeCardStatus, checkPermission, handleOnCheckCard, handleOnCheckAllCard, arrayChecked]);
  const { showModal, closeModal } = useModal();

  {/*
   const [filterColumns, setFilterColumn] = useState<string[]>([]);
   const columnsFilter = useMemo(() => {
   return columns.filter(column => (filterColumns.indexOf(column.field) === -1));
   }, [t, handleChangeCardStatus, checkPermission, columns, filterColumns]);
   const handleHideColumns = () => {
   showModal({
   buttonType: ModalButton.Empty,
   children: <HideTableColumn
   hideColumns={filterColumns}
   columns={columns}
   closeModal={closeModal}
   onChangeColumnHide={(hideColumns) => setFilterColumn(hideColumns)} />,
   });
   };
   */
  }
  const handleDoubleClick = (row: IRow) => {
    const startPageCardList = searchParams.get('page') || '1';
    navigate(`/holder/${activeHolderId}/account/${row.accountId}/card/${row.cardId}?page=${startPageCardList}&filter=${encodeURIComponent(JSON.stringify(filter))}`);
  };

  const listAccount = useMemo(() => (getFormatListAccount(dataAccount.accounts)), [dataAccount.accounts]);
  {/*
   //const role = getStorage('role');
   const matches = useMediaQuery('(max-width:1024px)');

   if (role === 'SmartCardsDriver' && matches) {
   return  <></>

   <DriverCards />;

   }  */
  }

  const handleCreateVirtualCard = () => {
    showModal({
      title: t('module.cards.orderCardTitle'),
      buttonType: ModalButton.Empty,
      children: <GetOrderVirtualCard onClose={closeModal} accountId={pAccountId} />,
    });
  };

  /* Получаем доступность */
  const isAllowCreateVirtualCard = useMemo(() => {
    return checkPermission<boolean>({ accessPoint: 'cards.access.createVirtualCard' });
  }, [checkPermission]);


  return <>
    <FilterContext.Provider value={{ filter }}>
      <InnerContainer direction={'left'} show={true} sx={{ zIndex: 0 }}>
        <ContainerRow>

          <Grid item xs={12} md={9} style={{ flexDirection: 'column', padding: '1rem' }}>
            {/* Выпадающий список клиентов */}
            <Grid container style={{ display: 'flex', alignItems: 'center' }}>

              <AccountCombobox item xs={12} md={4}>
                <ComboBox
                  textStyle={{ fontWeight: '700' }}
                  data={listAccount}
                  fieldLabelOption={'remark'}
                  label={t('module.dataGroup.accounts')}
                  fnOnChange={handleChangeAccount}
                  defaultValue={pAccountId}
                />
              </AccountCombobox>
            </Grid>

          </Grid>

          <Grid item xs={12} md={10}
                flexWrap={matches ? 'wrap' : 'nowrap'}
                style={{
                  display: 'flex', justifyContent: 'space-between', padding: '1rem', alignItems: 'center',
                }}>
            <Box sx={{ display: 'flex' }}>

              <HolderGroupTitle mt={1} title={`${t('module.common.title.titleCards')}`} />

              {/* Фильтр таблицы */}
              <FilterTable
                type={'cards'}
                onFilterClear={handleClearFilter}
                onFilterChange={handleChangeFilter}
                onFilterCommit={handleCommitFilter}
              />
            </Box>
            { /*  Скрытие столбцов
             <Typography sx={{ ml: 2, mr: 2 }}>|</Typography>
             <IconButton type={ButtonType.Columns} onClick={handleHideColumns} tooltip={t('module.common.filter.titleHideColumns')}/>
             */}
            {
              isAllowCreateVirtualCard && config?.createVirtualCard && <Button variant={'contained'} sx={{
                width: matches ? '170px' : '190px',
                marginBottom: matches ? '.5rem' : '0',
                fontSize: matches ? '.7rem' : '.9rem',
              }} onClick={handleCreateVirtualCard}>
                {t('module.cards.orderCardBtn')}
              </Button>
            }


          </Grid>

          <Grid item xs={12} md={10} style={{ flexDirection: 'column', padding: '1rem' }}>

            <CustomTable
              rows={rows.arr}
              columns={columns}
              isLoading={isLoadingAccount || isLoading}
              count={rows.count}
              labelEmptyRows={t('module.common.table.emptyRows')}
              rowsPerPageOptions={[countPerOnPage]}
              fnRefreshData={handleChangePage}
              pageNum={pageNum}
              accountId={pAccountId}
              holderId={activeHolderId}
              onDoubleClick={handleDoubleClick}
              sx={{ '& .MuiTableCell-root:first-child': { padding: '4px 0 0' } }}
            />

          </Grid>


        </ContainerRow>
      </InnerContainer>
    </FilterContext.Provider>
  </>;
}
