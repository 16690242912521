import { Route, Routes } from 'react-router-dom';
import { Login, NewPassAfterOtp, Register } from '../../../module';
import { LinkRegisterType } from '../../../../helpers';


export const AuthRoutes = () => {
  return (
    <Routes>
      <Route index path='/' element={<></>} />
      <Route path='/login' element={<Login />} />
      <Route path='/register' element={<Register screenType={LinkRegisterType.Register} />} />
      <Route path='/forgot-password' element={<Register screenType={LinkRegisterType.ForgotPassword} />} />
      <Route path='/:typeOtp/:phone/otp/' element={<NewPassAfterOtp />} />
    </Routes>
  );
};
