import { FilterObjectProps } from '../../interface';

/**
 * Поиск по нескольким полям в массиве
 * @param arr - Исходный массив
 * @param fields - поля по которым необходим поиск ['field']
 * @param searchStr - строка поиска
 */

export function filterObject<T>({
                                  arr,
                                  fields = [''],
                                  searchStr = '',
                                }: FilterObjectProps<T>) {

  if (searchStr === '') return arr;

  const lowerCaseSearchStr = searchStr.toLowerCase();

  const findArr = arr.filter((item: any) => {
    return fields.some(field => {
      const fieldValue = item[field]?.toString()?.toLowerCase();
      return fieldValue && fieldValue.includes(lowerCaseSearchStr);
    });
  });

  return findArr.length > 0 ? findArr : [];
}
