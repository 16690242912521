/**
 * 0 - по умолчанию (Ок, Отмена)
 * 1 - Ок
 * 2 - Отмена
 * 3 - Пусто
 */
export enum ModalButton {
  OkCancel = 0,
  Ok = 1,
  Cancel = 2,
  Empty = 3
}
