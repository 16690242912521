import React from 'react';
import { Box, Grid } from '@mui/material';
import { useCustomStyles } from '../../../styles';
import { ContainerColumn } from '../container';

interface IInfoBlock {
  title: string;
  children: React.ReactElement<any, any>;
  sx?: React.CSSProperties;
}


export default function TitleBlock({ title, sx = {}, children }: IInfoBlock) {
  const classes = useCustomStyles();
  return <>
    <Box sx={{ ...sx }}>
      <ContainerColumn>
        <Grid className={classes.textDefault}>{title}</Grid>
        <Grid className={classes.titleDefault}> {children}</Grid>
      </ContainerColumn>
    </Box>
  </>;
}
