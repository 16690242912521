import { memo, useMemo, useState } from 'react';
import { accountsService } from '../../../../../services';
import { useActiveHolder } from '../../../../../hooks';
import { getColumns } from './columns.accounts';
import { useTranslation } from 'react-i18next';
import { IFilter, IOrderBy } from '../../../../ui/custom-table/interface/interface.custom';
import { Grid } from '@mui/material';
import { HolderGroupTitle } from '../../../../common';
import { Button } from '../../../../ui';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../../../ui/custom-table';

/* Кол-во строк в таблице счетов, на странице инфо клиента */
const countPerOnPage: number = 5;

export const HolderAccounts = memo(() => {
  const { t } = useTranslation();
  const { activeHolderId } = useActiveHolder();
  /* Управляем линками */
  const navigate = useNavigate();

  const {
    data: dataAccounts = { accounts: [] },
    isLoading,
  } = accountsService.useGetAccountsByHolderIdQuery({ holderId: activeHolderId });

  const [pageNum, setPage] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<IOrderBy>({ field: '', order: 'asc' });
  const handleChangePage = ({ page, orderBy }: IFilter) => {
    setOrderBy(orderBy);
    setPage(page - 1);
  };

  /* Заполняем табличные заголовки */
  const columns = useMemo(() => {
    return getColumns();
  }, [t]);

  /* Фильтр по столбцам таблицы  */
  const rows = useMemo(() => {
    let count = 0;
    /* Применение фильтра */
    let filteredObj = dataAccounts.accounts;

    count = filteredObj.length;

    /* Обрезаем массив до необходимых размеров отображения на странице */
    const startRow = pageNum === 0 ? 0 : (pageNum * countPerOnPage);
    const stopRow = (pageNum + 1) * countPerOnPage;

    /* Обрезаем по строкам на странице после фильтра */
    filteredObj = filteredObj.slice(startRow, stopRow);

    return { arr: filteredObj, count };

  }, [dataAccounts.accounts, pageNum]);

  const renderTitle = useMemo(() => {
    return `${t('module.dataGroup.accounts')} (${dataAccounts.accounts.length})`;
  }, [t, dataAccounts.accounts.length]);

  return (<>
      <Grid item xs={12}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Grid item>
          <HolderGroupTitle mt={1} title={renderTitle} />
        </Grid>
        <Grid item style={{ marginLeft: '2rem' }}>
          <Button variant={'contained'}
                  onClick={() => navigate(`/holder/${activeHolderId}/accounts`)}>{t('module.accounts.btnAccountLink')}</Button>
        </Grid>
      </Grid>
      <Grid item xs={12} md={9}>

        <CustomTable
          rows={rows.arr}
          columns={columns}
          isLoading={isLoading}
          count={rows.count}
          labelEmptyRows={t('module.clientList.titlePage')}
          rowsPerPageOptions={[countPerOnPage]}
          fnRefreshData={handleChangePage}
          sx={{ minHeight: '199px' }}
        />

      </Grid>
    </>
  );
});
