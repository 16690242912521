import { memo } from 'react';
import { Box, Checkbox, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '../../../../hooks';

interface BtnProps {

  onClick(e: any): void;
}

interface UserButtonsProps {
  addBtn?: BtnProps;
  editBtn?: BtnProps;
  allUser?: BtnProps;
}

export const UserButtons = memo(({ addBtn, editBtn, allUser }: UserButtonsProps) => {
  const { t } = useTranslation();
  const matches = useMediaQuery();
  return (
    <Box sx={{ display: 'flex' }}>
      {addBtn &&
        <IconButton onClick={addBtn.onClick}><Tooltip title={t('modal.labelAdd')}><AddIcon /></Tooltip></IconButton>}
      {editBtn &&
        <IconButton onClick={editBtn.onClick}><Tooltip title={t('modal.labelEdit')}><EditIcon /></Tooltip></IconButton>}
      {
        allUser &&
        <FormControlLabel sx={{ marginLeft: matches ? 0 : '1rem' }} control={<Checkbox onClick={allUser.onClick} />}
                          label={t('module.common.title.titleOnlyCurrentHolder')} />
      }
    </Box>
  );
});
