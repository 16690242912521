import React, { useEffect, useState } from 'react';
import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";
import { ISingleRow } from "../../../interface/interface.custom";
import { Loading } from '../../../../loading';

const AntSwitch = styled( Switch )( ( { theme } ) => ( {
	margin: " 0 auto",
	width: 28,
	height: 16,
	padding: 0,
	display: 'flex',
	'&:active': {
		'& .MuiSwitch-thumb': {
			width: 15,
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			transform: 'translateX(9px)',
		},
	},
	'& .MuiSwitch-switchBase': {
		padding: 2,
		'&.Mui-checked': {
			transform: 'translateX(12px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor: theme.palette.mode === 'dark' ? '#1a8531' : '#000000',
			},
		},
	},
	'& .MuiSwitch-thumb': {
		boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
		width: 12,
		height: 12,
		borderRadius: 6,
		transition: theme.transitions.create( [ 'width' ], {
			duration: 200,
		} ),
	},
	'& .MuiSwitch-track': {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor:
			theme.palette.mode === 'dark' ? 'rgba(231,34,34,0.84)' : 'rgba(73,73,73,0.36)',
		boxSizing: 'border-box',
	},
} ) );

interface IBool {
	onToggleSwitch? ( { row }: ISingleRow ): Promise<boolean>;
	disabled?:boolean;
	value: boolean;
	row: any;
}

export const Bool = ( { value, row, onToggleSwitch, disabled = false }: IBool ) => {
	const [ isLoading, setLoading ] = useState<boolean>( false );

	const handleChangeSwitch = async ( e: React.ChangeEvent<HTMLInputElement> ) => {

		setLoading( true );

		if ( onToggleSwitch )
			onToggleSwitch( { row } )
				.then( res => {
					//res && setActive( e.target.checked );
					setLoading( false );
				} );
	};

	return (
		isLoading
			? <Loading type={ 'small' }/>
			: <AntSwitch checked={ value } onChange={ handleChangeSwitch } disabled={disabled}/>
	);
}
