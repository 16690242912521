import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(({theme}) => ({
  top: 0, right: 0, left: 0, bottom: 0,
  backgroundColor: 'inherit',
  position: 'absolute',
  padding: '1rem 0 0 2rem',
  height: 'fit-content',
  borderRadius: '10px',
  zIndex: '2',
  [theme.breakpoints.down('md')]: {
    padding: '0',
  },
}));
