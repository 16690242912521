import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import moment from 'moment';
import { GridTitle } from './LimitsCardDriver';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { HolderGroupTitle } from '../../../common';
import { useTranslation } from 'react-i18next';
import { reportService } from '../../../../services';
import { ReportProducts, TransactionProps } from '../../../../interface';
import { initialStateReportTrn } from '../../Reports';

const AccordionWrapper = styled(Accordion)(() => ({
  position: 'relative',
  zIndex: 9999,
}));

const TextSmallSize = styled(Typography)(() => ({
  fontSize: 10,
}));

const TextMediumSize = styled(Typography)(() => ({
  fontSize: 12,
}));

const TextBold = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 600,
}));

const Wrapper = styled(Box)(() => ({
  width: '310px',
  margin: '1rem auto',
  '& .MuiAccordion-root:before': {
    display: 'none',
  },
  border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f6f6f6',

}));

interface ILastTrnForDriver {
  cardId: string;
}

export default function LastTrnForDriver({ cardId }: ILastTrnForDriver) {
  const { t } = useTranslation();

  const [getReport, {
    data: dataReport = { transactions: [], countOfRows: 5 }, isLoading,
  }] = reportService.useLazyGetReportTransactionDriverQuery();

  useEffect(() => {
    cardId && getReport({
      ...initialStateReportTrn,
      limit: 5,
      with_cancelation: false,
      holderId: 0,
      cardId,
      fromDate: moment().format('2000-MM-01'),
      toDate: moment().format('YYYY-MM-DD'),
    });
  }, [cardId]);

  return <>
    <Grid item style={{ margin: '1rem', position: 'relative', zIndex: 9999 }}>
      <GridTitle item xs={12} textAlign={'center'} sx={{ margin: '1rem' }}>
        <HolderGroupTitle isLoading={isLoading}
                          title={t('module.reports.titleLastTrn')} mt={0}
        />
      </GridTitle>

      {dataReport.transactions.map((trn: TransactionProps) => (
        <Wrapper key={trn.id} mb={2}>

          <Grid container sx={{ padding: '.5rem' }}>
            <Grid item xs={12} display={'flex'} flexDirection={'row'}>
              <Grid item xs={6}> <TextBold>{trn.purseRemark}</TextBold> </Grid>
              <Grid item xs={6}> <TextBold align={'right'}>{trn.amount} ₴</TextBold> </Grid>
            </Grid>
            <Grid item xs={12}> <TextSmallSize>{trn.terminalName}</TextSmallSize> </Grid>
            <Grid item xs={12} mt={1}>
              <TextMediumSize>{moment(trn.terminalDate).format('DD.MM.YYYY HH:mm')}</TextMediumSize> </Grid>

          </Grid>

          <AccordionWrapper>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <TextMediumSize sx={{ width: '70%' }}> Транзакція: № {trn.transactionId}</TextMediumSize>
              <TextMediumSize sx={{
                color: 'text.secondary',
                width: '30%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }} align={'right'}>Детально</TextMediumSize>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: 0 }}>
              <Grid container>
                <Grid item xs={12} display={'flex'}>
                  <Grid item xs={7}>
                    <TextSmallSize
                      align={'left'}>{t('module.reports.titlePurse')}</TextSmallSize>
                  </Grid>
                  <Grid item xs={2}>
                    <TextSmallSize
                      align={'center'}>{t('module.reports.titleQty')}</TextSmallSize></Grid>
                  <Grid item xs={3}>
                    <TextSmallSize
                      align={'right'}>{t('module.reports.titlePrice')}</TextSmallSize></Grid>
                </Grid>

                {
                  trn.products.map((product: ReportProducts) => (
                    <Grid item xs={12} display={'flex'}>
                      <Grid item xs={7}>
                        <TextMediumSize align={'left'}>{product.name}</TextMediumSize>
                      </Grid>
                      <Grid item xs={2}>
                        <TextMediumSize align={'center'}>{product.quantity}</TextMediumSize></Grid>
                      <Grid item xs={3}>
                        <TextMediumSize
                          align={'right'}>{(product.price).toFixed(2)} ₴</TextMediumSize></Grid>
                    </Grid>
                  ))
                }

              </Grid>
            </AccordionDetails>
          </AccordionWrapper>
        </Wrapper>))}
    </Grid>
  </>;
}
