import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LinkRegister = styled(Typography)(({ theme }) => ({
  margin: '.5rem',
  fontSize: '12px',
  '&:hover ': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));
