import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { styled } from '@mui/material/styles';
import { Box, IconButton, Typography } from '@mui/material';
import { IRow } from '../../Rows/Rows';
import { ISingleRow } from '../../../interface/interface.custom';
import { useModal } from '../../../../../../hooks';
import { ModalButton } from '../../../../../../config';


const IconButtonCustom = styled(IconButton)(() => ({
  padding: 0,
}));

interface IDeleteRow {
  labelConfirm: string;
  row: IRow;

  onConfirmDelete?({ row }: ISingleRow): Promise<boolean>;
}

export default function DeleteRow({ labelConfirm, row, onConfirmDelete }: IDeleteRow) {
  const { showModal } = useModal();

  const handleDeleteRow = () => {
    onConfirmDelete && onConfirmDelete({ row }).then(res => {
      if (res) {
        //handleCloseDialog();
      }
    });
  };

  const handleConfirmationDialog = () => {
    showModal({
      buttonType: ModalButton.OkCancel,
      children: (
        <Box sx={{ width: '300px' }}>
          <Typography align={'justify'}>{labelConfirm}</Typography>
        </Box>
      ),
      callbackOk() {
        handleDeleteRow();
      },
    });
  };

  return <>
    <IconButtonCustom onClick={handleConfirmationDialog}>
      <DeleteForeverIcon />
    </IconButtonCustom>
  </>;
}
