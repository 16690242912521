import { IColumn } from '../../../../ui/custom-table/components/Columns/Columns';
import i18next from 'i18next';

export const getColumns = (check: boolean): IColumn[] => {

  const checkAccess: IColumn | null =
    check
      ? {
        field: 'disabled',
        label: '',
        formatHeader: 'default',
        paddingHeader: 0,
        cell: { format: 'check-card-limit', tooltip: i18next.t('module.transfer.tooltipWrongAccount') },
        align: 'right',
        alignData: 'right',
        width: 5,
      } : null;

  const columns: IColumn[] = [

    {
      field: 'cardId',
      label: i18next.t('module.cards.titleID'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'left',
    },
    {
      field: 'fio',
      label: i18next.t('module.common.title.titleFIO'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'left',
    },
    {
      field: 'mobile',
      label: i18next.t('module.cards.titlePhone'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'left',
    },
    {
      field: 'accountStr',
      label: i18next.t('module.setting.users.titleAccountId'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'left',
    },
  ];

  if (checkAccess) {
    columns.splice(0, 0, checkAccess as any);
  }

  return columns;
};
