import { apiSlice } from '../api/api.slice';
import { ConfigReducerProps } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';
import { saveConfig } from '../../store';


export const configService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getConfig: builder.query<ConfigReducerProps, any>({
      async queryFn({}, { dispatch }, extraOptions, fetchWithBQ) {
        try {

          const result = await fetchWithBQ({
            url: `/common/modules`,
          });

          /* Записуем конфиг */
          result.data && dispatch(saveConfig(result?.data as ConfigReducerProps));

          return { data: result.data as ConfigReducerProps };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),
  }),
});
