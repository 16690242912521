import React, { memo } from 'react';
import { Typography } from '@mui/material';
import { CheckboxAll } from './Format';
import { IColumn } from '../Columns/Columns';

interface IHeaders {
  column: IColumn;
}

export const Headers = memo(({ column }: IHeaders) => {
  const { header: formatHeader, label } = column;
  const header = formatHeader || {};
  const { format } = header;

  switch (format) {
    case 'font-size' : {
      const { fontSize }: any = header;
      return fontSize
        ? <Typography sx={{ fontSize }}>{label}</Typography>
        : <>Not fontSize</>;
    }

    case 'checked': {
      const { onChange, arrayChecked = [] } = header;
      return (onChange)
        ? <CheckboxAll onChange={onChange} arrayChecked={arrayChecked}/>
        : <>You have no callback for this function</>;
    }

    default: {
      return <>{label}</>;
    }
  }

});
