import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { ContainerRow, FilterTable } from '../../ui';
import { HolderGroupTitle, InfoBlock, InnerContainer } from '../../common';
import { Box, Button, Grid } from '@mui/material';
import OutlineBox from '../../ui/outline-box/OutlineBox';
import { useTranslation } from 'react-i18next';
import { accountsMutation, accountsService } from '../../../services';
import { useActiveHolder, useAppDispatch, useCheckPermission, useMediaQuery, useModal } from '../../../hooks';
import { IFilter, IOrderBy, ISingleRow } from '../../ui/custom-table/interface/interface.custom';
import { filterApply } from './filter/filter';
import { IFilterAccountsField, initialStateFilterAccount } from './filter/interface.filter';
import { COUNT_PER_ON_PAGE, ModalButton } from '../../../config';
import { getColumns } from './accounts.columns';
import FilterContext from './filter/context.account';
import CustomTable from '../../ui/custom-table';
import { GetOrderVirtualCard } from '../Cards/CardList/components';
import { EditAccount } from './components';


const accounts = {
  accounts: [],
  sumTotal: {
    totalBalance: 0,
    totalBalanceWithoutOverdraft: 0,
    totalOverdraft: 0,
  },
};

/* Кол-во строк в таблице счетов, на странице инфо клиента */
const countPerOnPage: number = COUNT_PER_ON_PAGE ? +COUNT_PER_ON_PAGE : 5;

export const Account = memo(() => {
  const { t } = useTranslation();

  const { activeHolderId, activeHolder } = useActiveHolder();
  const {
    data = accounts,
    isLoading: isLoadingClient,
  } = accountsService.useGetAccountsByHolderIdQuery({ holderId: activeHolderId });
  const matches = useMediaQuery();

  const dispatch = useAppDispatch();

  useEffect(() => { setPage(0); }, [activeHolderId]);

  /* Текущая страница счетов */
  const [pageNum, setPage] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<IOrderBy>({ field: '', order: 'asc' });
  const handleChangePage = ({ page, orderBy }: IFilter) => {
    setOrderBy(orderBy);
    setPage(page - 1);
  };

  /* Фильтр таблицы */
  const [filter, setFilterState] = useState<IFilterAccountsField>(initialStateFilterAccount);
  const [isFilterApply, setApplyFilter] = useState<boolean>(false);

  /* изменение фильтра */
  const handleChangeFilter = (newFilter: IFilterAccountsField) => {
    setFilterState(newFilter);
    setPage(0);
  };

  const handleClearFilter = () => {
    setFilterState(initialStateFilterAccount);
    handleCommitFilter();
    setPage(0);
  };
  /* Применение фильтра */
  const handleCommitFilter = useCallback(() => {
    setApplyFilter(prev => !prev);
    setPage(0);
  }, [filter]);

  const [setAccountNewStatus] = accountsMutation.useSetAccountNewStatusMutation();

  const handleChangeAccountStatus = async ({ row }: ISingleRow): Promise<boolean> => {
    await setAccountNewStatus({ accountId: Number(row.id), newStatus: row.active });
    return true;
  };

  const { checkPermission } = useCheckPermission();
  /* Заполняем табличные заголовки */
  const columns = useMemo(() => {
    return getColumns({ onToggleSwitch: handleChangeAccountStatus, checkPermission });
  }, [t, handleChangeAccountStatus, checkPermission]);

  /* Фильтр по столбцам таблицы  */
  const rows = useMemo(() => {
    let count = 0;

    /* Применение фильтра */
    let filteredArr = filterApply({ accounts: data.accounts, filter, orderBy });
    count = filteredArr.length;

    /* Обрезаем массив до необходимых размеров отображения на странице */
    const startRow = pageNum === 0 ? 0 : (pageNum * countPerOnPage);
    const stopRow = (pageNum + 1) * countPerOnPage;

    /* Обрезаем по строкам на странице после фильтра */
    filteredArr = filteredArr.slice(startRow, stopRow);

    return { arr: filteredArr, count };
  }, [pageNum, isFilterApply, orderBy, filter, data]);

  const { showModal, closeModal } = useModal();

  const handleCreateAccount = () => {
    showModal({
      title: t('module.accounts.createAccountTitle'),
      buttonType: ModalButton.Empty,
      children: <EditAccount onClose={closeModal} accountId={-1} />,
    });
  };

  return (
    <FilterContext.Provider value={{ filter }}>
      <InnerContainer show={true}>
        <ContainerRow>
          <Grid item xs={12} md={7} style={{ flexDirection: 'column', padding: '1rem' }}>
            <OutlineBox style={{
              padding: matches ? 0 : '.35rem',
              marginTop: matches ? '1rem' : 0,
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: matches ? 'column' : 'row',
            }}>
              <>
                <Grid item xs={12} md={4}>
                  <InfoBlock
                    title={t('module.clientList.titleStatus')}
                    value={t(`statusClient.${activeHolder?.status}`)}
                    isLoading={activeHolderId === undefined || isLoadingClient}
                    sx={{ textAlign: 'center' }}
                  />
                </Grid>
                <Grid item md={4} xs={12} sx={{
                  borderLeft: matches ? 'none' : '1px solid #ccc',
                  borderRight: matches ? 'none' : '1px solid #ccc',
                  borderTop: matches ? '1px solid #ccc' : 'none',
                  borderBottom: matches ? '1px solid #ccc' : 'none',
                  width: '100%',
                }}>
                  <InfoBlock
                    title={t('module.accounts.titleRest')}
                    value={data.sumTotal.totalBalance.toLocaleString()}
                    isLoading={activeHolderId === undefined || isLoadingClient}
                    sx={{ textAlign: 'center' }}
                  />
                </Grid>
                <Grid item md={4} xs={12} sx={{
                  borderRight: matches ? 'none' : '1px solid #ccc',
                  borderBottom: matches ? '1px solid #ccc' : 'none',
                  width: '100%',
                }}>
                  <InfoBlock
                    title={t('module.accounts.titleTotalBalanceWithoutHold')}
                    value={data.sumTotal.totalBalanceWithoutOverdraft.toLocaleString()}
                    isLoading={activeHolderId === undefined || isLoadingClient}
                    sx={{ textAlign: 'center' }}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <InfoBlock
                    title={t('module.accounts.titleOverdraft')}
                    value={data.sumTotal.totalOverdraft.toLocaleString()}
                    isLoading={activeHolderId === undefined || isLoadingClient}
                    sx={{ textAlign: 'center' }}
                  />
                </Grid>
              </>
            </OutlineBox>
          </Grid>
          <Grid item xs={12} md={10}
                flexWrap={matches ? 'wrap' : 'nowrap'}
                style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem', alignItems: 'center' }}>

            <Box sx={{ display: 'flex' }}>
              <HolderGroupTitle mt={1}
                                title={`${t('module.dataGroup.accounts')}`} />
              {/* Фильтр таблицы */}
              <FilterTable
                type={'accounts'}
                onFilterClear={handleClearFilter}
                onFilterChange={handleChangeFilter}
                onFilterCommit={handleCommitFilter}
              />
            </Box>

            { /*
              <Button variant={'contained'} sx={{
                width: matches ? '170px' : '200px',
                marginBottom: matches ? '.5rem' : '0',
                fontSize: matches ? '.7rem' : '.9rem',
              }} onClick={handleCreateAccount}>
                {t('module.accounts.createAccountBtnTitle')}
              </Button>
           */ }

          </Grid>

          <Grid item xs={12} md={10} style={{ flexDirection: 'column', padding: '1rem' }}>

            <CustomTable
              rows={rows.arr}
              columns={columns}
              isLoading={isLoadingClient}
              count={rows.count}
              labelEmptyRows={t('module.common.table.emptyRows')}
              rowsPerPageOptions={[countPerOnPage]}
              fnRefreshData={handleChangePage}
              holderId={activeHolderId}
            />

          </Grid>
        </ContainerRow>
      </InnerContainer>
    </FilterContext.Provider>
  );
});

