import { styled } from '@mui/material/styles';
import { FormControl } from '@mui/material';
import { FormControlProps } from '@mui/material/FormControl/FormControl';

export const FormControlMini = styled((props: FormControlProps) =>
  (<FormControl {...props} size={'small'} />))(() => ({
  margin: 0,
  minWidth: 120,
  '& .MuiSelect-select': {
    padding: '.09rem 0 0 .7rem',
  },
  '& .MuiFormLabel-root': {
    lineHeight: '1rem',
  },
}));
