import { TextFieldProps } from '@mui/material/TextField';
import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';

type IInputCheckbox = TextFieldProps & {
  label: string;
}

export default function InputCheckbox({ label = 'title', onChange, id, defaultChecked, ...props }: IInputCheckbox) {

  const [checked, setCheck] = useState<boolean>(false);

  useEffect(() => {
    if (defaultChecked !== undefined) setCheck(defaultChecked);
  }, [defaultChecked]);

  const handleChangeChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheck(e.currentTarget.checked);
    onChange && onChange(e);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return <FormControlLabel
    control={
      <Checkbox id={id} checked={checked} onChange={handleChangeChecked} color={'primary'} />
    }
    label={label}
    {...props}
  />;
}
