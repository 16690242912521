import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLayoutState } from './initial/inital-layout';
import { ConfigReducerProps, LayoutReducerState } from '../../interface';
import { delToken } from '../../utils';
import { apiSlice, setStorage } from '../../services';
import { Storage } from '../../config';
import { Roles } from '../../helpers';

export const logOutAsync = createAsyncThunk('layoutReducer/logOutAsync', (arg, { dispatch }) => {
  delToken();
  dispatch(layoutReducer.actions.logOut());
  dispatch(apiSlice.util.resetApiState());
  return true;
});

export const layoutReducer = createSlice({
  name: 'layoutReducer',
  initialState: initialLayoutState,
  reducers: {
    /* Признак что пользователь авторихован */
    setAuthorize(state: LayoutReducerState, action: PayloadAction<{ isAuth: boolean }>) {
      state.isAuth = action.payload.isAuth;
    },
    /* Отображаем постоянную ошибку вверху сайта*/
    setLoading(state: LayoutReducerState, action: PayloadAction<{ isLoading: boolean }>) {
      state.isLoading = action.payload.isLoading;
    },
    /* Отображаем постоянную ошибку вверху сайта*/
    setErrorMessage(state: LayoutReducerState, action: PayloadAction<{ errorMessage: string | undefined }>) {
      state.errorMessage = action.payload.errorMessage;
    },
    /* Сохраняем полученный конфиг */
    saveConfig(state: LayoutReducerState, action: PayloadAction<ConfigReducerProps>) {
      state.config = action.payload.config;
      state.profile = action.payload.profile;
      state.files = action.payload.files;
      state.links = action.payload.links;
      state.isLoading = true;
    },
    /* Устанавливаем активного холдера */
    setActiveHolderId(state: LayoutReducerState, action: PayloadAction<{ holderId: number }>) {
      state.activeHolderId = action.payload.holderId;
      setStorage(Storage.activeHolderId, action.payload.holderId);
    },
    saveActiveRole(state: LayoutReducerState, action: PayloadAction<{ role: Roles }>) {
      state.activeRole = action.payload.role;
    },
    logOut(state: LayoutReducerState) {
      state.isAuth = false;
    },

  },
  extraReducers: builder => { builder.addCase(logOutAsync.fulfilled, () => { }); },
});
