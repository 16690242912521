import { FC, memo, useMemo } from 'react';

import { Box, Grid } from '@mui/material';
import { Loading, ContainerColumn } from '../../ui';
import { InfoBlockProps } from '../../../interface';
import { useCustomStyles } from '../../../styles';


export const InfoBlock: FC<InfoBlockProps> = memo(({ title, value, isLoading = false, sx = {} }) => {
  const classes = useCustomStyles();

  const renderValue = useMemo(() => {
    return isLoading
      ? <Loading type={'small'} />
      : ['', null].indexOf(value) !== -1
        ? '-'
        : value;
  }, [isLoading, value]);

  return (
    <Box sx={{ ...sx }}>
      <ContainerColumn>
        <Grid className={classes.textDefault}>{title}</Grid>
        <Grid className={classes.titleDefault} sx={{ paddingTop: '.3rem', wordBreak: 'break-all' }}>{renderValue}</Grid>
      </ContainerColumn>
    </Box>
  );
});
