import { getCardLimits, getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { CardLimitsServiceProps, LimitsServiceProps, PursesServiceProps } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';
import { pursesListService } from './purse.list.service';
import { limitListService } from './limit.list.service';


export const limitsCardService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getCardLimits: builder.query<CardLimitsServiceProps, { cardId: string }>({
      async queryFn({ cardId }, { dispatch }, extraOptions, fetchWithBQ) {
        try {

          const result = await fetchWithBQ({
            url: `/cards/${cardId}/limits`,
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          const purses = await dispatch(pursesListService.endpoints.getPurses.initiate(''));
          const limits = await dispatch(limitListService.endpoints.getLimits.initiate(''));

          getCardLimits({
            data,
            dataPurses: purses.data as PursesServiceProps,
            dataLimits: limits.data as LimitsServiceProps,
          });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      providesTags: ['CardLimit']
    }),
  }),
});
