import React from 'react';
import { IOutlineBox } from '../../../interface';
import { OutlineContainer } from './style/OutlineContainer';
import { OutlineTitle } from './style/OutlineTitle';

export default function OutlineBox({ children, textPosition = 'right', label, sx = {}, style = {} }: IOutlineBox) {
  return (
    <OutlineContainer sx={{ ...sx }} style={{ ...style }}>
      {textPosition && <OutlineTitle textposition={textPosition}>{label}</OutlineTitle>}
      {children}
    </OutlineContainer>
  );
}
