import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { RequestReportTransactionProps, TransactionServiceProps } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';


export const reportService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getReportTransaction: builder.query<TransactionServiceProps, RequestReportTransactionProps>({
      async queryFn({
                      holderId, accountId, cardId, fromDate, toDate,
                      limit, page, operation_kind, with_cancelation,
                    }, {}, extraOptions, fetchWithBQ) {
        try {
          /* Формируем запрос */
          let req: any = { cardId, fromDate, toDate, limit, page, operation_kind, with_cancelation };

          /* Если выбран конкретный счет то включаем его тоже в запрос */
          if (accountId !== 0) req = { ...req, accountId };

          const result = await fetchWithBQ({
            url: `/common/transactions`,
            params: { lang: getLangIso(), ...req },
          });

          checkErrors<any>({ result, meta: result.meta });
          const data = result.data as TransactionServiceProps;

          data.transactions = data.transactions.map((trn) => {
            return { ...trn, sumWithDiscount: trn.amountWithDicsount };
          });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),

    getReportTransactionDriver: builder.query<TransactionServiceProps, RequestReportTransactionProps>({
      async queryFn({ cardId, fromDate, toDate, limit, page }, {}, extraOptions, fetchWithBQ) {
        try {
          /* Формируем запрос */
          const req: any = {  fromDate, toDate, limit, page };

          const result = await fetchWithBQ({
            url: `/users/cards/${cardId}/transactions`,
            params: { lang: getLangIso(), ...req },
          });

          checkErrors<any>({ result, meta: result.meta });
          const data = result.data as TransactionServiceProps;

          data.transactions = data.transactions.map((trn) => {
            return { ...trn, sumWithDiscount: trn.amountWithDicsount };
          });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),
  }),
});
