import React, { memo } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import SaveIcon from '@mui/icons-material/Save';
import { OutlineBox } from '../../../../../ui';

interface ButtonCommitProps {
  title: string;
  buttonType: 'webReport' | 'xlsReport';

  onCommit(): void;
}

export const ButtonCommit = memo(({ title, buttonType, onCommit }: ButtonCommitProps) => {

  return (
    <OutlineBox style={{
      padding: '2.1px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

    }} sx={{ '& p': { padding: 0 } }}
    >
      <IconButton color={'primary'} onClick={onCommit} sx={{ padding: '1' }}>
        <Tooltip title={title}>
          {
            buttonType === 'webReport'
              ? <AutorenewIcon fontSize={'large'} />
              : <SaveIcon fontSize={'large'} />
          }
        </Tooltip>
      </IconButton>
    </OutlineBox>
  );
});
