import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { KindServiceProps, ListXLSReportProps } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';


export const transactionKindService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getReportType: builder.query<KindServiceProps, any>({
      async queryFn({}, {}, extraOptions, fetchWithBQ) {
        try {
          /**
           *  Получаем типы операций
           */
          const result = await fetchWithBQ({
            url: `/common/transactions/kinds`,
            params: { lang: getLangIso() },
          });

          checkErrors<any>({ result, meta: result.meta });
          /**
           *  Получаем отчеты XLS
           */
          const resultXLS = await fetchWithBQ({
            url: `/common/reports`,
            params: { lang: getLangIso() },
          });

          checkErrors<any>({ result: resultXLS, meta: result.meta });

          const data = result.data as KindServiceProps;
          /* Помечаем что это веб отчеты по типа транзакций */
          data.kinds = data.kinds.map(kind => ({ ...kind, type: 'webReport' }));

          const dataXLS = resultXLS.data as ListXLSReportProps;

          /* Заполняем совместный список отчетов */
          dataXLS.reports.forEach(report => {
            data.kinds.push({ ...report, id: report.reportId, type: 'xlsReport' });
          });


          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),
  }),
});
