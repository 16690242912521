import { IColumn } from '../../../ui/custom-table/components/Columns/Columns';
import i18n from 'i18next';

export const getColumns = (): IColumn[] => {

  return [
    {
      field: 'collapse',
      label: '',
      formatHeader: 'empty',
      cell: { format: 'collapse', type: 'report-trn' },
      align: 'right',
      width: 10,
    },
    {
      field: 'terminalDate',
      label: i18n.t('module.reports.titleDate'),
      formatHeader: 'default',
      cell: { format: 'date-time' },
      align: 'center',
      alignData: 'center',
      width: 150,
    },
    {
      field: 'cardId',
      label: i18n.t('module.reports.titleCard'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      width: 150,
    },
    {
      field: 'terminalName',
      label: i18n.t('module.reports.titleNameAZS'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'left',

    },
    {
      field: 'purseRemark',
      label: i18n.t('module.reports.titlePurse'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'right',
      alignData: 'right',
      width: 200,
    },
    {
      field: 'quantity',
      label: i18n.t('module.reports.titleQty'),
      formatHeader: 'default',
      cell: { format: 'numericalDigit' },
      align: 'right',
      alignData: 'right',
      width: 100
    },
    {
      field: 'amount',
      label: i18n.t('module.reports.titleSum'),
      formatHeader: 'default',
      cell: { format: 'numericalDigit' },
      align: 'right',
      alignData: 'right',
      width: 100
    },
    {
      field: 'discount',
      label: i18n.t('module.reports.titleDiscount'),
      formatHeader: 'default',
      cell: { format: 'numericalDigit' },
      align: 'right',
      alignData: 'right',
      width: 100
    },
    {
      field: 'sumWithDiscount',
      label: i18n.t('module.reports.titleSumWithDiscount'),
      formatHeader: 'default',
      cell: { format: 'numericalDigit' },
      align: 'right',
      alignData: 'right',
      width: 100
    },
  ];
};
