export enum ButtonType {
  Add,
  Edit,
  Refresh,
  Back,
  Sync,
  Save,
  List,
  Filter,
  ClearFilter,
  Columns,
  Copy,
  Search

}
