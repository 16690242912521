import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import ua from './ua/translation';
import en from './en/translation';

i18next
  .use(initReactI18next)
  .init({
    debug: false,
    lng: 'ua',
    resources: {
      ua: {
        translation: ua
      },
      en: {
        translation: en
      },
    },
  } ).then();
