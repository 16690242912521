import { useAppDispatch } from './redux';
import { removeModal as removeModalReducer, showModal as showModalReducer } from '../store';
import { ModalButton } from '../config';

interface UseModalProps {
  title?: string;
  children: any;
  buttonType: ModalButton;
  labelOk?: string;
  labelCancel?: string;

  callbackOk?(): void;

  callbackCancel?(): void;
}

export const useModal = () => {

  const dispatch = useAppDispatch();

  const closeModal = () => {
    dispatch(removeModalReducer());
  };

  const showModal = (props: UseModalProps) => {
    const { title, callbackOk, callbackCancel, children, buttonType = ModalButton.OkCancel, labelOk, labelCancel } = props;

    dispatch(showModalReducer({
      ...props,
      labelOk, labelCancel,
      buttonType,
      callbackCancel: () => {
        callbackCancel && callbackCancel();
        closeModal();
      },
      callbackOk: () => {
        callbackOk && callbackOk();
        closeModal();
      },
    }));
  };


  return {
    showModal, closeModal,
  };

};
