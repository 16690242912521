import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { transformCatchError } from '../api/errors/transform-catch-error';


export const accountsMutation = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    setAccountNewStatus: builder.mutation<void, { accountId: number, newStatus: boolean }>({
      async queryFn({ accountId, newStatus }, {}, extraOptions, fetchWithBQ) {
        try {

          const result = await fetchWithBQ({
            url: `/accounts/${accountId}/status?active=${newStatus}`,
            method: 'POST',
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      invalidatesTags: ['Accounts']
    }),
  }),
});
