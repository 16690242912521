const translation = {
  title: 'SmartCards CS',
  config: {
    locale: {
      auth: {
        enterCRM: 'Enter CRM',
      },
    },
  },
  modal: {
    confirmExit: 'Do you really want to exit the system?',
    labelOk: 'Yes',
    labelCancel: 'Cancel',
    labelAdd: 'Add',
    labelEdit: 'Edit',
    labelApply: 'Apply',
  },
  menu: {
    profile: {
      title: 'Company',
    },
    account: {
      title: 'Accounts',
    },
    cards: {
      title: 'Cards',
    },
    reports: {
      title: 'Reports',
    },
    documents: {
      title: 'Documents',
    },
    exchange: {
      title: 'Funds Transfer',
    },
    myCard: {
      title: 'My Card',
    },
    helpers: {
      title: 'Instructions',
    },
    setting: {
      title: 'Settings',
    },
    users: {
      title: 'Users',
    },
  },
  module: {
    common: {
      table: {
        num: '#',
        active: 'Status',
        filter: 'Filter',
        clearFilter: 'Clear filter',
        emptyRows: 'No rows',
        setting: 'Settings',
      },
      filter: {
        title: 'Filter',
        titleSubmit: 'Apply',
        titleDate: 'Date',
        titleStatus: 'Status',
        titleIsActive: 'Active',
        titleInactive: 'Inactive',
        titleIsBlock: 'Blocked',
        titleBalance: 'Balance',
        titleNumCard: 'Card number',
        titlePhone: 'Phone',
        titleVIN: 'Car',
        titleFirstName: 'First name',
        titleLastName: 'Last name',
        titleFrom: 'From',
        titleTo: 'To',
        titleAccountName: 'Name',
        titleSelectAll: 'All',
        titleNotSelected: 'Not selected',
        titleNotAccess: 'Access denied',
        rowNotSelected: 'No rows selected',
        titleHideColumns: 'Hide columns',
      },
      title: {
        titleOnlyCurrentHolder: 'Only current company',
        backToClients: 'Back to client list',
        backToCards: 'Back to card list',
        backToAccounts: 'Back to account list',
        titleClientComboBox: 'Client',
        titleAccountComboBox: 'Account',
        titleCards: 'Cards',
        titleDataNotFound: 'Data not found',
        titleNotInitialized: 'Not defined',
        titleOk: 'Yes',
        titleCancel: 'Cancel',
        titlePeriod: 'Period',
        titleDocumentation: 'Documentation',
        titleLinks: 'Links',
        titleSearchStr: 'Search string',
        titleSelected: 'Selected',
        titleNext: 'Next',
        titleBack: 'Back',
        titleCommit: 'Commit',
        titleSum: 'Sum',
        titleOr: 'Or',
        titleCreate: 'Create',
        titleDelete: 'Delete',
        titleDeletePhone: 'Delete number?',
        titleOf: 'of',
        titleExecute: 'Display',
        titleSubmit: 'Save',
        titleReportTooltip: 'Open calendar',
        titleReportLabelCancel: 'Close',
        titleReportLabelInput: 'Period',
        titleReportLabelBtn: 'Choose',
        dateFrom: 'From',
        dateTo: 'To',
      },
      modal: {
        type: {
          info: 'Information',
          success: 'Success',
          warning: 'Warning',
          error: 'Error',
          confirmation: 'Confirmation',
          default: '',
        },
        labelConfirmation: 'Do you really want to delete the row?',
        labelConfirmationDeleteUser: 'Do you really want to delete the user?',
        labelConfirmationDeleteUserCurrentHolder: 'Do you really want to revoke access from the user for the current company?',
      },
    },
    register: {
      registerCRM: 'Registration',
      updateCRM: 'Change password',
      welcomeTextPhone: 'Please enter your phone number',
      welcomeTextOTP: 'Enter the code sent to you via SMS to the specified phone number',
      titlePhone: 'Phone',
      titleOTP: 'SMS Code',
      titleForPass: 'Set your new password for the account',
      btnRegisterPhone: 'Send SMS code',
      btnRegisterOTP: 'Register',
      btnUpdatePassOTP: 'Update data',
      titleEnterPass: 'Enter password',
      titleEnterRePass: 'Confirm password',
      titleRegisterDriver: 'Your phone number "{{urlParam.phone}}" will be linked to the card "{{urlParam.cardId}}". Confirm this action?',
      titleRegisterDriverSuccess: 'Phone number "{{urlParam.phone}}" successfully linked to card "{{urlParam.cardId}}". Next, you need to register.',
    },
    login: {
      enterCRM: 'Enter CRM',
      welcomeText: 'To log in to your personal account, enter your phone number and password or register',
      titleLogin: 'Phone',
      titlePassword: 'Password',
      btnRegister: 'Register',
      btnForgotPass: 'Forgot password',
      btnEnter: 'Enter',
      btnResendOTP: 'Resend SMS with new code',
      btnLogout: 'Logout',
    },
    clientList: {
      placeholderSearchString: 'Search by name or EDRPOU',
      titleAccounts: 'Accounts',
      titlePage: 'Clients',
      titleID: 'ID',
      titleNameClient: 'Client name',
      titleOKPO: 'EDRPOU',
      titleINN: 'TIN',
      titlePhone: 'Phone',
      titleEmail: 'Email',
      titleAddress: 'Address',
      titleLegalAddress: 'Legal address',
      titleNumContract: 'Contract number',
      titleStatus: 'Client status',
    },
    holders: {
      btnSelectHolder: 'Select',
    },
    accounts: {
      titleID: 'ID',
      titleRemark: 'Description',
      titleRest: 'Balance',
      titleOverdraft: 'Overdraft',
      titleTotalBalance: 'Available',
      titleTotalBalanceHint: 'Account balance',
      titlePurses: 'Purses',
      titleTotalBalanceWithoutHold: 'Balance',
      titleTotalBalanceWithoutHoldHint: 'Account balance without holds',
      titleCards: 'Cards',
      btnAccountLink: 'Go to accounts',
      createAccountBtnTitle: 'Create an account',
      createAccountTitle: 'Create an account',
      editAccountTitle: 'Editing an account',
      editName: 'Account name'
    },
    cards: {
      titleID: 'Card',
      titleVin: 'Car',
      titlePhone: 'Phone',
      titleName: 'Last name, First name',
      titleActiveMobileApp: 'Driver\'s cabinet',
      titleSetting: 'Settings',
      titleConfirmRenewPin: 'Do you really want to change the current pin?',
      titleShowNewPin: 'New PIN',
      titleBtnSendOtp: 'Send code',
      titleBtnRetrySendOtp: 'Resend code',
      titleBtnWithoutConfirm: 'Save without confirmation',
      titleCodeFromSMS: 'SMS code',
      titleBtnCommit: 'Confirm number change',
      titlePermitByDayOfTheWeek: 'Permit by day of the week',
      tooltipNoLimit: 'No limit',
      tooltipLabelWarning: 'Warning! No limit set, the card will not be serviced.',
      orderCardBtn:'Order card',
      orderCardTitle: 'Issue a virtual card',
      limits: {
        title: 'Limits',
        titleBtnAddLimit: 'Add limit',
        titleMoneySchema: 'Money scheme',
        titleQtySchema: 'Volume scheme',
        titleShortMoneySchema: 'Money',
        titleShortQtySchema: 'Volume',
        titlePurses: 'Purses',
        titleSchemaType: 'Scheme',
        titleType: 'Type',
        titleMoneyByDay: 'UAH/Day',
        titleQtyByDay: 'Liters/Day',
        titleMoneyByMonth: 'UAH/Month',
        titleQtyByMonth: 'Liters/Month',
        titleMoneyByOnce: 'UAH/Once',
        titleQtyByOnce: 'Liters/Once',
        titleMoneyByWeek: 'UAH/Week',
        titleQtyByWeek: 'Liters/Week',
        titleMoneyOutOfTime: 'UAH until deduction',
        titleQtyOutOfTime: 'Liters until deduction',
        titleBalance: 'Turnover',
        titleLimitAmount: 'Limit',
        titleRest: 'Balance',
        copyLimit: 'Copy Limits',
        newLimit: {
          title: 'Account',
          titleIndividual: 'Individual',
          titleCommon: 'Common',
          titleTypeLimit: 'Limit type',
          titleLimitAmount: 'Amount',
        },
      },
      qr: {
        notExistCard: 'Card does not exist.\n To add a card, link your phone number to the card',
        notMatchResolution: 'Does not match screen resolution, please turn your phone',
        contextMenu: {
          blocked: 'Blocking',
          confirmBlockedCard: 'Do you really want to block the card?',
          limits: 'View limits',
        },
        status: {
          '0': 'Blocked',
          '1': 'Active',
        },
      },
    },
    transfer: {
      titleAccountSource: 'From',
      titleAccountSourceHelper: 'Select source',
      titleAccountDestination: 'To',
      titleAccountDestinationHelper: 'Select destination',
      titleTransferButton: 'Perform transfer',
      confirmTransfer: 'Do you really want to perform this transfer?',
      defaultMoney: 'UAH',
      labelMoneyBeforeChooseAccount: 'Choose account',
      labelMoney: 'Money kind',
      labelRestOfMoneyBeforeChooseAccount: 'Choose wallet',
      labelRestOfMoney: 'Available: {{sum}}',
      sourceAccountName: 'Account source',
      destinationAccountName: 'Account destination',
      notEnoughMoney: 'Not enough money',
    },
    reports: {
      titleTrnKind: 'Type of report',
      titleDate: 'Date',
      titleCard: 'Card',
      titleNameAZS: 'Gas Station',
      titleAddress: 'Gas Station Address',
      titlePurse: 'Purse',
      titleQty: 'Qty',
      titlePrice: 'Price',
      titleSum: 'Sum',
      titleDiscount: 'Discount',
      titleSumWithDiscount: 'Total',
      titleName: 'Name',
      titleUseFromDate: 'From date',
      titleUseToDate: 'To date',
      titleSubmitXLS: 'Save EXCEL',
      tittleFilterClear: 'Clear',
      titleAll: 'All',
      titleLastTrn: 'Last transactions',
      titleFilterPurses: 'Purses',
    },
    documents: {
      billing: {
        titleTab: 'Invoices',
        fileName: 'Invoice_from_%1%',
        typeFuel: 'Payment for petroleum products',
        typeGoods: 'Payment for goods',
        titleBtnCreateInvoice: 'Create invoice',
        titleLoadingFile: 'Generating invoice...',
        titleLoadingFileError: 'Error displaying preview...',
        titleBtnSaveXLSX: 'Save XLSX',
        titleBtnSavePDF: 'Save PDF',
        titleTypeGoods: 'Payment for goods and services',
        shortTitleTypeGoods: 'goods',
        titleTypeFuel: 'Payment for petroleum products',
        shortTitleTypeFuel: 'PP',
      },
      certificates: {
        titleTab: 'Expense documents',
        table: {
          type: 'Document type',
          number: 'Document number',
          qty: 'Quantity',
          sumWithPDV: 'Amount, UAH with VAT',
          status: 'Status',
        },
      },
    },
    setting: {
      titleChangePass: 'Change password',
      titleChangeProfile: 'Profile',
      titleBtnChangeProfile: 'Change data',
      titleAddNewUser: 'Add new user',
      users: {
        titleAccountId: 'ID',
        titleAccountRemark: 'Description',
        titleFio: 'Last name, first name',
        titleFirstName: 'First name',
        titleLastName: 'Last name',
        titlePhone: 'Phone',
        titleEmail: 'Email',
        titleApiRole: 'Role',
        titleActive: 'Activity',
        titleHoldersLength: 'Number of clients',
        titleHolderDefaultShort: 'Main company',
        titleHolderDefault: 'User\'s main company',
        titleUserEdit: 'Profile change permission',
        confirmChangeAdminRole: 'Attention! Changing access operation is irreversible and may result in its loss. Are you sure?',
      },
      api: {
        titleApi: 'To access the API, use the generated API key:',
      },
      user: {
        limitAccounts: 'Account limits',
        limitCards: 'Card limits',
      },
    },
    dataGroup: {
      infoClient: 'Client information',
      accounts: 'Accounts',
      analytics: 'Analytics',
      limits: 'Limit balances',
      addLimits: 'Add limit',
      additionalInfo: 'Additional information',
      analyticInfo: 'Analytics',
    },
  },
  roles: {
    SmartCardsAdmin: 'Administrator',
    SmartCardsManager: 'Manager',
    SmartCardsViewer: 'View',
    SmartCardsReporter: 'Reports',
    SmartCardsDriver: 'Driver',
  },
  statusClient: {
    '0': 'Active',
    '1': 'Blocked',
    '2': 'Deleted',
    '3': 'Moved',
  },
  statusCard: {
    '0': 'Active',
    '1': 'Blocked',
    '2': 'Archived',
  },

  error: {
    '1': 'Entered data is not a phone number',
    '2': 'Phone number not found',
    '3': 'Incorrect SMS code',
    '4': 'User was registered previously',
    '5': 'User not found',
    '6': 'Incorrect password or phone number (email)',
    '7': 'Access denied',
    '8': 'Incorrect parameters',
    '9': 'Error creating limit',
    '10': 'Failed to set new PIN',
    '11': 'User has been deleted, please contact the administrator',
    '12': 'User has been deactivated, please contact the administrator',
    '18': 'Error updating limit',
    '101': 'Account does not belong to the client',
    '102': 'Insufficient funds',
    '401': 'Authorization error',
    '403': 'Access denied',
    '404': 'URL address not found',
    '1000': 'Unknown error',
    '1001': 'User not authorized',
    '1002': 'Operation performed successfully',
    '1003': 'Error occurred during execution',
    '1004': 'Incorrect phone number',
    '1005': 'Request error',
    '1006': 'Request not authorized',
    '1007': 'Filled field is of incorrect format',
    '1008': 'Account limits selected, but accounts are not selected',
    '1009': 'Card limits selected, but cards are not selected',
    '1100': 'Authorization data not provided',

    ERROR_GET_ACTIVE_HOLDER_ID: 'Error getting active client, you need to log out and log back into the system',
    SEND_OTP_SUCCESS: 'Code successfully sent to number {{phone}}',
    PASSWORD_CHANGED: 'Password changed',
    REGISTER_SUCCESSFULLY: 'User registered',
    TOO_MANY_REQUEST: 'Too many requests, please try again later',
    REQUIRED_FIELD: 'Required field',
    CONFIG_ERROR: 'Error getting configuration file',
    FETCH_ERROR: 'Network error',
    PARSING_ERROR: 'Error forming request',
    UNKNOWN_ERROR: 'Unknown error',
    ERROR_CHOICE_DATE: 'Incorrect date',
    SUCCESS: 'Successful execution',
  },
  validation: {
    ERROR_EMAIL: 'Incorrect email',
    ERROR_PHONE: 'Incorrect phone number',
    EMPTY_ONE_ACCOUNT: 'account',
    EMPTY_ONE_PURSES: 'purse',
    EMPTY_ONE_CARDS: 'card',
    ERROR_EMPTY_ONE_PARAM: 'Report parameters are missing ({{params}})',
    REQUIRED_FIELD: 'Required field',
  },
};

export default translation;
