import { memo } from 'react';

import { WrapperStyle } from './style/WrapperStyle';

import { LanguageItem } from './components/LaguageItem';
import { useTranslation } from 'react-i18next';
import { ChangeLanguageProps, LabelLang } from '../../../../../interface';
import { useConfig } from '../../../../../hooks';
//import { setStorage } from '@services';


export const ChangeLanguage = memo(({ sx }: ChangeLanguageProps) => {

  const { locale: { language } } = useConfig();
  const { i18n } = useTranslation();


  const handleChange = (lang: LabelLang) => {
    i18n.changeLanguage(lang.toLowerCase()).then(() => {
     // setStorage(Storage.language, lang.toLowerCase());
    });
  };

  return (
    <WrapperStyle sx={{ ...sx }}>
      {
        language.map((languageItem: any, idx: number) => (
          <LanguageItem
            key={languageItem.langISO}
            label={languageItem.label}
            currentLanguage={i18n.language as LabelLang}
            qty={language.length}
            idx={idx}
            onClick={handleChange}
          />
        ))
      }
    </WrapperStyle>
  );
});
