import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { InputFilled, InputCheckbox } from '../../input';
import React, { useEffect, useState } from 'react';
import { useFilterAccountContext } from '../../../module/Account/filter/context.account';
import { IFilterAccountsField, initialStateFilterAccount } from '../../../module/Account/filter/interface.filter';
import { useTranslation } from 'react-i18next';
import { TitleBlock } from '../..';


const Title = styled(Typography)(() => ({
  fontSize: '2rem',
  fontWeight: 600,
  textAlign: 'center',
}));

const FilterInput = styled(InputFilled)((props) => {
  const { property } = props;
  const style = property === 'fullWidth'
    ? { maxWidth: 'initial', marginTop: '2rem' }
    : { maxWidth: '150px', marginTop: '0 !important' };

  return ({
    ...style,
    marginRight: property === 'left' ? '.5rem' : '0',
    marginLeft: property === 'right' ? '.5rem' : '0',
  });
});


interface IFilterAccounts {
  onFilterChange(filter: IFilterAccountsField | any): void;
}

export default function FilterAccounts({ onFilterChange }: IFilterAccounts) {
  const { t } = useTranslation();
  /* Получаем фильтры из Аккаунтов */
  const { filter } = useFilterAccountContext();

  const [formFilter, setFilter] = useState<IFilterAccountsField>(initialStateFilterAccount);

  useEffect(() => {
    filter && setFilter(prev => ({ ...prev, ...filter }));
  }, [filter]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, checked } = e.currentTarget;
    let newFilter = formFilter;
    switch (id) {
      case 'isActive':
      case 'isBlock': {
        newFilter = { ...newFilter, active: { ...newFilter.active, [id]: checked } };
        break;
      }
      case 'name': {
        newFilter = { ...newFilter, name: value };
        break;
      }
      /* balance */
      default : {
        newFilter = { ...newFilter, balance: { ...newFilter?.balance, [id]: +value } };
        break;
      }
    }

    setFilter(newFilter);
    onFilterChange(newFilter);
  };

  return <Box sx={{ minWidth: '300px' }}>
    <Title>{t('module.common.filter.title')}</Title>
    <TitleBlock title={t('module.common.filter.titleStatus')}>
      <Box display={'flex'} flexDirection={'column'}>
        <InputCheckbox label={t('module.common.filter.titleIsActive')} id={'isActive'} onChange={handleChange}
                       defaultChecked={formFilter.active.isActive} />
        <InputCheckbox label={t('module.common.filter.titleIsBlock')} id={'isBlock'} onChange={handleChange}
                       defaultChecked={formFilter.active.isBlock} />
      </Box>

    </TitleBlock>
    <TitleBlock title={t('module.common.filter.titleBalance')} sx={{ marginTop: '1.5rem' }}>
      <Box display={'flex'} flexDirection={'column'}>
        <Box display={'flex'}>
          <FilterInput id={'from'} onChange={handleChange} label={t('module.common.filter.titleFrom')} property={'left'}
                       value={formFilter.balance.from} type={'number'} />
          <FilterInput id={'to'} onChange={handleChange} label={t('module.common.filter.titleTo')} property={'right'}
                       value={formFilter.balance.to} type={'number'} />
        </Box>
        <FilterInput id={'name'} onChange={handleChange} label={t('module.common.filter.titleAccountName')}
                     property={'fullWidth'}
                     value={formFilter.name} />
      </Box>
    </TitleBlock>
  </Box>;
}
