import { IOrderBy } from '../../../../ui/custom-table/interface/interface.custom';
import { IFilterCardsField } from './interface.filter';
import { ActiveAccountsProps, ActiveType, CardsProps } from '../../../../../interface';
import { fieldSort } from '../../../../../utils';

interface IFilterApply {
  /**
   * Искомій массив
   */
  cards: CardsProps[];
  /**
   * поля фильтра
   */
  filter: IFilterCardsField;
  /**
   * Сортировка по полю и метод (asc, desc)
   */
  orderBy: IOrderBy;
}

export const filterApply = ({ cards, filter, orderBy }: IFilterApply): CardsProps[] => {
  let filteredArr = cards;
  /*Accounts*/
  const { active, firstName, lastName, vin, mobile, cardId } = filter;

  const filterActive = filterIsActiveAccount(active);

  filteredArr = filteredArr.filter(arr => {
    let checkActive = true;

    /* Фильтр активности выбран */
    if (filterActive !== undefined) {
      const active = arr.status === 0;
      checkActive = active === filterActive;
    }

    /* Если поисковая строки заполнены */
    const checkCard = ((cardId !== '' && arr.cardId.includes(cardId)) || cardId === '');
    const checkFirstName = ((firstName !== '' && arr.firstName.toLocaleUpperCase().includes(firstName.toLocaleUpperCase())) || firstName === '');
    const checkLastName = ((lastName !== '' && arr.lastName.toLocaleUpperCase().includes(lastName.toLocaleUpperCase())) || lastName === '');
    const checkMobile = ((mobile !== '' && arr.mobile.includes(mobile)) || mobile === '');
    const checkVIN = ((vin !== '' && arr.vin.toLocaleUpperCase().includes(vin.toLocaleUpperCase())) || vin === '');


    return checkActive && checkCard && checkVIN && checkFirstName && checkLastName && checkMobile;

  });

  /* Сортируем если это необходимо */
  if (orderBy.field !== '') {

    filteredArr = fieldSort({ array: filteredArr, orderBy });

  }

  return filteredArr;

};

const filterIsActiveAccount = (active: ActiveAccountsProps): ActiveType => {
  const { isActive, isBlock } = active;
  let res: ActiveType = undefined;

  if (isActive && !isBlock) res = true;
  if (!isActive && isBlock) res = false;

  return res;
};
