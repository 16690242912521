import TableCell from '@mui/material/TableCell';
import { IOrderBy, ISingleRow, TAlign, TFormatColumn, TFormatHeader, TTypeCollapsibleColumn } from '../../interface/interface.custom';
import FormatColumn from '../Cell/FormatColumn';
import { styled } from '@mui/material/styles';

import { TableSortLabel, Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { useCustomStyles } from '../../../../../styles';
import { Headers } from '../Headers';

//import { ResizableCell } from '../Cell';

export interface IColumn {
  field: string;
  width?: number;
  align?: TAlign;
  alignData?: TAlign;
  formatHeader?: TFormatColumn;
  header?: {
    format?: TFormatHeader;
    [key: string]: any;
  };
  paddingHeader?: number;
  formatProps?: any;
  cell: {
    format: TFormatColumn; type?: TTypeCollapsibleColumn; /**
     *  Если тип "switch" существует колбэк переключателя
     */
    onToggleSwitch?({ row }: ISingleRow): Promise<boolean>; /**
     * Если это "text-edit" существует колбэк изменения текстовой строки
     */
    onChangeField?({ row }: ISingleRow): Promise<boolean>; /**
     *  Если тип 'delete' нажатие на удалить
     */
    onClickDelete?({ row }: ISingleRow): Promise<boolean>; [key: string]: any; /**
     * Если нажали скачать файл
     */
    onDownloadFile?({ row }: ISingleRow): any;
  };
  label: string;
  hint?: string;
  sortable?: boolean;
}

interface IColumns {
  /**
   * Заголовки колонок
   */
  columns: IColumn[];
  /**
   * Сортировка
   */
  orderBy: IOrderBy;

  /**
   * Все данные столбца
   */
  rows?: any;

  /**
   * Колбэк смены поля сортировки
   */
  onChangeOrderBy?(orderBy: IOrderBy): void;
}

const TableCellBorder = styled(TableCell)(() => ({
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    top: '30%',
    bottom: '30%',
    right: 0,
    height: '40%',
    borderRight: '2px solid rgb(224, 224, 224)',
  },

}));

export default function Columns({ columns, orderBy, onChangeOrderBy, rows }: IColumns) {
  const classes = useCustomStyles();

  return useMemo(() => {

    const handleRequestSort = (field: string) => {
      if (onChangeOrderBy) {
        onChangeOrderBy({
          field, order: orderBy.order === 'asc' ? 'desc' : 'asc',
        });
      }
    };


    return <>
      {columns.map(column => {

        /* Отдельные стили колонок */
        const sx = column?.header?.sx ?? {};

        /*  Возвращаем поля по умолчанию */
        const response: JSX.Element = (
          /* Проверяем сортируется ли поле */
          column.sortable ?  /* Сортируется */
            <>
              <TableSortLabel
                sx={{ width: '100%' }}
                active={orderBy.field === column.field}
                direction={orderBy.order}
                onClick={handleRequestSort.bind(null, column.field)}
              >
                <Headers column={column} />
                { /* <FormatColumn column={column} /> */}
              </TableSortLabel>

            </> : /* Не сортируется просто  форматируем если надо */
            <Headers column={column} />

        );
        { /* <FormatColumn column={column} />
         const columnRows = rows.reduce((acc: any, item: any) => {
         return [...acc, item[column.field]];
         }, []);

         // если нужно что-то сделать с колонкой
         switch (column.formatHeader) {
         case 'text-edit-limits': {
         const matchLength = columnRows.filter((c: number) => c === -1);

         if (matchLength.length === columnRows.length) return response = <></>;

         break;
         }

         case 'custom': {
         response = column?.header && <Headers header={column?.header} /> || <>Error Header Format</>;
         break;
         }

         }
         */
        }

        return (
          <Tooltip
            key={column.field}
            title={column.hint ? column.hint : column.label}
            placement='top'
          >
            <TableCellBorder
              className={classes.titleDefault}
              sx={{
                '& .MuiButtonBase-root': { position: 'initial' },
                width: column.width,
                textAlign: column.align,
                cursor: 'pointer',
                ...sx,
              }}
            >

              {response}

            </TableCellBorder>
          </Tooltip>
        );

      })}
    </>;
  }, [classes.titleDefault, columns, onChangeOrderBy, orderBy.field, orderBy.order, rows]);
}
