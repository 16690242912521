import i18n from 'i18next';
import { IColumn } from "../../../../../ui/custom-table/components/Columns/Columns";


export const getColumns = (): IColumn[] => {
	return [
		{
			field : 'lastName',
			label:  i18n.t('module.setting.users.titleLastName' ),
			formatHeader: 'default',
			cell : {format: 'default'},
			align: 'center',
			alignData: 'center'
		},
		{
			field : 'firstName',
			label:  i18n.t('module.setting.users.titleFirstName' ),
			formatHeader: 'default',
			cell : {format: 'default'},
			align: 'center',
			alignData: 'center'
		},
		{
			field : 'cardId',
			label: i18n.t('module.cards.titleID' ),
			formatHeader: 'default',
			cell : {format: 'default'},
			align: 'center',
			alignData: 'center'
		},
		{
			field : 'mobile',
			label: i18n.t('module.cards.titlePhone' ),
			formatHeader: 'default',
			cell : {format: 'default'},
			align: 'center',
			alignData: 'center'
		}
	]
}
