import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useMemo } from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';



const LinkLabel = styled(Typography)((props) => ({
  fontSize: 'inherit', opacity: '.5', '&:hover': { opacity: '1', color: props.theme.palette.primary.main },
}));

const StyleNavLink = styled(NavLink)(() => ({
  textDecoration: 'none',
  color: 'inherit',
}));

interface ILinkIcon {
  link: string;
  icon: React.ReactNode;
}
export default function LinkIcon({ link, icon }: ILinkIcon) {
  return <StyleNavLink to={link}>
    {
      icon && <LinkLabel>{icon}</LinkLabel>
    }
  </StyleNavLink>;
}
