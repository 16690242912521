import { memo, useMemo } from 'react';
import { ButtonType } from './enums';
import { MIconButton } from './style';
import {
  AddCircle as AddCircleIcon, Edit as EditIcon,
  Refresh as RefreshIcon,
  Reply as BackIcon,
  CloudSyncOutlined as CloudSyncOutlinedIcon,
  Save as SaveIcon,
  PriorityHigh as PriorityHighIcon,
  Dvr as DvrIcon,
  FilterAlt as FilterAltIcon,
  FilterAltOff as FilterAltOffIcon,
  ViewColumn as ViewColumnIcon,
  FileCopy as FileCopyIcon,
  Search as SearchIcon
} from '@mui/icons-material';

import { Box, Tooltip } from '@mui/material';


interface IconButtonProps {
  type: ButtonType;
  tooltip?: string;
  disabled?: boolean;
  showAttention?: boolean;

  onClick(): void;
}

export const IconButton = memo(({ onClick, tooltip, disabled, showAttention = false, type }: IconButtonProps) => {

  const handleClick = () => {
    onClick();
  };

  const renderIconButton = useMemo(() => {
    switch (type) {
      case ButtonType.Refresh : {
        return (
          <MIconButton onClick={handleClick}>
            <RefreshIcon />
          </MIconButton>
        );
      }
      case ButtonType.Add : {
        return (
          <MIconButton onClick={handleClick}>
            <AddCircleIcon />
          </MIconButton>
        );
      }
      case ButtonType.Edit : {
        return (
          <MIconButton onClick={handleClick}>
            <EditIcon />
          </MIconButton>
        );
      }
      case ButtonType.Save : {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MIconButton onClick={handleClick} disabled={disabled}>
              <SaveIcon />
            </MIconButton>
            {
              showAttention && !disabled && <PriorityHighIcon sx={{ fontSize: '.7em', color: '#d32222' }} />
            }
          </Box>
        );
      }
      case ButtonType.Sync : {
        return (
          <MIconButton onClick={handleClick}>
            <CloudSyncOutlinedIcon />
          </MIconButton>
        );
      }

      case ButtonType.List : {
        return (
          <MIconButton onClick={handleClick}>
            <DvrIcon />
          </MIconButton>
        );
      }

      case ButtonType.Filter : {
        return (
          <MIconButton onClick={handleClick}>
            <FilterAltIcon />
          </MIconButton>
        );
      }

      case ButtonType.ClearFilter : {
        return (
          <MIconButton onClick={handleClick}>
            <FilterAltOffIcon />
          </MIconButton>
        );
      }

      case ButtonType.Columns : {
        return (
          <MIconButton onClick={handleClick}>
            <ViewColumnIcon />
          </MIconButton>
        );
      }

      case ButtonType.Copy : {
        return (
          <MIconButton onClick={handleClick}>
            <FileCopyIcon />
          </MIconButton>
        );
      }

      case ButtonType.Search : {
        return (
          <MIconButton onClick={handleClick} disabled={disabled}>
            <SearchIcon  />
          </MIconButton>
        );
      }

      case ButtonType.Back : {
        return (
          <MIconButton onClick={handleClick}>
            <BackIcon fontSize={'large'} />
          </MIconButton>
        );
      }


    }
  }, [type, tooltip, disabled, showAttention, onClick]);

  return (
    <Tooltip title={tooltip}>
      {renderIconButton}
    </Tooltip>
  );
});
