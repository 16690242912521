import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { CustomTable, InputSearchLine } from '../../../../ui';
import { cardListService } from '../../../../../services';
import { useTranslation } from 'react-i18next';
import { useActiveHolder } from '../../../../../hooks';
import { filterObject } from '../../../../../utils';
import { getColumns } from './card.columns';
import { IRow } from '../../../../ui/custom-table/components/Rows/Rows';
import { LoadingButton } from '@mui/lab';
import { IFilter } from '../../../../ui/custom-table/interface/interface.custom';

/* Кол-во строк в таблице */
const countPerOnPage: number = 15;

interface CardsProps {
  accountListDestination: any[];

  onSelect(accountId: number): void;

  onClose(): void;
}

export const Cards = memo(({ onClose, onSelect, accountListDestination }: CardsProps) => {
  const { t } = useTranslation();
  const { activeHolderId } = useActiveHolder();
  const [selectedAccountId, setAccountId] = useState(-1);

  const childRef = useRef<any>(null);

  useEffect(() => {
    if (childRef.current) childRef.current.focus();
  }, [childRef.current]);

  /* Строка поиска по всем полям таблицы */
  const [searchStr, setSearchStr] = useState('');

  const {
    data: dataCards = { cards: [] },
    isLoading,
  } = cardListService.useGetCardListQuery({ holderId: activeHolderId, accountId: 0 });

  /* Текущая страница счетов */
  const [pageNum, setPage] = useState<number>(0);
  const handleChangePage = ({ page }: IFilter) => {
    setPage(page - 1);
  };

  /* Фильтр по столбцам таблицы  */
  const rows = useMemo(() => {
    let filteredArr = [...dataCards.cards];
    filteredArr = filterObject({
      arr: filteredArr,
      fields: ['accountStr', 'fio', 'mobile', 'cardId'],
      searchStr,
    });
    /* Обрезаем массив до необходимых размеров отображения на странице */
    const startRow = pageNum === 0 ? 0 : (pageNum * countPerOnPage);
    const stopRow = (pageNum + 1) * countPerOnPage;

    const count = filteredArr.length;

    /* Обрезаем по строкам на странице после фильтра */
    filteredArr = filteredArr.slice(startRow, stopRow);

    filteredArr = filteredArr.map(row => {

      if (accountListDestination.findIndex(account => (account.id === row.accountId && account.disabled)) !== -1) {
        return { ...row, disabled: false };
      } else
        return { ...row, disabled: true };
    });

    return { arr: filteredArr, count };
  }, [dataCards, searchStr, pageNum, accountListDestination]);

  /* Заполняем табличные заголовки */
  const columns = useMemo(() => {
    return getColumns(accountListDestination.length !== 0);
  }, [accountListDestination]);

  const handleClickRow = (row: IRow) => {
    row.disabled && setAccountId(row.accountId);
  };

  const handleCommit = () => {
    onSelect(selectedAccountId);
    onClose();
  };

  return (
    <Grid item sx={{
      width: '870px', height: '520px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    }}>
      <InputSearchLine
        ref={ref => childRef.current = ref}
        placeholder={t('module.common.title.titleSearchStr')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setPage(0);
          setSearchStr(e.currentTarget.value);
        }}
        style={{ borderRadius: '5px' }}
      />
      <Grid>
        {
          <CustomTable
            selectedRow
            rows={rows.arr}
            count={rows.count}
            columns={columns}
            isLoading={isLoading}
            labelEmptyRows={t('module.common.table.emptyRows')}
            rowsPerPageOptions={[countPerOnPage]}
            fnRefreshData={handleChangePage}
            pageNum={pageNum}
            onClick={handleClickRow}
            sx={{ height: '391px' }}
          />
        }
      </Grid>
      <Grid sx={{ textAlign: 'right' }}>
        <LoadingButton variant={'contained'}
                       onClick={handleCommit}
                       disabled={selectedAccountId === -1}
        >{t('module.common.title.titleReportLabelBtn')}</LoadingButton>
        <LoadingButton variant={'contained'} sx={{ marginLeft: '.5rem' }}
                       onClick={onClose}>{t('modal.labelCancel')}</LoadingButton>
      </Grid>
    </Grid>
  );
});
