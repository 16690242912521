import i18next from 'i18next';
import { CardLimitsServiceProps, LimitsProps, LimitsServiceProps, PurseItemProps, PursesServiceProps } from '../../interface';

interface IGetLimits {
  data: CardLimitsServiceProps;
  dataPurses: PursesServiceProps;
  dataLimits: LimitsServiceProps;
}

/* Парсинг ответа сервера для корректного отображения списка лимитов карты, как для водителя так и для редактирования лимитов */
export function getCardLimits({ data, dataLimits, dataPurses }: IGetLimits) {
  return data.limits = data.limits.map(limit => {

    const listPursesId = limit.attachedPurses.map(purse => purse.purseId);

    const currentPurse: PurseItemProps[] = dataPurses.purses && dataPurses.purses.filter(purse => listPursesId.indexOf(purse.purseId) !== -1) || [];

    let listPurses = currentPurse.map(purse => purse.remark);
    if (!listPurses) listPurses = [''];

    const typeSchema = currentPurse[0]?.purseTypeId === 0
      ? i18next.t('module.cards.limits.titleShortMoneySchema')
      : i18next.t('module.cards.limits.titleShortQtySchema');

    const limitType: LimitsProps = dataLimits && dataLimits.types.find(type => (limit.limitType === type.id)) || {
      id: 0, remark: '',
    };

    const rest = limit.limitAmount - limit.limitBalance;

    return {
      ...limit,
      purses: listPurses.join(', '),
      type: limitType.remark,
      typeSchema: typeSchema,
      qtyLimit: limit.limitAmount,
      balance: limit.limitBalance,
      rest: rest > 0 ? rest.toFixed(2) : 0,
    };
  });
}
