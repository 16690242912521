import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { QRCardPropsService } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';


export const qrCardService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getCardQR: builder.query<QRCardPropsService, { cardId: string }>({
      async queryFn({ cardId }, {}, extraOptions, fetchWithBQ) {
        try {

          const result = await fetchWithBQ({
            url: `/users/cards/${cardId}/qr`,
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),
  }),
});
