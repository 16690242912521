import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { CardLimitTypeServiceProps } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';


export const cardLimitByAccountSubTypeService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getCardLimitBySubType: builder.query<CardLimitTypeServiceProps, { accountSubType: number }>({
      async queryFn({ accountSubType }, { dispatch }, extraOptions, fetchWithBQ) {
        try {

          const result = await fetchWithBQ({
            url: `/common/limits/types/groups`,
            params: { accountSubType, lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });


          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),
  }),
});
