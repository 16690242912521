import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Moment } from 'moment';
import { DatePickerFilled } from './style/DatePickerFilled';
import { toast } from '../../../utils';
import { useTranslation } from 'react-i18next';
import 'moment/locale/uk';

interface IDatePicker {
  labelInput?: string;
  disabled?: boolean;
  pickerValue: Moment,

  onChange(value: any): void;
}

export default function DatePicker({
                                     labelInput = 'Період',
                                     disabled = false,
                                     onChange,
                                     pickerValue,
                                   }: IDatePicker) {

  const { t } = useTranslation();

  const handleOnChange = (value: string, validate: { validationError: null; }) => {
    if (validate.validationError === null) {
      onChange(value);
    } else {
      toast({ type: 'error', message: t('error.ERROR_CHOICE_DATE') });
    }

  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'uk'}>
      <DatePickerFilled
        value={pickerValue}
        onChange={handleOnChange}
        format={'DD.MM.YYYY'}
        label={labelInput}
        slotProps={{ textField: { variant: 'filled' } }}
      />

    </LocalizationProvider>
  );
}
