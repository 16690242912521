import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cardMutation, cardScheduleService, transformResponseError } from '../../../../../services';
import { useParams } from 'react-router-dom';
import { CardScheduleListServiceProps, CardScheduleProps, RequestCardScheduleProps, RequestCardSchedulesProps } from '../../../../../interface';
import { useCheckPermission, useMediaQuery } from '../../../../../hooks';
import { DateRangeBlock, OutlineBox } from '../../../../ui';
import { Box } from '@mui/material';
import { LocalGridItem } from '../CardInfo/style';
import { LoadingButton } from '@mui/lab';

const mobileStyle = {
  margin: 0,
  padding: '.2rem',
};

export const CardSchedule = memo(() => {
  const { t } = useTranslation();
  const matches = useMediaQuery();

  /* Получаем аренсную строку */
  const params = useParams<{ cardId: string, accountId: string, holderId: string }>();
  const pCardId = useMemo(() => {return params?.cardId ?? '1';}, [params?.cardId]);

  const { data: cardSchedule = { schedule: [] } } = cardScheduleService.useGetCardScheduleQuery({ cardId: pCardId });
  const [updateSchedule, { isLoading }] = cardMutation.useUpdateSchedulesMutation();

  const [schedules, setSchedule] = useState<CardScheduleProps[]>([] as CardScheduleProps[]);

  useEffect(() => {
    cardSchedule.schedule.length > 0 && setSchedule(cardSchedule.schedule);
  }, [cardSchedule.schedule]);

  const onChangeTimePickerState = (dayIdx: number, fromTime: string, toTime: string, isEnableDay: boolean, isValid: boolean) => {
    setSchedule(prev => {
      let tmpSchedule: CardScheduleProps[] = prev;
      const idx = tmpSchedule.findIndex(schedule => schedule.dayOfWeek === dayIdx);
      tmpSchedule = [...tmpSchedule.slice(0, idx), {
        ...tmpSchedule[idx], fromTime, toTime, enable: isEnableDay, isValid,
      }, ...tmpSchedule.slice(idx + 1)];
      tmpSchedule[idx] = { ...tmpSchedule[idx], fromTime, toTime, enable: isEnableDay, isValid };
      return tmpSchedule;
    });
  };

  const isDisabledSaveScheduleDayOfWeek = useMemo(() => {
    /* Проверяем если не существует никакого расписание, тогда кнопка неактивна, если существует проверяем что оно валидно */
    return (!schedules || schedules.length === 0) ? true : schedules.findIndex(schedule => !schedule.isValid) !== -1;
  }, [schedules]);

  const onChangeTimePicker = async () => {
    const newSchedule: CardScheduleProps[] = schedules.map(schedule => ({
      ...schedule,
      dayOfWeek: schedule.dayOfWeek,
      dayOfWeekName: schedule.dayOfWeekName,
      fromTime: schedule.fromTime,
      toTime: schedule.toTime,
      enable: schedule.enable,
    }));

    await updateSchedule({ cardId: pCardId, schedule: { schedule: newSchedule } })
      .then(({ error }: any) => {
        const isError = !!error;
        transformResponseError({ isError, error: String(error), successToast: true });
      });
  };

  const { checkPermission } = useCheckPermission();
  /* Получаем доступность */
  const isAllowChangeSchedule = useMemo(() => {
    return !checkPermission<boolean>({ accessPoint: 'cards.access.setting.schedule' });
  }, [checkPermission]);

  return (
    <LocalGridItem item xs={12} style={matches ? { ...mobileStyle } : {}} sx={{ padding: 0 }}>
      <OutlineBox style={{ width: '350px' }}
                  label={t('module.cards.titlePermitByDayOfTheWeek')}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {schedules?.map((schedule, idx) => (
            <DateRangeBlock key={idx} dayOfWeek={{ dayIdx: idx + 1, dayName: schedule.dayOfWeekName }}
                            fromTime={schedule.fromTime}
                            toTime={schedule.toTime}
                            onChange={onChangeTimePickerState}
                            isEnableDay={schedule.enable}
                            disabled={isAllowChangeSchedule}
            />))

          }

          {
            !isAllowChangeSchedule
              ?
              <LoadingButton
                variant={'contained'}
                style={{ marginTop: '1rem' }}
                disabled={isDisabledSaveScheduleDayOfWeek}
                onClick={onChangeTimePicker}
              >
                {t('module.common.title.titleSubmit')}
              </LoadingButton>
              : <Box sx={{ margin: '1.7rem 0' }} />
          }

        </Box>
      </OutlineBox>
    </LocalGridItem>
  );
});
