import { Badge, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCard';

interface BadgeQtyProps {
  link: string;
  qty: number;
}

const LinkLabel = styled(Typography)((props) => ({
  fontSize: 'inherit', opacity: '.5', '&:hover': { opacity: '1', color: props.theme.palette.secondary.main },
}));

const StyleNavLink = styled(NavLink)(() => ({
  textDecoration: 'none',
  color: 'inherit',
}));

const BadgeLink = styled(Badge)(({ theme }) => ({
  '& svg:hover': {
    color: theme.palette.primary.main,
  },
  '& svg:hover + .MuiBadge-badge': {
    backgroundColor: theme.palette.primary.main,
  },

}));

export default function BadgeQty({ link, qty }: BadgeQtyProps) {
  return (
    <StyleNavLink to={link}>
      <LinkLabel>

        <BadgeLink badgeContent={qty} color={'primary'} max={9999999} >
          <CreditCardIcon color='secondary' />
        </BadgeLink>

      </LinkLabel>
    </StyleNavLink>
  );
}
