import { FC, memo } from 'react';
import { Slide } from '@mui/material';
import { Container } from './style/Container';
import { InnerContainerProps } from '../../../interface';


export const InnerContainer: FC<InnerContainerProps> = memo(({ show, children, sx = {}, direction = 'left' }) => (
  <Slide direction={direction} in={show} mountOnEnter unmountOnExit>
    <Container sx={{ ...sx }} >
      {children}
    </Container>
  </Slide>
));
