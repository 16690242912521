import React, { FC, memo, useMemo } from 'react';
import { Typography } from '@mui/material';
import { HolderLineProps } from '../../../../../../../interface';

export const HolderLine: FC<HolderLineProps> = memo(({
                                                       text,
                                                       fontWeight,
                                                       maxLength,
                                                       color = '#ccc',
                                                       fontSize = '.6rem',
                                                     }) => {

  const str = useMemo(() => {
    return (maxLength && text.length > maxLength) ? `${text.slice(0, maxLength)}...` : text;
  }, [text, maxLength]);

  return <Typography color={color}
                     sx={{ fontSize, fontWeight, textAlign: 'left' }}>{str?.toUpperCase()}</Typography>;
});
