import React, { memo, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Title } from '../styles/Title';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '../styles/LoadingButton';
import { registerOrForgotService, transformResponseError } from '../../../../services';
import { LinkRegisterType } from '../../../../helpers';
import { SendTopProps } from '../../../../interface';
import { InputFilled } from '../../../ui';
import { useCustomStyles } from '../../../../styles';
import { CenterContainer, FooterContainerButton, LinkRegister } from '../..';
import { toast } from '../../../../utils';


export const NewPassAfterOtp = memo(() => {
  const { t } = useTranslation();
  const global = useCustomStyles();
  const navigation = useNavigate();
  const { typeOtp, phone } = useParams();

  const [newPass, setPass] = useState<SendTopProps>({
    pass: '',
    otp: '',
    isShowPassword: false,
  });

  const validationSchema = yup.object().shape({
    otp: yup.string().required(t('error.REQUIRED_FIELD')),
    pass: yup.string().required(t('error.REQUIRED_FIELD')),
  });

  const {
    register, handleSubmit, formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const handleChangePass = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newState = {
      ...newPass,
      [name]: value,
    };

    setPass(newState);
  };

  const handleShowPassword = () => {
    setPass(prev => ({ ...prev, isShowPassword: !prev.isShowPassword }));
  };

  const formType = useMemo(() => {
    return typeOtp === LinkRegisterType.Register
      ? LinkRegisterType.Register
      : LinkRegisterType.ForgotPassword;
  }, [typeOtp]);

  const renderLabelSubmit = useMemo(() => {
    return formType === LinkRegisterType.Register
      ? t('module.register.btnRegisterOTP')
      : t('module.register.btnUpdatePassOTP');
  }, [t, formType]);

  const [registerOrForgot, { isLoading }] = registerOrForgotService.useLazyRegisterOrForgotQuery();
  const handleRegisterNewPass = () => {
    registerOrForgot({
      type: formType,
      otp: newPass.otp,
      phone: String(phone),
      password: newPass.pass,
    }).then(({ isError, error }) => {
      transformResponseError({ isError, error: String(error) });
      if (!isError) {
        const message = formType === LinkRegisterType.Register ? t('error.REGISTER_SUCCESSFULLY') : t('error.PASSWORD_CHANGED');
        toast({ type: 'success', message });
        navigation('/login', { replace: true });
      }
    });
  };

  const handleClickLinkEnter = () => {
    navigation('/login', { replace: true });
  };

  return (
    <>
      <CenterContainer item className={global.titleBold}>
        <Title item className={global.titleBold}>{t('module.register.updateCRM')}</Title>
        <Grid item className={global.textDefault}
              sx={{ marginBottom: '1rem' }}>{t('module.register.welcomeTextOTP')}</Grid>
        <InputFilled
          label={t('module.register.titleOTP')}
          name={'otp'}
          onChange={handleChangePass}
          inputProps={register('otp')}
          error={!!errors.otp}
          helperText={errors.otp ? (errors?.otp?.message as ReactNode) : ' '}
        />
        <Grid item className={global.textDefault}
              sx={{ marginBottom: '1rem' }}>{t('module.register.titleForPass')}</Grid>
        <InputFilled label={t('module.register.titleEnterPass')}
                     name={'pass'}
                     onChange={handleChangePass}
                     type={!newPass.isShowPassword ? 'password' : 'text'}
                     InputProps={{
                       endAdornment: <InputAdornment position='end'>
                         <IconButton onClick={handleShowPassword} edge='end'>
                           {!newPass.isShowPassword ? <VisibilityOff /> : <Visibility />}
                         </IconButton>
                       </InputAdornment>,
                     }}
                     sx={{ '& .MuiInputBase-root:after, & .MuiInputBase-root:before': { display: 'none' } }}
                     inputProps={register('pass')}
                     error={!!errors.pass}
                     helperText={errors.pass ? (errors?.pass?.message as ReactNode) : ' '}
        />
        <LoadingButton variant='contained' loading={isLoading}
                       onClick={handleSubmit(handleRegisterNewPass)}>{renderLabelSubmit}</LoadingButton>
      </CenterContainer>
      <FooterContainerButton>
        <LinkRegister className={global.textDefault}
                      onClick={handleClickLinkEnter}>{t('module.login.btnEnter')}
        </LinkRegister>
      </FooterContainerButton>
    </>
  );
});
