import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useStyles } from './style';
import { memo, useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { RegisterDriverProps } from '../../../interface';
import { registerDriverApi } from '../../../services';
import { useCustomStyles } from '../../../styles';


export const RegisterDriver = memo(() => {

    /* Получаем адренсную строку */
    const params = useParams();
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const { t } = useTranslation();

    const otc = searchParams.get('otc');
    const phone = searchParams.get('phone');
    const cardId = searchParams.getAll('cardId');

    const classes = useStyles();

    const [isLoading, setLoading] = useState(false);

    const [urlParam, setUrlParam] = useState<RegisterDriverProps | any>({ otc: '', phone: '', cardId: '' });

    const [updatePhoneOtc] = registerDriverApi.useUpdatePhoneOtcMutation();

    const [showSuccessMessage, setSuccessMessage] = useState(false);

    useEffect(() => {
      searchParams.size === 3 && setUrlParam({ otc, phone, cardId });
    }, [params]);

    const global = useCustomStyles();

    const handleSubmitRegistrationLink = () => {
      setLoading(true);
      updatePhoneOtc({ otc: urlParam.otc }).then((res: any) => {
        if (res && res.data && res.data.errorCode === 0) {
          setSuccessMessage(true);
        }
        setLoading(false);
      });
    };

    const navigateToRegister = () => {
      navigate(`/mode=register&phone=${urlParam.phone}`, { replace: true });
    };

    return <>
      <Grid className={classes.container}>
        <Grid className={global.textDefault}
              style={{
                maxWidth: '450px',
                border: '1px solid #ccc',
                padding: '1rem',
                borderRadius: '5px',
              }}>

          {showSuccessMessage &&
            <>
              <Typography style={{ textAlign: 'center' }}>
                {t('module.register.titleRegisterDriverSuccess', { urlParam })}
              </Typography>
              <Button
                variant='contained'
                style={{
                  width: '100%',
                  marginTop: '1rem',
                  marginRight: '1rem',
                  padding: '.7rem 0',
                }}
                onClick={navigateToRegister}>
                {t('module.register.btnRegisterOTP')}
              </Button>
            </>
          }

          {!showSuccessMessage &&
            <>
              <Typography style={{ textAlign: 'center' }}>
                {t('module.register.titleRegisterDriver', { urlParam })}
              </Typography>
              <Grid style={{ display: 'flex' }}>
                <LoadingButton
                  loading={isLoading}
                  variant='contained'
                  style={{
                    width: '100%',
                    marginTop: '1rem',
                    marginRight: '1rem',
                    padding: '.7rem 0',
                  }}
                  onClick={handleSubmitRegistrationLink}>
                  {t('module.common.title.titleCommit')}
                </LoadingButton>
                <Button
                  variant='contained'
                  style={{
                    width: '100%',
                    marginTop: '1rem',
                    padding: '.7rem 0',
                  }}
                  onClick={() => navigate('/', { replace: true })}>
                  {t('module.common.title.titleCancel')}
                </Button>
              </Grid>
            </>
          }


        </Grid>
      </Grid>
    </>;
  },
);
