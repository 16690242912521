import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { PursesProps } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';


export const purseService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getPursesByAccountId: builder.query<PursesProps, {accountId: number}>({
      async queryFn({accountId}, {}, extraOptions, fetchWithBQ) {
        try {
          const result = await fetchWithBQ({
            url: `/accounts/${ accountId }/purses`,
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),
  }),
});
