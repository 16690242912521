import CookiesJS from 'js-cookie';
import { Cookies } from '../../helpers';
import moment from 'moment';
import { getStorage, removeStorage } from '../../services';
import { Storage } from '../../config';

/**
 * Запись токена
 * @param token
 * @param expireAt
 */
export const setToken = (token: string, expireAt: string) => {
  CookiesJS.set(Cookies.token, token, { expires: moment(expireAt).toDate() });
};


/**
 * Получение токена
 */
export const getToken = () => {
  return getStorage(Storage.token); //CookiesJS.get(Cookies.token);
};

/**
 * Удаление токена
 */
export const delToken = () => {
  removeStorage(Storage.refreshToken);
  removeStorage(Storage.token);
  //CookiesJS.remove(Cookies.token);
};
