import React, { memo, useMemo, useState } from 'react';
import { Button, LanguageContainer } from './style';

import { List, ListItem, ListItemButton, ListItemText, Popover, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useCheckPermission } from '../../../../../hooks';
import { setStorage } from '../../../../../services';
import { Storage } from '../../../../../config';

export const MainChangeLanguage = memo(() => {
  const { i18n } = useTranslation();
  const { config } = useAppSelector(state => state.layoutReducer);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (lang: string) => {
    setStorage(Storage.language, lang);
    i18n.changeLanguage(lang).then(() => {
      handleClose();
    });
  };

  const renderListLanguage = useMemo(() => {
    return config.locale.language.map((lang, idx) => (
      <ListItem disablePadding key={idx}>
        <ListItemButton
          selected={i18n.language.toUpperCase() === lang.label}
          onClick={handleChangeLanguage.bind(null, lang.label.toLowerCase())}>
          <ListItemText primary={lang.label} />
        </ListItemButton>
      </ListItem>
    ));
  }, [config, i18n.language, handleChangeLanguage]);

  const { checkPermission } = useCheckPermission();
  /* Получаем доступность */
  const isAllowChangeLanguage = useMemo(() => {
    return checkPermission<boolean>({ accessPoint: 'changeLanguage' });
  }, [checkPermission]);

  if (!isAllowChangeLanguage) {
    return <></>;
  }

  return <LanguageContainer>
    <Button onClick={handleClick} open={open}>
      <Typography color={'#ccc'}>{i18n.language.toUpperCase()}</Typography>
    </Button>
    <Popover
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <List sx={{ padding: 0 }}>
        {
          renderListLanguage
        }
      </List>
    </Popover>

  </LanguageContainer>;
});
