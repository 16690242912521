import { CircularProgress, TableRow } from '@mui/material';
import { FullScreenBox, StyleCell } from './style/style';


interface ILoading {
  type?: 'default' | 'small' | 'medium' | 'big' | 'fullScreen';
}

export default function Loading({ type = 'default' }: ILoading) {
  switch (type) {
    case 'small': {
      return <CircularProgress color='secondary' size={20} />;
    }

    case 'medium': {
      return <CircularProgress color='secondary' size={40} />;
    }

    case 'big': {
      return <CircularProgress color='secondary' size={80} />;
    }

    case 'fullScreen': {
      return <FullScreenBox><CircularProgress color='secondary' size={60} /></FullScreenBox>;
    }

    default: {
      return (
        <TableRow>
          <StyleCell colSpan={25}>
            <CircularProgress color='secondary' />
          </StyleCell>
        </TableRow>
      );
    }
  }


}
