import { memo, Suspense, useEffect, useMemo, useState } from 'react';
import { TTypeAccountSubType } from './limits.interface';
import { Box, Button, Grid } from '@mui/material';
import { HolderGroupTitle } from '../../../../common';
import { Loading } from '../../../../ui';
import CustomTable from '../../../../ui/custom-table';
import { IFilter, IOrderBy } from '../../../../ui/custom-table/interface/interface.custom';

import { styled } from '@mui/material/styles';

import { COUNT_PER_ON_PAGE, ModalButton } from '../../../../../config';
import { CardsByAccount, ModalAddLimit } from './components';
import { getColumnsLimits } from './limits.columns';
import { useParams } from 'react-router-dom';
import { useActiveHolder, useCheckPermission, useMediaQuery, useModal } from '../../../../../hooks';
import { getAccountIdFromParams } from '../../CardList/utils/utils';
import { accountsService, cardInfoService, cardMutation, limitsCardService, transformResponseError } from '../../../../../services';
import { AccountProps, CardsProps } from '../../../../../interface';
import { useTranslation } from 'react-i18next';

/* Кол-во строк в таблице счетов, на странице инфо клиента */
const countPerOnPage: number = +COUNT_PER_ON_PAGE;

const GridTitle = styled(Grid)(() => ({
  display: 'flex', alignItems: 'center', '&>.MuiGrid-root': {
    margin: 0,
  },
}));

export const CardLimit = memo(() => {
  const { t } = useTranslation();
  /* Инициализация медиа  */
  const matches = useMediaQuery();

  const { checkPermission } = useCheckPermission();
  const { showModal, closeModal } = useModal();

  const [isOpenModal, setOpenModal] = useState({ isShow: false, title: '', accountSubType: 0 });
  const [account, setAccount] = useState<AccountProps>();

  const [card, setCard] = useState<CardsProps>();

  const { activeHolderId } = useActiveHolder();

  /* Получаем адренсную строку */
  const params = useParams<{ cardId: string, accountId: string, holderId: string }>();

  /* Получаем карту из адресной строки */
  const pCardId = useMemo(() => {return params?.cardId ?? '1';}, [params?.cardId]);

  /* Получаем карту */
  const {
    data: dataCards = { cards: [] },
  } = cardInfoService.useGetCardInfoQuery({ cardId: pCardId });

  useEffect(() => {
    dataCards.cards.length > 0 && setCard(dataCards.cards[0]);
  }, [dataCards]);

  const pAccountId = useMemo(() => (getAccountIdFromParams(params)), [params?.accountId]);

  /* Получаем список счетов выбранного клиента */
  const { data: dataAccounts = { accounts: [] } } = accountsService.useGetAccountsByHolderIdQuery({ holderId: activeHolderId });

  useEffect(() => {
    const selectedAcc = dataAccounts.accounts.filter(a => a.id === +pAccountId)[0];
    selectedAcc && setAccount(selectedAcc);
  }, [dataAccounts, pAccountId]);

  const cardMemo = useMemo(() => {
    const reserveCard = { cardId: '' };
    return card ? card : reserveCard;
  }, [card]);

  /* Получаем список лимитов выбранной карты */
  const {
    data: dataLimits = { limits: [] }, isLoading: isLoadingLimits,
  } = limitsCardService.useGetCardLimitsQuery({ cardId: pCardId });

  const [deleteLimit] = cardMutation.useDeleteLimitMutation();
  const [updateLimit] = cardMutation.useUpdateLimitMutation();

  /* Обновляем лимит */
  const handleUpdateLimit = async (limitId: number, limitAmount: number) => {
    return updateLimit({ cardId: pCardId, limitId, limitAmount })
      .then(({ error }: any) => {
        const isError = !!error;
        transformResponseError({ isError, error: String(error), successToast: true });
        return !isError;
      });
  };

  /* Удаляем лимит */
  const handleDeleteLimit = async (limitId: number) => {
    return deleteLimit({ cardId: pCardId, limitId })
      .then(({ error }: any) => {
        const isError = !!error;
        transformResponseError({ isError, error: String(error), successToast: true });
        return !isError;
      });
  };

  /* Заполняем табличные заголовки */
  const columns = useMemo(() => {
    return getColumnsLimits({
      handleDeleteLimit,
      handleUpdateLimit,
      checkPermission,
    });
  }, [handleDeleteLimit, handleUpdateLimit, checkPermission]);

  /* Текущая страница счетов */
  const [pageNum, setPage] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<IOrderBy>({ field: '', order: 'asc' });
  const handleChangePage = ({ page, orderBy }: IFilter) => {
    setOrderBy(orderBy);
    setPage(page - 1);
  };

  /* Фильтр по столбцам таблицы  */
  const rows = useMemo(() => {
    /* Применение фильтра */
    let filteredArr = dataLimits.limits;
    const count = filteredArr.length;

    /* Обрезаем массив до необходимых размеров отображения на странице */
    const startRow = pageNum === 0 ? 0 : (pageNum * countPerOnPage);
    const stopRow = (pageNum + 1) * countPerOnPage;

    /* Обрезаем по строкам на странице после фильтра */
    filteredArr = filteredArr.slice(startRow, stopRow);

    return { arr: filteredArr, count };

  }, [pageNum, orderBy, dataLimits]);

  /* Получаем подтип аккаунта */
  const accountSubType = useMemo(() => {
    return account ? account.accountSubType : -1;
  }, [account]);


  const renderButtonsAddLimits = useMemo(() => {
    return <Box sx={{ margin: matches ? '.7rem' : '0 0 0 .5rem' }}>
      {[0, 101].indexOf(accountSubType) !== -1 && <Button
        variant={'contained'}
        sx={{ width: matches ? '150px' : '190px', fontSize: matches ? '.7rem' : '.9rem' }}
        onClick={() => setOpenModal({
          isShow: true,
          title: t('module.cards.limits.titleMoneySchema'),
          accountSubType: 101,
        })}
      >
        {t('module.cards.limits.titleMoneySchema')}
      </Button>}

      {[0, 102].indexOf(accountSubType) !== -1 && <Button
        variant={'contained'}
        sx={{ marginLeft: '.5rem', width: matches ? '150px' : '190px', fontSize: matches ? '.7rem' : '.9rem' }}
        onClick={() => setOpenModal({
          isShow: true,
          title: t('module.cards.limits.titleQtySchema'),
          accountSubType: 102,
        })}
      >
        {t('module.cards.limits.titleQtySchema')}
      </Button>}
    </Box>;
  }, [accountSubType, matches]);

  const chooseCardForCopy = () => {
    showModal({
      buttonType: ModalButton.Empty,
      children: <CardsByAccount onClose={closeModal} accountId={pAccountId} parentCard={pCardId} />,
    });
  };


  /* Получаем доступность полностью лимита */
  const isAllowLimit = useMemo(() => {
    return checkPermission<boolean>({ accessPoint: 'cards.access.limit.isAllow' });
  }, [checkPermission]);

  /* Получаем доступность полностью лимита */
  const isAllowAddLimit = useMemo(() => {
    return checkPermission<boolean>({ accessPoint: 'cards.access.limit.add' });
  }, [checkPermission, isAllowLimit]);

  if (!isAllowLimit) {
    return <></>;
  }

  return <>
    <Grid item xs={12} md={10}>

      <GridTitle item xs={12} flexWrap={matches ? 'wrap' : 'nowrap'}
                 sx={{
                   margin: 'initial',
                   width: '100%',
                   display: 'flex',
                   alignItems: 'center',
                   justifyContent: 'center',
                 }}>


        <HolderGroupTitle isLoading={isLoadingLimits}
                          title={isAllowAddLimit ? t('module.dataGroup.addLimits') : t('module.cards.limits.title')}
                          mt={0} />

        <Box sx={{ marginRight: 'auto' }}>
          {isAllowAddLimit && renderButtonsAddLimits}
        </Box>

        {/*
          <Button variant={'contained'} sx={{
            width: matches ? '170px' : '190px',
            marginBottom: matches ? '.5rem' : '0',
            fontSize: matches ? '.7rem' : '.9rem',
          }} onClick={chooseCardForCopy}>
            {t('module.cards.limits.copyLimit')}
          </Button>
        */}
      </GridTitle>

      <CustomTable
        rows={rows.arr}
        columns={columns}
        isLoading={isLoadingLimits}
        count={rows.count}
        selectedRow
        labelEmptyRows={t('module.common.table.emptyRows')}
        rowsPerPageOptions={[countPerOnPage]}
        fnRefreshData={handleChangePage}
        accountId={cardMemo && cardMemo.cardId}
      />

    </Grid>
    {
      isOpenModal && <Suspense fallback={<Loading type={'fullScreen'} />}>
        <ModalAddLimit title={isOpenModal.title} isShow={isOpenModal.isShow}
                       handleClose={() => setOpenModal({ isShow: false, title: '', accountSubType: 0 })}
                       cardId={cardMemo.cardId}
                       accountSubType={isOpenModal.accountSubType as TTypeAccountSubType} />
      </Suspense>
    }
  </>;
});
