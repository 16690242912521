import { IOrderBy } from '../../components/ui/custom-table/interface/interface.custom';

interface IFieldSort {
  array: any[];
  orderBy: IOrderBy;
}

/* Сортировка полей на клиенте */
export function fieldSort({ array, orderBy: { field, order } }: IFieldSort) {
  return array.slice().sort(function(a: any, b: any) {
    if (order === 'desc') {
      if (a[field] > b[field]) {
        return 1;
      }
      if (a[field] < b[field]) {
        return -1;
      }
    } else {
      if (a[field] < b[field]) {
        return 1;
      }
      if (a[field] > b[field]) {
        return -1;
      }
    }
    // a должно быть равным b
    return 0;
  });
}
