import { memo } from 'react';

import { ButtonLanguage } from './style/ButtonLanguage';
import { Box } from '@mui/material';
import { LanguageItemProps } from '../../../../../../interface';

export const LanguageItem = memo(({ idx, label, onClick, qty, currentLanguage }: LanguageItemProps) => {
  return (
    <Box key={label}>
      <ButtonLanguage variant={'text'}
                      style={{ color: currentLanguage !== label.toLowerCase() ? 'inherit' : '#626262' }}
                      onClick={onClick.bind(null, label)}
      >{label}</ButtonLanguage>
      {idx + 1 !== qty ? '|' : ''}
    </Box>
  );
});
