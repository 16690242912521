import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { DocumentListServiceProps, DocumentProps, RequestDocumentListServiceProps } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';


export const documentsListService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getDocuments: builder.query<DocumentListServiceProps, RequestDocumentListServiceProps>({
      async queryFn(params, {}, extraOptions, fetchWithBQ) {
        try {
          const result = await fetchWithBQ({
            url: `/common/docs`,
            params: {
              lang: getLangIso(),
              ...params,
            },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          data.docs = data.docs.map((doc: DocumentProps) => ({
            ...doc,
            amount: doc.docHeaders?.amount || '-',
            quantity: doc.docHeaders?.quantity || '-',
          }));

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),
  }),
});
