import { ActiveAccountsProps } from '../../../../../interface';

export interface IFilterCardsField {
  active: ActiveAccountsProps;
  cardId: string,
  mobile: string,
  vin: string,
  firstName: string,
  lastName: string
}

export const initialStateFilterCard: IFilterCardsField = {
  active: {
    isActive: false,
    isBlock: false,
  },
  cardId: '',
  mobile: '',
  vin: '',
  firstName: '',
  lastName: '',
};
