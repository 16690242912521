import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { HoldersListServiceProps } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';
import i18next from 'i18next';


export const holdersListService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getHolders: builder.query<HoldersListServiceProps, { withEmptyLine?: boolean } | any>({
      async queryFn({ withEmptyLine }, {}, extraOptions, fetchWithBQ) {
        try {
          const result = await fetchWithBQ({
            url: `/users/profile`, // holders
            params: { lang: getLangIso() },
          });

          const answer = checkErrors<any>({ result, meta: result.meta });

          const data = { holders: [...answer.user.holders ]  };

          /* Добовляем строку "Не обрано" */
          if (withEmptyLine) {
            data.holders = [{
              ...data.holders[0],
              id: -1,
              name: i18next.t('module.common.filter.titleNotSelected'),
            }, ...data.holders];
          }

          return { data } ;
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),
  }),
});
