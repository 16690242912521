import { createContext, useContext } from 'react';
import { IFilterCardsField,  initialStateFilterCard } from "./interface.filter";

type TContext = {
	filter: IFilterCardsField | any;
}

export const CardContext = createContext<TContext>( { filter: initialStateFilterCard } );

export const useFilterCardContext = () => useContext( CardContext )

export default CardContext;