import React, { memo, useEffect, useMemo, useState } from 'react';
import { accountsService, cardListService } from '../../../../../../../services';
import { ContainerRow, CustomTable, InputSearchLine } from '../../../../../../ui';
import { AccessAccountsProps } from '../../interface';
import { IRow } from '../../../../../../ui/custom-table/components/Rows/Rows';
import { useTranslation } from 'react-i18next';
import { getColumns } from '../utils/account.columns';
import { getColumnsCard } from '../utils/card.columns';
import { filterObject } from '../../../../../../../utils';

interface HolderAccountProps {
  holderId: number;
  accessAccounts: AccessAccountsProps[];
  limitCards: boolean;
  disabled?: boolean;

  onChange(holderId: number, accessAccounts: AccessAccountsProps[]): void;
}

const accounts = {
  accounts: [],
  sumTotal: {
    totalBalance: 0,
    totalBalanceWithoutOverdraft: 0,
    totalOverdraft: 0,
  },
};

const countPerOnPage: number = 7;//+COUNT_PER_ON_PAGE;

export const HolderAccount = memo(({
                                     holderId,
                                     accessAccounts,
                                     onChange,
                                     limitCards = false,
                                     disabled = false,
                                   }: HolderAccountProps) => {
  const { t } = useTranslation();

  const {
    data: dataAccounts = accounts,
    isLoading: isLoadingClient,
  } = accountsService.useGetAccountsByHolderIdQuery({ holderId, withChecked: true });

  const [getCardsList, {
    data: dataCards = { cards: [] },
    isLoading: isLoadingCard,

  }] = cardListService.useLazyGetCardListQuery();

  const [selectedAccount, setAccount] = useState<number>(-1);

  const [searchAccountStr, setSearchAccountStr] = useState('');
  const [searchCardStr, setSearchCardStr] = useState('');

  /* Текущая страница счетов */
  const [pageNum, setPage] = useState<number>(0);

  const cbChangePage = (page: number) => { setPage(page - 1); };

  /* Текущая страница карт */
  const [pageNumCard, setPageCard] = useState<number>(0);

  const cbChangeCardPage = (page: number) => { setPageCard(page - 1); };

  const handleEditAccount = (accountId: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const isAdd: boolean = e.target.checked;


    // Проверяем что мы хотим добавить
    if (isAdd) {
      // Проверяем что такой accountId еще не добавлен
      // Если мы добавляем значит на всякий случай удалим если такой массив уже имееть
      if (accessAccounts.some(account => account.accountId === accountId)) {
        accessAccounts = accessAccounts.filter(a => a.accountId !== accountId);
      }
      // Добавляем новый элемент в массив c пустыми данными
      accessAccounts = [...accessAccounts, { accountId, accessCards: [] }];
    } else {
      // Если нужно удалить, проверяем, что пользователь имеет массив accessAccounts
      if (accessAccounts.length > 0) {
        accessAccounts = accessAccounts.filter(account => account.accountId !== accountId);
      }
    }

    onChange(holderId, accessAccounts);
  };

  const handleChangeCards = (accountId: number, cardId: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const isAdd: boolean = e.target.checked;
    const newAccessAccounts = [...accessAccounts];
    let updatedAccessAccounts: any;
    // Пытаемся найти индекс элемента аккаунта
    const idx = newAccessAccounts.findIndex(n => n.accountId === accountId);
    const accessCards = [...newAccessAccounts[idx]?.accessCards as { cardId: string }[]];

    // Проверяем что мы хотим добавить
    if (isAdd) {
      // Проверяем что такой карты нет
      const isExistCard = accessCards?.find(c => c.cardId === cardId);
      // Если объект заморожен, создаем глубокую копию
      const newAccessCards = isExistCard ? accessCards : [...accessCards, { cardId }];

      // Если объект заморожен, создаем глубокую копию newAccessAccounts
      updatedAccessAccounts = newAccessAccounts.map((account, index) => {
        if (index === idx) {
          return { ...account, accessCards: newAccessCards };
        }
        return account;
      });


    } else {
      // Удаляем карту
      // Если объект заморожен, создаем глубокую копию newAccessAccounts
      updatedAccessAccounts = newAccessAccounts.map((account, index) => {
        if (index === idx) {
          // Удаляем карту
          return { ...account, accessCards: accessCards.filter(c => c.cardId !== cardId) };
        }
        return account;
      });
    }

    onChange(holderId, updatedAccessAccounts);

  };

  const dataAccountsChecked = useMemo(() => {
    return dataAccounts.accounts.map(account => ({
      ...account,
      checked: accessAccounts.findIndex(a => a.accountId === account.accountId) !== -1,
    }));
  }, [dataAccounts, accessAccounts]);

  const rows = useMemo(() => {
    let filteredArr = [...dataAccountsChecked];
    filteredArr = filterObject({ arr: filteredArr, fields: ['accountId', 'remark'], searchStr: searchAccountStr });

    /* Обрезаем массив до необходимых размеров отображения на странице */
    const startRow = pageNum === 0 ? 0 : (pageNum * countPerOnPage);
    const stopRow = (pageNum + 1) * countPerOnPage;

    /* Обрезаем по строкам на странице после фильтра */
    filteredArr = filteredArr.slice(startRow, stopRow);
    const count = searchAccountStr !== '' ? filteredArr.length : dataAccountsChecked.length;

    return { arr: filteredArr, count };
  }, [dataAccountsChecked, searchAccountStr, pageNum]);

  /* Заполняем табличные заголовки */
  const columns = useMemo(() => {
    return getColumns({ handleEditAccount, disabled });
  }, [handleEditAccount, disabled]);

  const dataCardsChecked = useMemo(() => {
    const cards = accessAccounts.find(a => a.accountId === selectedAccount);

    return dataCards.cards.map(card => {
      let isChecked = false;
      if (cards !== undefined) {
        isChecked = cards.accessCards?.findIndex(c => c.cardId === card.cardId) !== -1;
      }

      return ({
        ...card,
        checked: isChecked,
      });
    });
  }, [dataCards, accessAccounts, selectedAccount]);

  const rowsCard = useMemo(() => {
    let filteredArr = [...dataCardsChecked];
    filteredArr = filterObject({
      arr: filteredArr,
      fields: ['cardId', 'fio', 'vin', 'mobile'],
      searchStr: searchCardStr,
    });

    /* Обрезаем массив до необходимых размеров отображения на странице */
    const startRow = pageNumCard === 0 ? 0 : (pageNumCard * countPerOnPage);
    const stopRow = (pageNumCard + 1) * countPerOnPage;

    /* Обрезаем по строкам на странице после фильтра */
    filteredArr = filteredArr.slice(startRow, stopRow);
    const count = searchAccountStr !== '' ? filteredArr.length : dataCardsChecked.length;

    return { arr: filteredArr, count };
  }, [dataCardsChecked, searchCardStr, pageNumCard]);

  const columnsCard = useMemo(() => {
    return getColumnsCard({ handleChangeCards, disabled });
  }, [handleChangeCards, disabled]);

  const handleClickRowAccount = (row: IRow) => {
    const newAccounts = [...dataAccounts.accounts];
    const idx = newAccounts.findIndex(account => account.accountId === row.id);
    newAccounts[idx] = { ...newAccounts[idx], checked: !newAccounts[idx].checked };
    //dispatch(updateSelectedAccount({ holderId, withChecked: true, newAccounts }));
    if (newAccounts[idx].checked) {
      setAccount(row.id as number);
    } else setAccount(-1);
  };

  useEffect(() => {
    selectedAccount !== -1 && getCardsList({ holderId, accountId: selectedAccount, withChecked: true });
  }, [selectedAccount, holderId]);

  const handleClickRowCard = (row: IRow) => {
    const newCards = [...dataCards.cards];
    const idx = newCards.findIndex(card => card.cardId === row.id);
    newCards[idx] = { ...newCards[idx], checked: !newCards[idx].checked };
    //dispatch(updateSelectedCard({ holderId, accountId: row.accountId, withChecked: true, newCards }));
  };

  const renderSearchAccountCmp = useMemo(() => {
    return (<>

        <InputSearchLine
          placeholder={t('module.common.title.titleSearchStr')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchAccountStr(e.currentTarget.value);
          }}
          style={{ borderRadius: '5px' }}
        />
      </>
    );
  }, []);

  const renderSearchCardCmp = useMemo(() => {
    return (<>

        <InputSearchLine
          placeholder={t('module.common.title.titleSearchStr')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchCardStr(e.currentTarget.value);
          }}
          style={{ borderRadius: '5px' }}
        />
      </>
    );
  }, []);

  return <>
    <ContainerRow>
      <CustomTable
        rows={rows.arr}
        count={rows.count}
        columns={columns}
        isLoading={isLoadingClient}
        labelEmptyRows={t('module.common.table.emptyRows')}
        topComponent={renderSearchAccountCmp}
        topPagination
        rowsPerPageOptions={[countPerOnPage]}
        cbChangePage={cbChangePage}
        pageNum={pageNum}
        isSimple
        onClick={handleClickRowAccount}
        selectedRow
        sx={{ width: '100%', height: !limitCards ? '100%' : '197px', '& .MuiTableCell-root': { padding: 0 } }}
      />

      {
        limitCards &&
        <CustomTable
          rows={rowsCard.arr}
          count={rowsCard.count}
          columns={columnsCard}
          isLoading={isLoadingCard}
          labelEmptyRows={t('module.common.table.emptyRows')}
          isSimple
          selectedRow
          onClick={handleClickRowCard}
          topComponent={renderSearchCardCmp}
          topPagination
          rowsPerPageOptions={[countPerOnPage]}
          cbChangePage={cbChangeCardPage}
          pageNum={pageNumCard}
          sx={{ height: '197px', '& .MuiTableCell-root': { padding: 0 } }}
        />
      }

    </ContainerRow>

  </>;
});
