import React, { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { styled } from '@mui/material/styles';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { TimeField } from '@mui/x-date-pickers';
import moment from 'moment';

const Wrapper = styled(Grid)(() => ({
  display: 'flex', justifyContent: 'space-between', '& .MuiCheckbox-root': {
    padding: '0',
  },
}));

export const TimePickerWrapper = styled(Grid)(() => ({
  width: '170px', display: 'flex', justifyContent: 'space-between', '& .MuiFormControl-root': {
    width: '75px',
  }, '& .MuiOutlinedInput-input': {
    padding: '0 0 0 .5rem',
  },
}));


interface IDateRangeBlock {
  isEnableDay: boolean;
  dayOfWeek: {
    dayIdx: number; dayName: string;
  };
  fromTime: string;
  toTime: string;
  disabled?: boolean;

  onChange(dayOfWeek: number, fromTime: string, toTime: string, isEnableDay: boolean, isValid: boolean): void;
}

const initialTime = [moment('2000-01-01T00:00:00'), moment('2000-01-01T23:59:59')];

export default function DateRangeBlock({
                                         dayOfWeek: { dayIdx, dayName },
                                         fromTime,
                                         toTime,
                                         isEnableDay,
                                         onChange,
                                         disabled = false,
                                       }: IDateRangeBlock) {

  const [value, setValue] = React.useState<moment.MomentInput[]>(initialTime);
  const [isActiveDay, setActiveDay] = useState(true);

  useEffect(() => {
    setValue([moment(`2000-01-01T${fromTime}`), moment(`2000-01-01T${toTime}`)]);
    setActiveDay(isEnableDay);
  }, [fromTime, toTime, isEnableDay]);

  const changeDateHandler = (type: 'fromTime' | 'toTime' | 'enabledDay', newValue: React.SetStateAction<moment.MomentInput>) => {
    const isValid = moment(newValue as moment.MomentInput).isValid();
    let tmpValue: moment.MomentInput[] = value;
    let isEnabledDay = isActiveDay;
    if (isValid) {
      switch (type) {
        case 'enabledDay':
          isEnabledDay = !isActiveDay;
          setActiveDay(isEnabledDay);
          break;
        case 'fromTime':
          tmpValue = [newValue as moment.MomentInput, value[1]];
          break;
        case 'toTime':
          tmpValue = [value[0], newValue as moment.MomentInput];
          break;
      }
      setValue(tmpValue);
    }

    onChange(dayIdx, moment(tmpValue[0]).format('HH:mm:00'), moment(tmpValue[1]).format('HH:mm:59'), isEnabledDay, isValid);
  };

  return (<Wrapper>
    <Grid>
      <FormControlLabel
        control={<Checkbox checked={isActiveDay} onChange={changeDateHandler.bind(null, 'enabledDay', moment())} />}
        label={dayName}
        disabled={disabled}
      />

    </Grid>

    <TimePickerWrapper>
      <LocalizationProvider dateAdapter={AdapterMoment}>

        <TimeField
          ampm={false}
          value={value[0]}
          onChange={changeDateHandler.bind(null, 'fromTime')}
          disabled={disabled}
        />

        <TimeField
          ampm={false}
          value={value[1]}
          onChange={changeDateHandler.bind(null, 'toTime')}
          disabled={disabled}
        />

      </LocalizationProvider>
    </TimePickerWrapper>

  </Wrapper>);

}
