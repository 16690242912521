import * as React from 'react';
import { memo, useMemo, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MenuItem, StyledMenu } from './style/Popup';
import { IRow } from '../../Rows/Rows';
import { ISingleRow } from '../../../interface/interface.custom';
import { Loading } from '../../../../loading';

interface PopupStatusProps {
  row: IRow;

  onChange?({ row }: ISingleRow): Promise<boolean>;
}

export const PopupStatus = memo(({ row, onChange }: PopupStatusProps) => {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleOpenPopup = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const renderColor = useMemo(() => {
      return row.active ? '#048f0f' : '#ff0000';
    }, [row]);

    const handleClickItem = (active: boolean) => {
      if (onChange) {
        setLoading(true);
        const newRow = { ...row, active } as any;
        onChange({ row: newRow })
          .then((isChanged) => {
            if (isChanged) {
              setLoading(false);
              handleClose();
            }
          });
      }

    };

    if (isLoading) return <Typography align={'center'}> <Loading type={'small'} /></Typography>;

    return (
      <div>
        <Typography>{t(`statusClient.${row.active ? 0 : 1}`, 'ERROR')}
          <IconButton onClick={handleOpenPopup} sx={{ padding: 0 }}><MoreVertIcon
            sx={{ color: `${renderColor}` }} /></IconButton>
        </Typography>

        <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem onClick={handleClickItem.bind(null, true)}
                    bordercolor={'#048f0f'}>{t('module.common.filter.titleIsActive')}</MenuItem>
          <MenuItem onClick={handleClickItem.bind(null, false)}
                    bordercolor={'#ff0000'}>{t('module.common.filter.titleIsBlock')}</MenuItem>
        </StyledMenu>
      </div>
    );
  },
);

