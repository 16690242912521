import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { GridProps } from '@mui/material/Grid/Grid';

export const MainContainer = styled((props: GridProps) => (
  <Grid container {...props} />
))(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.primary.main,
  justifyContent: 'flex-start',
  minHeight: '100%',
  width: '100%',
  boxSizing: 'border-box',
  [theme.breakpoints.down('md')]: {},
}));
