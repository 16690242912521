import { IRow } from '../Rows/Rows';
import React, { useMemo } from 'react';
import { IColumn } from '../Columns/Columns';
import { Box, CardMedia, IconButton, TableCell, Tooltip, Typography } from '@mui/material';
import { Bool } from './FetchFormats/Bool';
import LinkOpacity from './FetchFormats/LinkOpacity';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useCustomTableContext } from '../../helpers/table.context';
import CellTextEditor from './FetchFormats/CellTextEditor';
import { ISingleRow } from '../../interface/interface.custom';
import DeleteRow from './FetchFormats/DeleteRow';
import moment from 'moment';
import CellPhoneEditor from './FetchFormats/CellPhoneEditor';
import CellCombobox from './FetchFormats/CellCombobox';
import CellComboboxMultiply from './FetchFormats/CellComboboxMultiply';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useParams, useSearchParams } from 'react-router-dom';
import { PopupStatusCard } from './FetchFormats/PopupStatusCard';
import { CardsProps } from '../../../../../interface';
import LinkIcon from './FetchFormats/LinkIcon';
import { PopupStatus } from './FetchFormats/PopupStatus';
import BadgeQty from './FetchFormats/BadgeQty';
import { InputCheckbox } from '../../../input';
import { SelectMini } from './FetchFormats/select';
import { Roles } from '../../../../../helpers';

interface ICell {
  column: IColumn;
  row: IRow;
  columnRows?: any;
  /**
   * Тип для открытия внутренней таблицы
   */
  collapsible?: {
    isOpen: boolean; handleToggleCollapsibleTable(): void;
  };

}

export default function FormatCell({
                                     column, row, columnRows, collapsible = {
    isOpen: false, handleToggleCollapsibleTable: () => console.log('Empty callback!'),
  },
                                   }: ICell) {

  const { param } = useCustomTableContext();
  const urlParams = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const renderCell = useMemo(() => {

    const value = row[column.field];
    const { cell: { format } } = column;

    switch (format) {

      case 'empty': {
        return <></>;
      }

      case 'boolean': {
        const { field } = column;
        return <Typography align={'center'}>{row[field] ? <CheckIcon /> : <CloseIcon />}</Typography>;
      }

      case 'select' : {
        const { cell: { fieldValue, data, onChange, disabled } } = column;
        return <SelectMini value={row[fieldValue]} data={data} onChange={(e) => onChange(row, e.target.value)}
                           disabled={disabled} />;
      }

      case 'limit-account-by-role': {
        const { cell: { fieldValue, onToggleSwitch, disabled } } = column;

        return [Roles.Admin, Roles.Driver].indexOf(row.roleName) !== -1 || row[fieldValue] === '-'
          ? ' '
          : (onToggleSwitch)
            ? <Bool row={row} onToggleSwitch={onToggleSwitch} value={value} disabled={disabled} />
            : <>You have no callback for this function</>;
      }

      case 'limit-cards-by-account': {
        const { cell: { fieldValue, onToggleSwitch, parentValue, disabled } } = column;
        return [Roles.Admin, Roles.Driver].indexOf(row.roleName) !== -1 || row[fieldValue] === '-' || !row[parentValue]
          ? ' '
          : (onToggleSwitch)
            ? <Bool row={row} onToggleSwitch={onToggleSwitch} value={value} disabled={disabled} />
            : <>You have no callback for this function</>;
      }

      case 'check-box': {
        const { field, cell: { onCheck, disabled } } = column;
        return (onCheck)
          ? <InputCheckbox label={``} onChange={(e: any) => onCheck(row, e)} defaultChecked={row[field]}
                           sx={{ margin: 0, '& .MuiButtonBase-root': { padding: 0 } }}
                           disabled={disabled}
          />
          : <>You have no callback for this function</>;
      }

      /**
       * Проверка на существование лимита
       */
      case 'check-card-limit' : {
        const { field, cell: { tooltip }, alignData } = column;

        return !row[field]
          ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: alignData, paddingBottom: '2px' }}>
            <Tooltip title={tooltip} placement={'top'}><ReportProblemOutlinedIcon color={'action'}
                                                                                  sx={{ fontSize: '1.0rem' }} /></Tooltip>
          </Box>
          : ' ';
      }


      /**
       * Выпадающее меню изменения статуса карты
       */
      case 'popup-status-card': {
        const { field, cell: { onToggleSwitch, allowAccessStatus } } = column;
        return <PopupStatusCard card={row as CardsProps} allowAccessStatus={allowAccessStatus}
                                onChange={onToggleSwitch} />;
      }

      /**
       * Выпадающее меню Активность
       */
      case 'popup-status': {
        const { field, cell: { onToggleSwitch } } = column;
        return <PopupStatus row={row} onChange={onToggleSwitch} />;
      }

      case 'switch-account':
        return (column.cell.onToggleSwitch) ?
          <Bool row={row} onToggleSwitch={column.cell.onToggleSwitch} value={value} /> : <>You have no callback
            for this function</>;

      case 'switch-account-tooltip': {
        const { cell: { switchTooltip } } = column;

        return (column.cell.onToggleSwitch)
          ? <Tooltip title={row.active ? switchTooltip[0] : switchTooltip[1]} placement='top'>
            <Typography><Bool row={row} onToggleSwitch={column.cell.onToggleSwitch}
                              value={value} /></Typography>
          </Tooltip>
          : <>You have no callback for this function</>;
      }


      case 'switch-card':
        return (column.cell.onToggleSwitch) ?
          <Bool row={row} onToggleSwitch={column.cell.onToggleSwitch} value={value} /> : <>You have no callback
            for this function</>;

      case 'switch-card-tooltip': {
        const { cell: { switchTooltip } } = column;

        return (column.cell.onToggleSwitch) ?
          <Tooltip title={row.status === 1 ? switchTooltip[0] : switchTooltip[1]} placement='top'>
            <Typography><Bool row={row} onToggleSwitch={column.cell.onToggleSwitch} value={value} /></Typography>
          </Tooltip> : <>You have no callback for this function</>;
      }


      case 'default-or-red-zero-field': {
        const { field, cell: { zeroCheckField } } = column;

        return row[zeroCheckField] === 0 ?
          <Typography color={'red'} fontWeight={'400'}>{row[field]}</Typography> : <>{row[field]}</>;
      }


      case 'link-opacity-card': {
        const { cell: { label, icon } } = column;

        const holderId = parseInt(urlParams.holderId as string);
        const accountId = parseInt(urlParams.accountId as string);//row.accountId === 0 ? parseInt(urlParams.accountId as string) : row.accountId;
        const filter = searchParams.get('filter') || '';

        const startPageCardList = searchParams.get('page') || '1';
        return <LinkOpacity
          link={`/holder/${holderId}/account/${row.accountId}/card/${row.cardId}/setting?page=${startPageCardList}&filter=${encodeURIComponent(filter)}`}
          label={`${label}`}
        />;
      }

      case 'link-setting-card': {
        const { cell: { label, icon } } = column;

        const holderId = parseInt(urlParams.holderId as string);
        const filter = searchParams.get('filter') || '';

        const startPageCardList = searchParams.get('page') || '1';
        return <LinkIcon
          link={`/holder/${holderId}/account/${row.accountId}/card/${row.cardId}?page=${startPageCardList}&filter=${encodeURIComponent(filter)}`}
          icon={icon}
        />;
      }

      case 'text-edit-limits': {
        const { field } = column;

        const matchLength = columnRows.filter((c: number) => c === -1);
        if (matchLength.length === columnRows.length) {
          return null;
        }

        /* Корректируем значимое поле amount */
        const onCommitChange = async ({ row }: ISingleRow) => {
          const newRow = { ...row, limitAmount: row[field] };
          return (column.cell.onChangeField) ? column.cell.onChangeField({ row: newRow }) : false;
        };

        if (row[field] === -1) return <></>;

        return <CellTextEditor type={'number'} row={row} field={field}
                               onCommitChanges={onCommitChange}
        />;
      }

      case 'text-edit-limits-or-red-zero-field': {
        const { field, cell: { zeroCheckField } } = column;

        const matchLength = columnRows.filter((c: number) => c === -1);
        if (matchLength.length === columnRows.length) {
          return null;
        }

        /* Корректируем значимое поле amount */
        const onCommitChange = async ({ row }: ISingleRow) => {
          const newRow = { ...row, limitAmount: row[field] };
          return (column.cell.onChangeField) ? column.cell.onChangeField({ row: newRow }) : false;
        };

        if (row[field] === -1) return <></>;

        return row[zeroCheckField] === 0 ? <Typography color={'red'}>
          <CellTextEditor type={'number'} row={row} field={field}
                          onCommitChanges={onCommitChange}
          />
        </Typography> : <CellTextEditor type={'number'} row={row} field={field}
                                        onCommitChanges={onCommitChange}
        />;


      }

      case 'text-or-empty-cell': {
        const { field } = column;
        const matchLength = columnRows.filter((c: number) => c === -1);
        if (matchLength.length === columnRows.length) {
          return null;
        }


        if (row[field] === -1) {
          return <></>;
        } else return row[field];
      }

      case 'text-edit': {
        const { field } = column;
        /* Корректируем значимое поле amount */
        const onCommitChange = async ({ row }: ISingleRow) => {
          const newRow = { ...row, [field]: row[field] };
          return (column.cell.onChangeField) ? column.cell.onChangeField({ row: newRow }) : false;
        };

        return <CellTextEditor type={'text'} row={row} field={field} onCommitChanges={onCommitChange} />;
      }

      case 'text-edit-phone': {
        const { field } = column;
        /* Корректируем значимое поле amount */
        const onCommitChange = async ({ row }: ISingleRow) => {
          const newRow = { ...row, [field]: row[field] };
          return (column.cell.onChangeField) ? column.cell.onChangeField({ row: newRow }) : false;
        };

        return <CellPhoneEditor row={row} field={field} onCommitChanges={onCommitChange} />;
      }

      case 'text-edit-email': {
        const { field } = column;
        /* Корректируем значимое поле amount */
        const onCommitChange = async ({ row }: ISingleRow) => {
          const newRow = { ...row, [field]: row[field] };
          return (column.cell.onChangeField) ? column.cell.onChangeField({ row: newRow }) : false;
        };

        return <CellTextEditor type={'email'} row={row} field={field} onCommitChanges={onCommitChange} />;
      }

      case 'combobox-edit': {
        const { field, cell: { array } } = column;
        /* Корректируем значимое поле amount */
        const onCommitChange = async ({ row }: ISingleRow) => {
          const newRow = { ...row, [field]: row[field] };
          return (column.cell.onChangeField) ? column.cell.onChangeField({ row: newRow }) : false;
        };


        return <CellCombobox value={row[field]} row={row} onCommitChanges={onCommitChange}
                             array={array} />;
      }

      case 'combobox-edit-multiply': {
        const { field, cell: { array, selectField, selectId, optionName } } = column;

        const onCommitChange = async ({ row }: ISingleRow) => {
          const newRow = { ...row, [field]: row[field] };
          return (column.cell.onChangeField) ? column.cell.onChangeField({ row: newRow }) : false;
        };

        const selected = row[selectField].map((f: any) => (f[selectId]));

        return <CellComboboxMultiply selected={selected} fieldId={selectId}
                                     optionName={optionName}
                                     array={array} />;
      }

      case 'icon-delete' : {
        const { cell: { labelConfirm } } = column;
        return <DeleteRow labelConfirm={labelConfirm}
                          onConfirmDelete={column.cell.onClickDelete} row={row} />;
      }

      case 'link-account': {
        const { cell: { label } } = column;
        const holderId = param.holderId ? param.holderId : 0;
        return <BadgeQty link={`/holder/${holderId}/account/${row.id}/cards`}
                         qty={row.numbersActiveCards} />;
      }

      case 'link-account-readonly': {
        const { cell: { label } } = column;
        return <Typography>{row.numbersActiveCards}</Typography>;
      }

      case 'link-opacity-client': {
        const { cell: { label } } = column;

        return <LinkOpacity link={`/clients/${row.id}/accounts`}
                            label={`${label}`} />;
      }

      case 'icon-before-text' : {
        const { cell: { icon } } = column;
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <column.cell.icon sx={{ marginRight: '.3rem' }} />
          {row[column.field]}
        </Box>;
      }

      case 'icon-download' : {
        const { cell: { onDownloadFile } } = column;

        const getIcon = () => {
          switch (row.contentType) {
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              return `icons-excel.png`;
            case 'application/pdf':
              return `icons-pdf.png`;
            default:
              return 'icons-unknown.png';
          }
        };

        return <>
          <CardMedia
            style={{ width: '32px', margin: '0 auto' }}
            component='img'
            image={`./img/${getIcon()}`}
            onClick={() => onDownloadFile ? onDownloadFile({ row }) : ({ row }: any) => {}}
          />
        </>;
      }

      case 'date-time' : {
        return moment(row[column.field]).format('DD.MM.YYYY HH:mm');
      }

      case 'join' : {
        const { cell: { fields } } = column;
        const findFields = fields.reduce((acc: any, field: string | number) => ([...acc, row[field]]), []);
        return findFields.join(' ');
      }

      case 'collapse': {
        const { isOpen, handleToggleCollapsibleTable } = collapsible;
        return <IconButton
          size='small'
          onClick={handleToggleCollapsibleTable}
          sx={{padding: '10px 10px'}}
        >
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>;
      }

      case 'numericalDigit': {
        return typeof row[column.field] === 'string'
          ? row[column.field]
          : row[column.field].formatNumber(2).toLocaleString();
      }


      default:
        return <>{row[column.field]}</>;
    }


  }, [column, row, collapsible]);


  return renderCell &&
    <TableCell style={{ cursor: 'pointer', padding:'1px 5px' }} align={column.alignData}>{renderCell}</TableCell>;
}


