import { InputFilled, Loading } from '../../../../../ui';
import { Suspense, useState } from 'react';
import ModalFilterCard from '../ModalFilterCard/ModalFilterCard';
import { useTranslation } from 'react-i18next';

interface ICardSelect {
  cardId: string;
  disabled: boolean;

  onApplyCardFilter(card: string): void;
}

export default function CardSelect({cardId, disabled = false, onApplyCardFilter }: ICardSelect) {
  const { t } = useTranslation();

  const [isOpenModal, setOpenModal] = useState(false);

  const onChangeSelectedCard = () => {
    !disabled && setOpenModal(true);
  };

  const handleApplyCardFilter = (card: string) => {
    onApplyCardFilter(card);
  };

  return <>
    <InputFilled
      label={t('module.reports.titleCard')}
      variant={'filled'}
      sx={{ '& input': { paddingRight: '2.5rem' } }}
      onClick={onChangeSelectedCard}
      onChange={onChangeSelectedCard}
      value={cardId}
      disabled={disabled}
    />
    {
      isOpenModal && <Suspense fallback={<Loading type={'fullScreen'} />}>
        <ModalFilterCard isShow={isOpenModal} handleClose={() => setOpenModal(false)}
                         handleApplyCardFilter={handleApplyCardFilter} />
      </Suspense>
    }
  </>;
}
