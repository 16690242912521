import { Grid, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery } from '@mui/material';
import { TTypeCollapsibleColumn } from '../../../../interface/interface.custom';
import { useEffect } from 'react';
import { IRow } from '../../../Rows/Rows';
import { useTranslation } from 'react-i18next';
import { purseService } from '../../../../../../../services';
import { Loading } from '../../../../../loading';
import { PurseProps } from '../../../../../../../interface';
import { getMediaQuery } from '../../../../../../../utils';

interface ICollapsibleType {
  type?: TTypeCollapsibleColumn;
  row: IRow;
}

export default function AccountPurses({ type, row }: ICollapsibleType) {
  /* Получаем языковую локаль */
  const { t } = useTranslation();
  /* Инициализация медиа  */
  const matches = useMediaQuery(getMediaQuery());

  const [getPurses, { data: dataPurses = { purses: [] }, isLoading }] = purseService.useLazyGetPursesByAccountIdQuery();

  useEffect(() => {
    getPurses({ accountId: +row.id });
  }, [row]);

  return (
    <Grid item xs={6} sx={{ margin: `${matches ? '1rem 0 1rem 1rem' : '1rem 0 1rem 10rem'}` }}>
      <Table size='small' aria-label='purchases'>
        <TableHead>
          <TableRow>
            <TableCell>{t('module.accounts.titlePurses')}</TableCell>
            <TableCell>{t('module.accounts.titleTotalBalanceWithoutHold')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            isLoading
              ? <Loading />
              : dataPurses.purses && dataPurses.purses.map((p: PurseProps) => (
              <TableRow hover key={p.id}>
                <TableCell>{p.remark}</TableCell>
                <TableCell>{p.amount}</TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </Grid>
  );
}
