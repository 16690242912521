import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useMemo } from 'react';
import { HolderGroupTitle, InnerContainer } from '../../../common';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '../../../../hooks';
import { cardInfoService } from '../../../../services';
import { CardInfo, CardLimit, CardSchedule } from './';
import ReplyIcon from '@mui/icons-material/Reply';

export default function CardSetting() {
  /* Получаем языковую локаль */
  const { t } = useTranslation();
  /* Получаем адренсную строку */
  const params = useParams<{ cardId: string, accountId: string, holderId: string }>();
  /* Инициализация медиа  */
  const matches = useMediaQuery();

  const pCardId = useMemo(() => {return params?.cardId ?? '1';}, [params?.cardId]);

  /* Получаем карту */
  const {
    data: dataCards = { cards: [] },
    isLoading: isLoadingCards,
  } = cardInfoService.useGetCardInfoQuery({ cardId: pCardId });

  const navigation = useNavigate();

  const handleBack = () => {
    navigation(-1);
  };

  return <>
    <InnerContainer direction={'left'} show={true}>

      <Grid container style={{ flexDirection: 'column' }}>
        <Grid display={'flex'} alignItems={'center'}>
          <IconButton sx={{ marginRight: '1rem' }} onClick={handleBack}>
            <ReplyIcon />
          </IconButton>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <HolderGroupTitle isLoading={isLoadingCards}
                              mt={1}
                              title={`${t('module.cards.titleID')}: ${dataCards.cards[0]?.cardId}`}
                              sx={{ marginLeft: `${matches ? '1rem' : '0'}` }} />
            {
              !isLoadingCards && !dataCards.cards[0]?.hasLimits &&
              <Typography fontSize={'.9rem'} color={'red'}
                          fontWeight={'bold'}>{t('module.cards.tooltipLabelWarning')}</Typography>
            }
          </Box>
        </Grid>
        <Grid item xs={12} display={'flex'} flexWrap={matches ? 'wrap' : 'nowrap'} sx={{ marginBottom: '1rem' }}>
          <CardInfo />
          <CardSchedule />
        </Grid>
        <CardLimit />
      </Grid>

    </InnerContainer>
  </>;
}
