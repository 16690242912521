import i18next from 'i18next';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { transformCatchError } from '../api/errors/transform-catch-error';
import { IReqSendUpdatePhoneOtc, ResponseCommonProps } from '../../interface';

export const registerDriverApi = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    updatePhoneOtc: builder.mutation<ResponseCommonProps, IReqSendUpdatePhoneOtc>({
      async queryFn({ otc }, {}, extraOptions, fetchWithBQ) {

        try {
          const result = await fetchWithBQ({
            url: `/cards/update/phone/otc`,
            method: 'post',
            params: {
              lang: i18next.language,
              otc,
            },
          });

          const data = checkErrors<ResponseCommonProps>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),
  }),
});
