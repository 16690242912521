import React, { ChangeEvent, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useActiveHolder, useMediaQuery, useValidation } from '../../../../../../hooks';
import { accountsService, cardMutation, transformResponseError } from '../../../../../../services';
import { Box, Grid } from '@mui/material';
import { ComboBox, HStack, InputFilled } from '../../../../../ui';
import { RequestCardVirtualCreateProps } from '../../../../../../interface';
import { initialRequest } from './config';
import { InputPhone } from '../../../../../ui';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

interface GetOrderVirtualCardProps {
  accountId: number;

  onClose(): void;
}

const phoneRegExp = /^38[0-9]{3}[0-9]{3}[0-9]{4}$/;

export const GetOrderVirtualCard = memo(({ accountId, onClose }: GetOrderVirtualCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { activeHolderId } = useActiveHolder();
  const notSelected = useMemo(() => ([{ id: -1, label: t('module.common.filter.titleNotSelected') }]), [t]);

  const [request, setRequest] = useState<RequestCardVirtualCreateProps>(initialRequest);
  const [requestAccountId, setRequestAccountId] = useState(-1);

  /* Получаем список счетов выбранного клиента */
  const { data: dataAccounts = { accounts: [] } } = accountsService.useGetAccountsByHolderIdQuery({ holderId: activeHolderId });

  const validationSchema = yup.object().shape({
    firstName: yup.string().required(t('validation.REQUIRED_FIELD')),
    mobile: yup.string().required(t('validation.REQUIRED_FIELD'))
      .matches(phoneRegExp, t('validation.ERROR_PHONE')),
  });
  const { validationError, register, handleSubmit, setValue } = useValidation(validationSchema);

  useEffect(() => {
    let newAccount: number;

    /* Если аккаунт не выбран или не подгрузился список счетов */
    if (accountId === 0 || dataAccounts.accounts.length === 0) newAccount = -1;
    else {
      /* Находим существующий id из списка, если что-то пошло не так выбираем "Не выбрано" */
      newAccount = dataAccounts.accounts.find(account => account.accountId === accountId)?.accountId || -1;
    }
    setRequestAccountId(newAccount);

  }, [dataAccounts, accountId]);


  const accountList = useMemo(() => {
    /* Если список счетов получен трансформируем под комбобокс */
    if (dataAccounts.accounts.length > 0) {
      return [...notSelected, ...dataAccounts.accounts.map(account => ({ label: `[${account.id}] ${account.remark}`, ...account }))];
    }

    return [...notSelected];
  }, [dataAccounts]);

  const handleAccountChange = (newAccountId: number) => {
    setRequestAccountId(newAccountId);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'mobile') {
      setValue('mobile', e.target.value);
    }
    setRequest(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const [createVirtualCard, { isLoading }] = cardMutation.useCreateVirtualCardMutation();

  const handleCommit = async () => {
    await createVirtualCard({ accountId: requestAccountId, request })
      .then(({ data, error }: any) => {
        const isError = !!error;
        transformResponseError({ isError, error: String(error), successToast: true });
        if (!isError) {
          onClose();
          navigate(`/holder/${activeHolderId}/account/${requestAccountId}/card/${data.cardId}`);
        }
      });
  };

  const matches = useMediaQuery();

  return (
    <Grid sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>

      <Grid xs={12}>

        <ComboBox
          textStyle={{ fontWeight: '700' }}
          data={accountList}
          fieldLabelOption={'label'}
          label={t('module.cards.limits.newLimit.title')}
          defaultValue={requestAccountId}
          fnOnChange={handleAccountChange}
          error={requestAccountId === -1}
        />
      </Grid>

      <HStack display={'flex'} flexWrap={matches ? 'wrap' : 'nowrap'}>
        <InputFilled
          name={'lastName'}
          label={t('module.common.filter.titleLastName')}
          sx={{ margin: matches ? '.5rem 0 0 0' : '.5rem .5rem 0 0' }}
          onChange={handleOnChange}
          value={request.lastName}
        />

        <InputFilled
          name={'firstName'}
          label={t('module.common.filter.titleFirstName')}
          sx={{ marginTop: '.5rem' }}
          onChange={handleOnChange}
          value={request.firstName}
          inputProps={register('firstName')}
          {...validationError('firstName')}
        />
      </HStack>
      <HStack display={'flex'} flexWrap={matches ? 'wrap' : 'nowrap'}>
        <InputPhone
          name={'mobile'}
          sx={{
            margin: matches ? '.5rem 0 0 0' : '.5rem .5rem 0 0',
            '& .Mui-disabled': { backgroundColor: 'transparent !important' },
          }}
          inputType={'filled'}
          value={request.mobile}
          onChange={handleOnChange}
          label={t('module.common.filter.titlePhone')}
          inputProps={register('mobile')}
          {...validationError('mobile')}
        />

        <InputFilled
          name={'vin'}
          label={t('module.common.filter.titleVIN')}
          sx={{ marginTop: '.5rem' }}
          onChange={handleOnChange}
          value={request.vin}
        />
      </HStack>
      <InputFilled
        name={'email'}
        label={t('module.setting.users.titleEmail')}
        sx={{ marginTop: '.5rem' }}
        onChange={handleOnChange}
        value={request.email}
      />


      <Grid sx={{ textAlign: 'right', marginTop: '.5rem' }}>
        <LoadingButton
          loading={isLoading}
          variant={'contained'}
          onClick={handleSubmit(handleCommit)}
          disabled={requestAccountId === -1}
        >{t('module.common.title.titleCreate')}</LoadingButton>
        <LoadingButton variant={'contained'} sx={{ marginLeft: '.5rem' }}
                       onClick={onClose}>{t('modal.labelCancel')}</LoadingButton>
      </Grid>
    </Grid>
  );
});
