import { saveActiveRole, setActiveHolderId } from '../../store';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { transformCatchError } from '../api/errors/transform-catch-error';
import { ResAuthApiServiceProps } from '../../interface';
import { setStorage } from '../storage/storage.service';
import { Storage } from '../../config';


export const tokenService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    retakeToken: builder.query<ResAuthApiServiceProps, { holderId: number }>({
      async queryFn({ holderId }, { dispatch }, extraOptions, fetchWithBQ) {
        try {
          const result: any = await fetchWithBQ({
            url: `/common/users/authorization/retake`,
            method: 'get',
            params: { holderId },
          });

          const data = checkErrors<ResAuthApiServiceProps>({ result, meta: result.meta });

          /* Авторизуемся */
          setStorage(Storage.token, data.token);
          setStorage(Storage.refreshToken, data.refreshToken);

          /* Устанавливаем холдера по умолчанию */
          dispatch(setActiveHolderId({ holderId: data.currentHolderId }));
          /* Устанавливаем активную роль */
          dispatch(saveActiveRole({ role: data.role }));

          return { data };

        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),

  }),
});
