import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InputFilled } from '../../../../../ui';
import { LoadingButton } from '@mui/lab';

interface IInputNewPassword {
  pass: string;
  rePass: string;
  isLoadingRegister: boolean;

  onChange(e: React.ChangeEvent<HTMLInputElement>): void;

  onUpdatePass(): void;
}

export default function InputNewPassword({
                                           pass,
                                           rePass,
                                           onChange,
                                           onUpdatePass,
                                           isLoadingRegister,
                                         }: IInputNewPassword) {
  const { t } = useTranslation();

  const [isError, setError] = useState(false);

  useEffect(() => {
    setError(pass !== rePass);
  }, [pass, rePass]);


  return <>
    <InputFilled label={t('module.register.titleEnterPass')} name={'pass'}
                 sx={{ '& > label': { color: `${isError && 'red'}` } }}
                 onChange={onChange}
                 type={'password'}
    />
    <InputFilled label={t('module.register.titleEnterRePass')} name={'rePass'}
                 sx={{ '& > label': { color: `${isError && 'red'}` } }}
                 onChange={onChange}
                 type={'password'}
    />

    <InputFilled label={t('module.register.titleOTP')} name={'otp'}
                 style={{ marginTop: '2rem' }}
                 onChange={onChange}
    />

    <LoadingButton
      loading={isLoadingRegister}
      variant='contained'
      style={{ width: '100%', marginTop: '.7rem', padding: '.7rem 0' }}
      onClick={onUpdatePass}
    >{t('module.register.btnUpdatePassOTP')}</LoadingButton>
  </>;
}
