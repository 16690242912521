import { ModalReducerState } from '../../../interface';


export const initialModalState: ModalReducerState = {
  isShowModal: false,
  children: undefined,
  buttonType: 0,
  labelOk: 'Yes',
  labelCancel: 'Cancel',
  callbackOk: () => {},
};
