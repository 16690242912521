import { memo, ReactNode, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { Title } from '../styles/Title';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../styles/LoadingButton';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { authorizationService, transformResponseError } from '../../../../services';
import { InputFilled, InputPhone } from '../../../ui';
import { CredentialsProps } from '../../../../interface';
import { LinkRegisterType } from '../../../../helpers';
import { useConfig } from '../../../../hooks';
import { useCustomStyles } from '../../../../styles';
import { FooterContainerButton, LinkRegister } from '../..';

export const Login = memo(() => {
  const { t, i18n } = useTranslation();
  const global = useCustomStyles();

  const config = useConfig();
  const navigation = useNavigate();


  const renderTitle = useMemo(() => {
    let str = t('config.locale.auth.enterCRM');

    if (config?.locale?.auth?.enterCRM) {
      str = config.locale.auth.enterCRM;
    }

    return str;
  }, [config?.locale?.auth?.enterCRM, t, i18n.language]);

  const [credentials, setCredentials] = useState<CredentialsProps>({ phone: '', password: '' });


  const validationSchema = yup.object().shape({
    phone: yup.string().required(t('error.REQUIRED_FIELD')),
    password: yup.string().required(t('error.REQUIRED_FIELD')),
  });

  const {
    register, handleSubmit, formState: { errors }, setValue,
  } = useForm({ resolver: yupResolver(validationSchema) });

  const handleOnChange = (type: 'text' | 'phone' | 'email' | 'password', e: any) => {
    let value: any = '';
    switch (type) {
      case 'email':
      case 'text': {
        value = { login: e.currentTarget.value.toString() };
        break;
      }
      case 'phone': {
        setValue('phone', e.target.value.toString());
        value = { phone: e.target.value.toString() };
        break;
      }
      case 'password':
        value = { password: e.currentTarget.value.toString() };
        break;
    }
    setCredentials(prev => ({ ...prev, ...value }));
  };

  const renderAuthTypeInput = useMemo(() => {
    switch (config.loginFormat) {
      case 1: // - телефон
        return (<InputPhone
          sx={{ marginTop: '1.2rem' }}
          inputType={'filled'}
          value={credentials.phone}
          onChange={handleOnChange.bind(null, 'phone')}
          label={t('module.login.titleLogin')}
          inputProps={register('phone')}
          error={!!errors.phone}
          helperText={errors.phone ? (errors?.phone?.message as ReactNode) : ' '}
        />);
      default: // - текст
        return (<InputFilled label={t('module.login.titleLogin')} onChange={handleOnChange.bind(null, 'text')}
                             value={credentials.phone} />);
    }

  }, [config.loginFormat, credentials.phone, errors.phone, register, t]);


  const [authorization, { isLoading }] = authorizationService.useLazyAuthorizationQuery();
  const handleUserAuth = () => {
    authorization({ username: credentials.phone, password: credentials.password })
      .then(({ isError, error, data }) => {
        transformResponseError({ isError, error: String(error) });
        if (!isError && data) {
          navigation(`/holder/${data.currentHolderId}/profile`);
        }
      });
  };


  const handleClickLinkRegister = (type: LinkRegisterType) => {
    type === LinkRegisterType.Register && navigation('/register', { replace: true });
    type === LinkRegisterType.ForgotPassword && navigation('/forgot-password', { replace: true });
  };

  return (
    <Grid sx={{ marginTop: 'auto' }}>
      <Title item className={global.titleBold}>{renderTitle}</Title>
      <Grid item className={global.textDefault}>{t('module.login.welcomeText')}</Grid>
      {renderAuthTypeInput}
      <InputFilled
        label={t('module.login.titlePassword')}
        onChange={handleOnChange.bind(null, 'password')}
        type={'password'}
        value={credentials.password}
        inputProps={register('password')}
        error={!!errors.password}
        helperText={errors.password ? (errors?.password?.message as ReactNode) : ' '}
      />
      <LoadingButton variant='contained' loading={isLoading} onClick={handleSubmit(handleUserAuth)}>
        {t('module.login.btnEnter')}
      </LoadingButton>
      <FooterContainerButton>
        <LinkRegister className={global.textDefault}
                      onClick={handleClickLinkRegister.bind(null, LinkRegisterType.Register)}>
          {t('module.login.btnRegister')}
        </LinkRegister> /
        <LinkRegister className={global.textDefault}
                      onClick={handleClickLinkRegister.bind(null, LinkRegisterType.ForgotPassword)}>
          {t('module.login.btnForgotPass')}
        </LinkRegister>
      </FooterContainerButton>
    </Grid>
  );
});
