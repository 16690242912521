import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit';
import { apiSlice, authorizationService, registerDriverApi, registerOrForgotService, registerOtpService } from '../services';
import { layoutReducer } from './reducers/layoutReducer';
import { modalReducer } from './reducers/modalReducer';


const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  [layoutReducer.name]: layoutReducer.reducer,
  [modalReducer.name]: modalReducer.reducer,
});

export const setupStore = () => {

  return configureStore({
    devTools: true,
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false })
   //   .concat(navigateMiddleware)
      .concat(apiSlice.middleware)
      .concat(registerDriverApi.middleware)
      .concat(authorizationService.middleware)
      .concat(registerOtpService.middleware)
      .concat(registerOrForgotService.middleware),
  });
};

// const navigateMiddleware: Middleware = () => (next) => (action) => {
//   if (action.type === 'layoutReducer/logOut') {
//     const navigate = useNavigate();
//     navigate('/login', { replace: true });
//   }
//   return next(action);
// };

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
