import { createTheme } from '@mui/material';
import { ukUA } from '@mui/material/locale';


export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#000',
      light: '#fff',
      dark: '#999',
      contrastText: '#d3d3d3',
    },
    secondary: {
      main: '#2C78D9',
      light: '#6ea6ff',
      dark: '#004da7',
      contrastText: '#fdfdfd',
    },
    contrastThreshold: 3,
    divider: '#000',
  },
}, ukUA);
