import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const CredentialsEnter = styled(Grid)(({theme})=>({
  display: "flex",
  alignItems: "left",
  position: "absolute",
  right: "15px",
  bottom: "15px",
  top: "15px",
  width: "25vw",
  borderRadius: "10px",
  backgroundColor: "#fff",
  padding: "3%",
  flexDirection: "column",
  justifyContent: "center",
  overflow: "hidden",
  [ theme.breakpoints.down( 'lg' ) ]: {
    width: "40vw"
  },
  [ theme.breakpoints.down( 'md' ) ]: {
    width: "inherit",
    height: "inherit",
    right: "10px",
    left: "10px",
    bottom: "15px",
    top: "7vh"
  }
}));
