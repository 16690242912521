import React, { memo } from 'react';
import { MenuContainer } from './style';
import { MenuItem } from './component/MenuItem';
import { useActiveRoutes } from '../../../../../hooks';
import { useTranslation } from 'react-i18next';

export const Menu = memo(() => {
    const { t } = useTranslation();
    const allowRoutes = useActiveRoutes();

    return (
      <MenuContainer>
        {
          allowRoutes.map(route => (
            <MenuItem key={route.link} title={t(route.title as any)} icon={route.icon} linkTo={route.link} />
          ))
        }
      </MenuContainer>
    );
  })
;
