import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useActiveHolder, useMediaQuery } from '../../../../hooks';
import React, { memo, useMemo } from 'react';
import { GridItem, Loading } from '../../../ui';
import { HolderGroupTitle, InfoBlock } from '../../../common';
import { HolderProps } from '../../../../interface';
import { useCustomStyles } from '../../../../styles';

export interface HolderDetailInfoProps {
  holder: HolderProps;
}

export const HolderDetailInfo = memo(({ holder }: HolderDetailInfoProps) => {
  const classes = useCustomStyles();
  const { t } = useTranslation();
  const matches = useMediaQuery();
  const { activeHolderId, activeHolder } = useActiveHolder();

  const renderTitle = useMemo(() => {
    if (activeHolder) {
      return <Box>
        <Typography className={classes.titleDefault}
                    style={{
                      fontSize: '1.5rem',
                      marginBottom: '.5rem',
                    }}>{t('module.dataGroup.infoClient')}:</Typography>
        <Typography>{activeHolder.fullName} ({t('module.clientList.titleID')}:{activeHolder.holderId})</Typography>
      </Box>;
    }
  }, [activeHolder, t]);


  if (!holder) {
    return <Loading type={'fullScreen'} />;
  }

  return <>
    {/*  Заголовок группы */}
    <HolderGroupTitle title={renderTitle} mt={1} />
    {/* Группа */}
    <Grid item xs={12}
          style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '1rem' }}>

      <GridItem item xs={12} md={4}>
        <InfoBlock title={t('module.clientList.titleStatus')} value={t(`statusClient.${activeHolder?.status}`)} />
      </GridItem>
      <GridItem item xs={12} md={4}>
        <InfoBlock title={t('module.clientList.titleOKPO')} sx={{ marginLeft:1, marginRight: 1}} value={holder.okpo} />
      </GridItem>
      <GridItem item xs={12} md={4}>
        <InfoBlock title={t('module.clientList.titleINN')} value={holder.itn} />
      </GridItem>
      <GridItem item xs={12} md={4}>
        <InfoBlock title={t('module.clientList.titlePhone')} value={holder.phone} />
      </GridItem>
      <GridItem item xs={12} md={4}>
        <InfoBlock title={t('module.clientList.titleEmail')} sx={{ marginLeft:1, marginRight: 1}} value={holder.email} />
      </GridItem>
      <GridItem item xs={12} md={4}>
        <InfoBlock title={t('module.clientList.titleAddress')} value={holder.regAddress} />
      </GridItem>
      <GridItem item xs={12} md={4}>
        <InfoBlock title={t('module.clientList.titleLegalAddress')} value={holder.regAddress} />
      </GridItem>
      <GridItem item xs={12} md={4}>
        <InfoBlock title={t('module.clientList.titleNumContract')} sx={{ marginLeft:1, marginRight: 1}} value={holder.contractNumber} />
      </GridItem>

    </Grid>
  </>;
});
