import { memo } from 'react';
import { GridItem, Loading } from '../../ui';
import { Box } from '@mui/material';
import { useCustomStyles } from '../../../styles';
import { HolderGroupTitleProps } from '../../../interface';


export const HolderGroupTitle = memo(({ title, isLoading = false, sx = {}, mt = 3 }: HolderGroupTitleProps) => {
  const classes = useCustomStyles();

  return (
    <GridItem item sx={{ ...sx }}>
      <Box
        style={{ marginTop: `${mt}rem`, fontSize: '1.5rem' }}
        className={classes.titleDefault}>
        {isLoading ? <Loading type={'small'} /> : title}
      </Box>
    </GridItem>
  );
});
