import { t } from 'i18next';
import { CellType, ct } from '../../../../ui/table/components/CellType/CellType';
import { IColumn } from '../../../../ui/custom-table/components/Columns/Columns';

export const getAccountsColumns = () => {

  return [
    {
      field: 'id',
      headerName: t('module.accounts.titleID'),
      width: 75,
      sortable: false,
    },
    {
      field: 'remark', sortable: false,
      headerName: t('module.accounts.titleRemark'),
      renderCell: ({ value }: any) => CellType({ type: ct.DEFAULT, value }),
      flex: 1,
    },
    {
      field: 'totalBalance', sortable: false,
      headerName: t('module.accounts.titleRest'),
      renderCell: ({ value }: any) => CellType({ type: ct.ROUND_2, value }),
      width: 120, align: 'center', headerAlign: 'center',
    },
    {
      field: 'overdraft', sortable: false,
      headerName: t('module.accounts.titleOverdraft'),
      renderCell: ({ value }: any) => CellType({ type: ct.ROUND_2, value }),
      width: 120, align: 'center', headerAlign: 'center',
    },
  ];
};


export const getColumns = (): IColumn[] => {
  return [
    {
      field: 'id',
      label: t('module.clientList.titleID'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'left',
      alignData: 'left',
      width: 75,
    },
    {
      field: 'remark',
      label: t('module.accounts.titleRemark'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'left',
      alignData: 'left',
    },
    {
      field: 'totalBalance',
      label: t('module.accounts.titleRest'),
      formatHeader: 'default',
      cell: { format: 'numericalDigit' },
      align: 'center',
      alignData: 'right',
      width: 120,
    },
    {
      field: 'overdraft',
      label: t('module.accounts.titleOverdraft'),
      formatHeader: 'default',
      cell: { format: 'numericalDigit' },
      align: 'center',
      alignData: 'right',
      width: 120,
    },
  ];
};

