import { styled } from '@mui/material/styles';
import { GridItem } from '../../../../../ui';

export const LocalGridItem = styled(GridItem)((props) => ({
  boxSizing: 'border-box',
  marginRight: '.7rem',
  padding: '.2rem',
  [props.theme.breakpoints.down('md')]: {
    margin: '.5rem 1rem',
  },
}));
