import i18n from 'i18next';
import { IColumn } from '../../ui/custom-table/components/Columns/Columns';
import { ISingleRow } from '../../ui/custom-table/interface/interface.custom';

interface UserListProps {
  handleDeleteUser(userId: number): void;

  handleDeleteOneHolder(userId: number): void;

  showUserCurrentHolder: boolean;
}

export const getColumns = ({
                             handleDeleteUser,
                             handleDeleteOneHolder,
                             showUserCurrentHolder,
                           }: UserListProps): IColumn[] => {

  const onClickDeleteUser = async ({ row }: ISingleRow) => {
    handleDeleteUser(row.id as number);
    return true;
  };

  const onClickDeleteOneHolder = async ({ row }: ISingleRow) => {
    handleDeleteOneHolder(row.id as number);
    return true;
  };

  return [
    {
      field: 'fio',
      label: i18n.t('module.setting.users.titleFio'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'left',
      alignData: 'left',
      sortable: true,
      width: 200,
    },
    {
      field: 'phoneNumber',
      label: i18n.t('module.setting.users.titlePhone'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'right',
      width: 100,
    },
    {
      field: 'email',
      label: i18n.t('module.setting.users.titleEmail'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'right',
      width: 200,
    },
    {
      field: 'active',
      label: i18n.t('module.setting.users.titleActive'),
      formatHeader: 'default',
      cell: { format: 'boolean' },
      align: 'center',
      alignData: 'center',
      width: 100,
    },
    {
      field: 'holdersLength',
      label: i18n.t('module.setting.users.titleHoldersLength'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      width: 100,
    },
    {
      field: 'commonHolderName',
      label: i18n.t('module.setting.users.titleHolderDefaultShort'),
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      width: 120,
    },
    {
      field: 'btnDeleteUser',
      label: '',
      formatHeader: 'default',
      cell: {
        format: 'icon-delete',
        onClickDelete: showUserCurrentHolder ? onClickDeleteOneHolder : onClickDeleteUser,
        labelConfirm: i18n.t(showUserCurrentHolder ? `module.common.modal.labelConfirmationDeleteUserCurrentHolder` : `module.common.modal.labelConfirmationDeleteUser`),
      },
      align: 'center',
      alignData: 'center',
      width: 10,
    },
  ];
};
