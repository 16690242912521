import React from 'react';
import { IMaskInput } from 'react-imask';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { TextFieldProps } from '@mui/material/TextField';

import { BaseTextFieldProps } from '@mui/material/TextField/TextField';
import InputFilled from './InputFilled';
import { useCustomStyles } from '../../../styles';

interface CustomProps {
  onChange: (e: { target: { name: string; value: string; } }) => void;
  placeholder: string;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref) {
  const { onChange, placeholder, ...other } = props;

  const onAccept = (value: any, mask: any) => {
    onChange({ target: { name: props.name, value: `${mask._unmaskedValue}` } });
  };

  return (<IMaskInput
    {...other}
    mask='xx(xxx) xxx-xx-xx'
    definitions={{ 'x': /\d/ }}
    placeholder={placeholder}
    onAccept={onAccept}
    overwrite
  />);
});

type IInputMask = BaseTextFieldProps & TextFieldProps & {
  inputType?: 'standard' | 'filled';
  InputProps?: any;
  label?: string;
  value: string;
  sx?: any;
}

export default function InputPhone({
                                     inputType = 'standard',
                                     value,
                                     label = '',
                                     sx = {},
                                     onChange,
                                     InputProps = {},
                                     ...other
                                   }: IInputMask) {
  const classes = useCustomStyles();

  if (inputType === 'filled') {
    return (<InputFilled
      value={value}
      label={label}
      onChange={onChange}
      InputProps={{ ...InputProps, inputComponent: TextMaskCustom as any }}
      placeholder={'38(0xx) xxx-xx-xx'}
      sx={{ '& .MuiFilledInput-root:before, .MuiFilledInput-root:after': { display: 'none' }, ...sx }}
      {...other}

    />);
  }


  return label !== ''
    ? <Box sx={{
      '& > :not(style)': { m: 1 }, overflow: 'hidden', ...sx,
    }}>
      <FormControl variant='standard' focused>
        <InputLabel htmlFor='formatted-phone-mask-input' className={classes.textDefault}
                    style={{ color: 'inherit' }}
        >{label.toUpperCase()}</InputLabel>
        <Input
          id={'formatted-phone-mask-input'}
          value={value}
          onChange={onChange}
          inputComponent={TextMaskCustom as any}
          placeholder={'38(0xx) xxx-xx-xx'}
        />
      </FormControl>
    </Box>
    : <Box sx={{
      '& > :not(style)': { m: 0 }, overflow: 'hidden', ...sx,
    }}>
      <Input
        id={'formatted-phone-mask-input'}
        value={value}
        onChange={onChange}
        inputComponent={TextMaskCustom as any}
        placeholder={'38(0xx) xxx-xx-xx'}
        sx={{ font: 'inherit', '& input': { padding: 0 } }}
      />
    </Box>;
}
