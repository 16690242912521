import { Loading } from '../../../ui';
import { InnerContainer } from '../../../common';
import { useTranslation } from 'react-i18next';
import { SCBoxContainer } from '../../../common';
import { cardDriverService } from '../../../../services';
import { SmartCard } from '../components';


export default function MyCard() {
  const { t } = useTranslation();
  const { data: dataCard, isLoading: isLoadingData } = cardDriverService.useGetCardDriverQuery('');


  if (isLoadingData) {
    return <SCBoxContainer><Loading type={'big'} /></SCBoxContainer>;
  }

  if (!dataCard) {
    return <SCBoxContainer>{t('module.cards.qr.notExistCard')}</SCBoxContainer>;
  }


  return (<InnerContainer direction={'left'} show={true} sx={{
    maxWidth: '380px', margin: '0 auto',
    '& .MuiBox-root:nth-of-type(2)>.slider-container>div:nth-of-type(n+2):nth-of-type(-n+3)': {
      alignItems: 'initial !important',
      top: '70px !important'
    },

  }}><SmartCard /></InnerContainer>);
}
