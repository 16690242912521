import { Storage } from '../../config';


export const getStorage = <T>(storageKey: Storage) => {
  return localStorage.getItem(storageKey) as T;
};

export const setStorage = (storageKey: Storage, value: any) => {
  return localStorage.setItem(storageKey, value);
};

export const removeStorage = (storageKey: Storage) => {
  return localStorage.removeItem(storageKey);
};
