import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { AccountProps, AccountsServiceProps, ISumTotal } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';


export const accountsService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getAccountsByHolderId: builder.query<AccountsServiceProps, { holderId: number, withChecked?: boolean }>({
      async queryFn({ holderId, withChecked = false }, {}, extraOptions, fetchWithBQ) {
        try {

          const result = await fetchWithBQ({
            url: `/holders/${holderId}/accounts`,
            params: { lang: getLangIso() },
          });

          let data = checkErrors<any>({ result, meta: result.meta });

          const sumTotalBalance: ISumTotal = {
            totalBalance: 0,
            totalBalanceWithoutOverdraft: 0,
            totalOverdraft: 0,
          };

          data = {
            ...data,
            accounts: data.accounts.map((account: AccountProps) => {

              const totalBalance = account.totalBalance + account.overdraft;

              sumTotalBalance.totalBalance += totalBalance;
              sumTotalBalance.totalOverdraft += account.overdraft;
              sumTotalBalance.totalBalanceWithoutOverdraft += account.totalBalance;

              const checked = (withChecked) ? { checked: false } : {};

              return ({
                ...account,
                ...checked,
                totalBalance,
                totalBalanceWithoutOverdraft: account.totalBalance,
              });
            }),
            sumTotal: sumTotalBalance,
          };

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      providesTags: () => ['Accounts'],
    }),
  }),
});
