import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { InputNewPassword, SendOTP } from './components';
import { useTranslation } from 'react-i18next';
import { toast } from '../../../../../utils';
import { registerOrForgotService, registerOtpService } from '../../../../../services';
import { LinkRegisterType } from '../../../../../helpers';

interface IStatePass {
  phone: string;
  pass: string;
  rePass: string;
  otp: string;
}

export default function ChangePassword() {
  const { t } = useTranslation();

  const [isSendOTP, setSendOTP] = useState<boolean>(false);
  const [newPass, setPass] = useState<IStatePass>({
    phone: '',
    pass: '',
    rePass: '',
    otp: '',
  });

  const [sendOTP, { isLoading: isLoadingSendOTP }] = registerOtpService.useLazySendOTPQuery();
  const [getRegister, { isLoading: isLoadingRegister }] = registerOrForgotService.useLazyRegisterOrForgotQuery();

  const handleChangePass = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newState = {
      ...newPass,
      [name]: value,
    };

    setPass(newState);
  };

  const handleOnChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPass(prev => ({ ...prev, phone: e.target.value }));
  };

  const handleSendOTP = () => {
    if (newPass.phone.length < 12) {
      toast({ type: 'error', message: t('error.1004') });
    } else {
      newPass.phone &&
      sendOTP({ phone: newPass.phone })
        .then(({ isError }) => {
          /* Если нет ошибки при отправки ОТП помечаем */
          !isError && setSendOTP(true);
        });
    }
  };

  const handleUpdatePass = () => {
    /* Действие с паролем */
    getRegister({
      otp: newPass.otp,
      phone: newPass.phone,
      password: newPass.pass,
      type: LinkRegisterType.ForgotPassword,
    });
    setSendOTP(false);
  };


  return <Grid item xs={12} md={6}>
    {
      isSendOTP
        ? <InputNewPassword onChange={handleChangePass} pass={newPass.pass} rePass={newPass.rePass}
                            onUpdatePass={handleUpdatePass} isLoadingRegister={isLoadingRegister} />
        : <SendOTP onChange={handleOnChangePhone} value={newPass.phone} onSendOTP={handleSendOTP}
                   isLoadingSendOTP={isLoadingSendOTP} />
    }
  </Grid>;
}
