import * as React from 'react';
import { memo } from 'react';
import { InnerContainer } from '../../common';
import { Grid } from '@mui/material';
import { Accounts } from './components';

export const MoneyTransfers = memo(() => {
  return (
    <InnerContainer direction={'left'} show={true}>
      <Grid item xs={12} md={9}>
        <Accounts />
      </Grid>
    </InnerContainer>
  );
});
