import './i18n/config';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { saveConfig, setupStore } from './store';
import { useAppDispatch, useAppSelector, useGlobalError } from './hooks';
import { Loading } from './components/ui';
import { RegisterDriver } from './components/module';
import './utils/main/prototype';
import { getStorage } from './services';
import { APP_DEV, Storage } from './config';
import { initialLayoutState } from './store/reducers/initial/inital-layout';
import { configService } from './services/query/config.service';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const store = setupStore();

const RootContainer = () => {

  const { i18n, t } = useTranslation();
  const { isLoading } = useAppSelector(state => state.layoutReducer);
  const dispatch = useAppDispatch();

  const { showGlobalMessage } = useGlobalError();

  const [getConfig] = configService.useLazyGetConfigQuery();

  const getLocalConfig = async () => {
    const config = await fetch('config.json')
      .then((response: any) => {
        return response.json();
      }).catch(err => {
        if (err) {
          showGlobalMessage(t('error.CONFIG_ERROR'));
        }
      });

    if (config) {

      dispatch(saveConfig({ ...initialLayoutState, ...config }));
    }
  };

  const getCustomBaseName = async () => {
    await getLocalConfig();
    if (!(APP_DEV === 'true')) {
      await getLocalConfig();
    } else getConfig('')
      .then(async (config) => {
        if (!(config.data?.profile)) {
          await getLocalConfig();
        }
      });
  };

  useEffect(() => {
    /* Проверяем если конфиг не загружен в систему подгружаем */
    if (!isLoading) getLocalConfig().then();

    /* Проверяем сохраненный язык */
    const lang = getStorage<string>(Storage.language);
    /* Проверяем что язык существует и отличный от основного*/
    if (lang && lang !== 'uk') {
      i18n.changeLanguage(lang);
    }


  }, []);

  if (!isLoading)
    return <Loading type={'fullScreen'} />;
  else
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ToastContainer />
        <Routes>
          <Route path={`bind-phone`} element={<RegisterDriver />} />
          <Route path={'*'} element={<App />} />
        </Routes>
      </BrowserRouter>
    );
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RootContainer />
    </Provider>
  </React.StrictMode>,
);
