import { styled } from '@mui/material/styles';
import { GridProps } from '@mui/material/Grid/Grid';
import { Grid } from '@mui/material';

export const DataItem = styled((props: GridProps) => (
  <Grid item {...props} />))(({ theme }) => ({
  flex: 1,
  margin: '0 .5rem .5rem 0',
  width: '100%',
  minHeight: '100%',
  borderRadius: '7px',
  backgroundColor: theme.palette.primary.light,
  padding: '1rem',
  position: 'relative',
  overflowX: 'hidden',
  [theme.breakpoints.down('md')]: {
    margin: '.3rem',
  },
}));
