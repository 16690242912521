import { Bounce, toast as ts } from 'react-toastify';
import { ToastProps } from '../../interface';


export const toast = ({message, type, autoCloseTimeout = 5000}: ToastProps) => {
  ts(
    message,
    {
      /* Тип всплывающей подсказки */
      type,
      /* Расположение */
      position: 'bottom-left',
      /* таймаут отображения */
      autoClose : autoCloseTimeout,
      /* Отображать ли прогрессбар до закрытия */
      hideProgressBar: false,
      /* Кнопка закрытия */
      closeOnClick: true,
      /* Останавливаться если навели мышкой */
      pauseOnHover: true,
      /* Можно закрыть смахивание в сторону */
      draggable: true,
      /* Стиль отображения */
      transition: Bounce,
    },
  );
};
