import { alpha, styled } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import * as React from 'react';
import MenuItemM, { MenuItemProps } from '@mui/material/MenuItem';

export const StyledMenu = styled((props: MenuProps) => (
  <Menu elevation={0} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    padding: 0,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

type MenuItemType = MenuItemProps & { bordercolor: string };

export const MenuItem = styled((props: MenuItemType) => (<MenuItemM {...props} />))(({ bordercolor }) => ({
  border: `3px solid ${bordercolor}`,
  '&:first-of-type': {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  '&:last-child': {
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  '&:not(:last-child)': {
    marginBottom: '2px',
  },
}));
