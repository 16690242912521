export {};

declare global {
  interface Array<T> {
    filterObj(searchField: string): T[];
  }

  interface Number {
    formatNumber(precision: number): string;
  }
}

if (!Array.prototype.filterObj) {

  // eslint-disable-next-line no-extend-native
  Array.prototype.filterObj = function(searchField) {

    return this;
  };

}

Number.prototype.formatNumber = function (precision: number): string {
  const formatter = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
  //@ts-expect-error Прототип форматирования чисел
  return formatter.format(this);
};




