import { TransformResponseErrorProps } from '../../../interface';
import { toast } from '../../../utils';
import i18next from 'i18next';

const isDebug = false;

export const transformResponseError = ({
                                         isError,
                                         error,
                                         successToast,
                                         successMessage = i18next.t('error.SUCCESS'),
                                         callback,
                                       }: TransformResponseErrorProps) => {

  /** Проверяем что это ошибка и выводим сообещние */
  if (isError) {
    toast({ message: error, type: 'error' });
    return;
  }

  /** Проверяем может необходимо обязательное подтверждение выполненнеия */
  if (successToast && isDebug) {
    toast({ message: successMessage, type: 'success' });
  }

  /** Если существует колбэк и ошибок никаких не было выполняем его */
  callback && callback();
};
