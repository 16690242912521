import React, { memo } from 'react';
import { InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FormControlMini } from './style';
import { useTranslation } from 'react-i18next';
import { IRow } from '../../../Rows/Rows';

export interface SelectDataProps {
  label: string;
  value: string;
}

interface SelectMiniProps {
  label?: string;
  value: string;
  data: SelectDataProps[];
  disabled?:boolean;
  onChange(event: SelectChangeEvent): void;
}

export const SelectMini = memo(({ value, label, data, onChange, disabled=false }: SelectMiniProps) => {
  const { t } = useTranslation();

  return (
    <FormControlMini>
      {
        label && <InputLabel>{label}</InputLabel>
      }
      <Select
        value={value}
        label={label}
        onChange={onChange}
        disabled={disabled}
      >
        <MenuItem value='-'><em>{t('module.common.filter.titleNotAccess')}</em></MenuItem>
        {
          data.map(item => (<MenuItem key={new Date().getTime().toString()} value={item.value}>{item.label}</MenuItem>))
        }
      </Select>
    </FormControlMini>
  );
});
