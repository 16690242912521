import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const ContainerForDate = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  [theme.breakpoints.down('md')]: {
    display: 'inherit',
  },
  '& .MuiInputBase-root>input': {
    paddingRight: 0,
  },
}));
