import React from 'react';
import { TextFieldProps } from '@mui/material/TextField';
import { BaseTextFieldProps } from '@mui/material/TextField/TextField';
import { TextFieldFilled } from '../../../styles';


export type IInputFilled = BaseTextFieldProps & TextFieldProps & { label: any } & { sx?: any };

export default function InputFilled({ label = 'title', sx = {}, onChange, value, ...other }: IInputFilled) {

  return (
    <TextFieldFilled
      label={label.toUpperCase()}
      onChange={onChange}
      variant={'filled'}
      sx={{ ...sx }}
      {...other}
      value={value}
    />
  );
}
