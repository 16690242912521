interface CardStatusProps {
  id: number;
  title: string;
  color: string;
}

export const cardStatus: CardStatusProps[] = [
  { id: 0, title: 'Активна', color: '#048f0f' },
  { id: 1, title: 'Блокована', color: '#ff0000' },
  { id: 2, title: 'Встановити ліміт', color: '#e17517' },
];
