import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { transformCatchError } from '../api/errors/transform-catch-error';
import { RequestUsersProps } from '../../interface';


export const usersMutation = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    createOrUpdateUsers: builder.mutation<void, RequestUsersProps>({
      async queryFn(request, {}, extraOptions, fetchWithBQ) {
        try {


          const url = (!request.id || request.id === -1)
            /* Если идентификатора пользователя не существует значит это добавление*/
            ? `/users/create`
            /* существует значит это редактирование */
            : `/users/${request.id}`;

          const commonHolderId = request.commonHolderId === -1 ? null : request.commonHolderId;

          const result = await fetchWithBQ({
            url,
            method: 'POST',
            body: { ...request, commonHolderId },
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      invalidatesTags: ['Users'],
    }),
    deleteUser: builder.mutation<void, { personalAccountUserId: number }>({
      async queryFn({ personalAccountUserId }, {}, extraOptions, fetchWithBQ) {
        try {

          const result = await fetchWithBQ({
            url: `/users/${personalAccountUserId}`,
            method: 'DELETE',
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      invalidatesTags: ['Users'],
    }),
    deleteOneHolderFromUser: builder.mutation<void, { personalAccountUserId: number, holderId: number }>({
      async queryFn({ personalAccountUserId, holderId }, {}, extraOptions, fetchWithBQ) {
        try {

          const request = {
            holders: [{
              holderId,
            }],
          };

          const result = await fetchWithBQ({
            url: `/users/${personalAccountUserId}/holders`,
            method: 'DELETE',
            body: { ...request },
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
      invalidatesTags: ['Users'],
    }),
  }),
});
