import { createContext, useContext } from 'react';
import { IFilterAccountsField, initialStateFilterAccount } from "./interface.filter";

type TContext = {
	filter: IFilterAccountsField | any;
}

export const AccountContext = createContext<TContext>( { filter: initialStateFilterAccount } );

export const useFilterAccountContext = () => useContext( AccountContext )

export default AccountContext;