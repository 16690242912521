import { useEffect } from 'react';
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { HolderGroupTitle } from '../../../common';
import { useTranslation } from 'react-i18next';
import { cardDriverService } from '../../../../services';

interface ILimitsCardDriver {
  cardId: string;
}

export const GridTitle = styled(Grid)(() => ({
  display: 'flex', alignItems: 'center', justifyContent: 'center', '&>.MuiGrid-root': {
    margin: 0,
  },
}));

const TableCellData = styled(TableCell)(() => ({
  textAlign: 'center',
}));


export default function LimitsCardDriver({ cardId }: ILimitsCardDriver) {
  const { t } = useTranslation();

  const [getCardLimit, {
    data: dataLimits = { limits: [] }, isLoading: isLoadingLimits,
  }] = cardDriverService.useLazyGetCardDriverLimitsQuery()

  useEffect(() => {
    cardId && getCardLimit({ cardId });
  }, [cardId]);


  return <>
    <Grid item style={{ marginTop: '1rem' }}>
      {/*
       <Grid textAlign={'center'}>
       <Button variant={'contained'} color={'error'}>Блокуваня карти</Button>
       </Grid>
       */}
      <GridTitle item xs={12} textAlign={'center'} sx={{ margin: '1rem' }}>
        <HolderGroupTitle isLoading={isLoadingLimits}
                          title={t('module.dataGroup.limits')} mt={0}
        />
      </GridTitle>

      <TableContainer sx={{ width: 310, margin: '1rem auto' }}>
        {dataLimits.limits.map(limit => (<Box key={limit.limitId} style={{
          border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f6f6f6',
        }} mb={2}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCellData colSpan={2}>{limit.purses}</TableCellData>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align={'left'}>{limit.type}</TableCell>
                {+limit.rest === limit.limitAmount
                  ? <TableCell align={'right'}>{limit.rest}</TableCell>
                  : <TableCell
                    align={'right'}>{`${limit.rest} ${t('module.common.title.titleOf')} ${limit.limitAmount.toFixed(2)}`}</TableCell>}

              </TableRow>
            </TableBody>
          </Table>
        </Box>))}
      </TableContainer>
    </Grid>
  </>;
}
