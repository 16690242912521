import React from 'react';
import { Box, Grid, Typography } from '@mui/material';


import { Container, GritEditItem } from './common';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { ISingleRow } from '../custom-table/interface/interface.custom';
import { Loading } from '../loading';
import { Bool } from '../custom-table/components/Cell/FetchFormats/Bool';
import { useCustomStyles } from '../../../styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

interface ISwitchBlock {
  row: any;
  title: string;
  labelInfo?: string;
  value: boolean;
  isLoading?: boolean;
  editable?: boolean;

  onChangeSwitch({ row }: ISingleRow): Promise<boolean>;

  sx?: SxProps<Theme>;
}

const style = {
  '& .MuiSwitch-root': {
    margin: 0,
  },
};

export default function SwitchBlock({
                                      title,
                                      row,
                                      value,
                                      labelInfo = '',
                                      editable = true,
                                      isLoading = false,
                                      onChangeSwitch,
                                      sx = {},
                                    }: ISwitchBlock) {
  const classes = useCustomStyles();

  return <>
    <Box sx={{ ...sx, ...style }}>
      <Container container>
        <Grid className={classes.textDefault}>{title}</Grid>
        <GritEditItem className={classes.titleDefault}>
          {
            isLoading
              ? <Loading type={'small'} />
              : <>
                <Typography>{labelInfo}</Typography>
                {
                  editable
                    ? <Grid item xs={1}> <Bool row={row} onToggleSwitch={onChangeSwitch} value={value} /> </Grid>
                    : value ? <CheckIcon /> : <CloseIcon />
                }
              </>
          }
        </GritEditItem>
      </Container>
    </Box>
  </>;
}
