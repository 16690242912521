import { alpha, styled } from '@mui/material/styles';
import { Select } from '@mui/material';

export const SelectMultiply = styled(Select)(({ theme }) => ({
  border: '1px solid #e2e2e1',
  overflow: 'hidden',
  borderRadius: 4,
  padding: 0,
  transition: theme.transitions.create([
    'border-color',
    'background-color',
    'box-shadow',
  ]),
  '&:hover': {
    border: '1px solid #000',
    backgroundColor: 'transparent',
  },
  '&.Mui-focused': {
    backgroundColor: 'transparent',
    boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
    borderColor: theme.palette.primary.main,
  },
  '&:not(.Mui-disabled) ': {
    '&.MuiSelect-multiple:focus, &.MuiInputBase-root': {
      backgroundColor: 'transparent',
    },
  },
  '&:before': { display: 'none' },
  '&:after': { display: 'none' },
}));
