import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { PursesServiceProps, PurseItemProps } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';


export const pursesListService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getPurses: builder.query<PursesServiceProps, any>({
      async queryFn({}, {}, extraOptions, fetchWithBQ) {
        try {
          const result = await fetchWithBQ({
            url: `/common/purses`,
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          data.purses = data.purses.map((item: PurseItemProps) => ({
            ...item, id: item.purseId,
          }));

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),
  }),
});
