import * as React from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Grid } from '@mui/material';
import { ApiKey, ChangePassword } from './components';

import { InnerContainer } from '../../common';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useCheckPermission } from '../../../hooks';
import { EditCurrentProfile } from './components/ProfileEdit/EditCurrentProfile';

export default function Setting() {
  const { t } = useTranslation();
  const { checkPermission } = useCheckPermission();
  const [value, setValue] = React.useState('1');

  const { config } = useAppSelector(state => state.layoutReducer);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };


  return <>
    <InnerContainer direction={'left'} show={true}>
      <Grid item xs={12} md={9}>
        <TabContext value={value}>
          <TabList onChange={handleChange}>
            <Tab label={t('module.setting.titleChangeProfile')} value='1' />
            <Tab label={t('module.setting.titleChangePass')} value='2' />
            {config.showPublicApiKey && checkPermission<boolean>({ accessPoint: 'setting.apiKey' }) &&
              <Tab label='API' value='3' />}
          </TabList>
          <TabPanel value='1'>
            <EditCurrentProfile />
          </TabPanel>
          <TabPanel value='2'>
            <ChangePassword />
          </TabPanel>
          <TabPanel value='3'>
            <ApiKey />
          </TabPanel>
        </TabContext>
      </Grid>
    </InnerContainer>
  </>;
}
