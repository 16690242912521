import { memo, useMemo } from 'react';
import { Transition } from './style';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button as Btn } from '../button/Button';
import { ButtonProps } from '@mui/material/Button/Button';
import { ModalButton } from '../../../config';
import { useAppSelector } from '../../../hooks';
import { t } from 'i18next';

const Button = styled((props: ButtonProps) => (
  <Btn  {...props} variant={'contained'} />))(({ theme }) => ({
  justifyContent: 'right',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
}));

export const ModalContainer = memo(() => {

  const {
    isShowModal,
    buttonType,
    children,
    title,
    labelOk = t('modal.labelOk'),
    labelCancel = t('modal.labelCancel'),
    callbackCancel,
    callbackOk,
  } = useAppSelector(state => state.modalReducer);

  const renderTitle = useMemo(() => {
    return title && <DialogTitle>{title}</DialogTitle>;
  }, [title]);

  const renderButton = useMemo(() => {

    if (buttonType === ModalButton.OkCancel) {
      return (
        <DialogActions>
          <Button onClick={callbackOk}>{labelOk}</Button>
          <Button onClick={callbackCancel}>{labelCancel}</Button>
        </DialogActions>
      );
    }

    if (buttonType === ModalButton.Ok) {
      return (
        <DialogActions>
          <Button onClick={callbackCancel}>{labelOk}</Button>
        </DialogActions>
      );
    }

    if (buttonType === ModalButton.Cancel) {
      return (
        <DialogActions>
          <Button onClick={callbackCancel}>{labelCancel}</Button>
        </DialogActions>
      );
    }

    if (buttonType === ModalButton.Empty) {
      return;
    }

  }, [buttonType, callbackOk, callbackCancel]);

  return (
    <Dialog
      open={isShowModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={callbackCancel}
      sx={{
        zIndex: 1301,
        //zIndex: 1300,
        '& .MuiPaper-root': {
          maxWidth: '1024px',
        },
      }}
    >
      {renderTitle}
      <DialogContent>
        <DialogContentText>
          {children}
        </DialogContentText>
      </DialogContent>
      {renderButton}
    </Dialog>
  );
});
