import { styled } from '@mui/material/styles';
import { ButtonProps } from '@mui/material/Button/Button';
import { Button as Btn } from '../../CurrentHolder/style';

type ButtonCustom = ButtonProps & { open: boolean }

export const Button = styled((props: ButtonCustom) => {
    return <Btn  {...props} sx={{
      '&:after': {
        borderTop: !props.open ? '5px solid #ccc' : '0px',
        borderBottom: props.open ? '5px solid #ccc' : '0px',
      },
    }} />;
  },
)(({  }) => ({
  minWidth: '50px',
}));
