import React, { memo } from 'react';
import { InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FormControlMini } from './style';
import { useTranslation } from 'react-i18next';

export interface SelectDataProps {
  label: string;
  value: string;
}

interface SelectMiniProps {
  label?: string;
  value: string;
  data: SelectDataProps[];
  disabled?: boolean;
  sx?: any;
  name?: string;
  additionalLine?: any;
  enableNotSelected?: boolean;

  onChange(event: SelectChangeEvent): void;
}

export const SelectMini = memo(({
                                  value,
                                  label,
                                  data,
                                  onChange,
                                  disabled = false,
                                  sx = {},
                                  name = '',
                                  enableNotSelected = true,
                                  additionalLine = null
                                }: SelectMiniProps) => {
  const { t } = useTranslation();
  return (
    <FormControlMini sx={{ ...sx }}>
      {
        label && <InputLabel>{label}</InputLabel>
      }
      <Select
        value={value}
        label={label}
        onChange={onChange}
        name={name}
        disabled={disabled}
      >
        {enableNotSelected && <MenuItem value='----'><em>{t('module.common.filter.titleNotSelected')}</em></MenuItem>}

        {
          additionalLine && additionalLine
        }

        {
          data.map(item => (<MenuItem value={item.value}>{item.label}</MenuItem>))
        }
      </Select>
    </FormControlMini>
  );
});
