import { InterfaceUtils } from './interface.utils';
import i18n from 'i18next';
import { AccountProps, HolderProps } from '../../../../../interface';

export const getFormatListAccount = (accounts: AccountProps[]) => {
  return [{
    id: 0,
    accountId: 0,
    remark: i18n.t('module.reports.titleAll'),
  },
    ...accounts.map(account => ({
      ...account,
      remark: account.id === -1
        ? account.remark
        : `[${account.id}] ${account.remark}`,
    }))];
};


export const getHolderIdFromParams = (params: InterfaceUtils, holders: HolderProps[]) => {
  if (params?.holderId !== undefined && parseInt(params.holderId) !== 0) {
    return parseInt(params.holderId);
  }

  if (holders.length > 0) {
    return holders[0].holderId;
  }

  return 0;
};

export const getAccountIdFromParams = (params: InterfaceUtils) => {
  if (params?.accountId !== undefined && parseInt(params.accountId) !== 0)
    return parseInt(params.accountId);
  else return 0;
};

export const getCurrentAccountIdFromParams = (params: InterfaceUtils) => {
  if (params?.currentAccount !== undefined && parseInt(params.currentAccount) !== 0)
    return parseInt(params.currentAccount);
  else return 0;
};
