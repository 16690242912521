import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { getToken } from '../../utils';
import { BASE_URL, Storage } from '../../config';
import { logOut } from '../../store';
import { getStorage, setStorage } from '../storage/storage.service';

const dynamicBaseQuery: BaseQueryFn = async (args, WebApi, extraOptions = {}) => {
  const token = getToken();

  const rawBaseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: headers => {
      if (token && token !== '') {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    ...extraOptions,
  });

  return rawBaseQuery(args, WebApi, extraOptions);

};

const baseQueryWithReauth: BaseQueryFn = async (args, api, extraOptions) => {
  let result: any = await dynamicBaseQuery(args, api, extraOptions);

  if (result?.error?.status === 401 ) { //|| result?.meta?.response?.status === 200
    /* Пытаемся переполучить токен если пришел 401 статус */
    const refreshArgs = {
      method: 'POST',
      body: { token: getToken(), refreshToken: getStorage(Storage.refreshToken) || '' },
    };
    const refreshResult: any = await dynamicBaseQuery('/common/users/authorization/refresh', api, { ...extraOptions, ...refreshArgs });

    /* Если ошибки нет, перезаписываем токен и продолжаем */
    if (refreshResult?.data?.errorCode === 0) {
      await new Promise<void>((resolve) => {
        setStorage(Storage.token, refreshResult.data.token);
        setStorage(Storage.refreshToken, refreshResult.data.refreshToken);
        setTimeout(() => {resolve();}, 500);
      }).then(async () => {
        /* Пытаемся выполнить запрос еще раз */
        result = await dynamicBaseQuery(args, api, extraOptions);
      });

    } else {
      api.dispatch(logOut());
    }
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: 'apiSlice',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Accounts', 'Cards', 'CardInfo', 'CardSchedule', 'CardLimit', 'ApiKey', 'Users', 'UserProfile'],
  endpoints: () => ({}),
});
