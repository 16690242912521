import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { RouteProps } from '../../interface';
import {
  Account, Profile, Cards, ReportTrn, Documents,
  MyCard, HelperLinks, Setting, CardSetting, Users, MoneyTransfers,
} from '../../components/module';



const absolutePath = '/holder/:holderId';

export const routes: RouteProps[] = [
  {
    type: 'profile',
    icon: <AccountBalanceIcon />,
    title: 'menu.profile.title',
    link: absolutePath + '/profile',
    isRefreshOnChangeHolder: true,
    components: <Profile />,
  },
  {
    type: 'accounts',
    icon: <AssignmentIcon />,
    title: 'menu.account.title',
    link: absolutePath + '/accounts',
    isRefreshOnChangeHolder: true,
    components: <Account />,
  },
  {
    type: 'cards',
    icon: <CreditCardIcon />,
    title: 'menu.cards.title',
    link: absolutePath + '/account/:accountId/cards',
    isRefreshOnChangeHolder: true,
    components: <Cards />,
  },
  {
    type: 'card-setting',
    link: absolutePath + '/account/:accountId/card/:cardId',
    isRefreshOnChangeHolder: false,
    components: <CardSetting />,
  },
  {
    type: 'reports',
    icon: <HistoryEduIcon />,
    title: 'menu.reports.title',
    link: absolutePath + '/account/:accountId/reports',
    isRefreshOnChangeHolder: true,
    components: <ReportTrn />,
  },
  {
    type: 'documents',
    icon: <ReceiptLongIcon />,
    title: 'menu.documents.title',
    link: absolutePath + '/documents',
    isRefreshOnChangeHolder: true,
    components: <Documents />,
  },
  {
    type: 'exchange',
    icon: <CurrencyExchangeIcon />,
    title: 'menu.exchange.title',
    link: absolutePath + '/exchange',
    isRefreshOnChangeHolder: true,
    components: <MoneyTransfers />,
  },
  {
    type: 'myCard',
    icon: <CreditCardIcon />,
    title: 'menu.myCard.title',
    link: absolutePath + '/my-card',
    isRefreshOnChangeHolder: true,
    components: <MyCard />,
  },
  {
    type: 'helpersLink',
    icon: <InfoIcon />,
    title: 'menu.helpers.title',
    link: absolutePath + '/helpers-link',
    isRefreshOnChangeHolder: true,
    components: <HelperLinks />,
  },
  {
    type: 'users',
    icon: <AccountCircleIcon />,
    title: 'menu.users.title',
    link: absolutePath + '/users',
    isRefreshOnChangeHolder: true,
    components: <Users />,
  },
  {
    type: 'setting',
    icon: <SettingsIcon />,
    title: 'menu.setting.title',
    link: absolutePath + '/setting',
    isRefreshOnChangeHolder: true,
    components: <Setting />,
  },

];
