import React, { memo } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import { OutlineBox } from '../../../../../ui';

interface ButtonClearProps {
  onClear(): void;
}

export const ButtonClear = memo(({ onClear }: ButtonClearProps) => {
  const { t } = useTranslation();
  return (
    <OutlineBox style={{
      padding: '2.1px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

    }} sx={{ '& p': { padding: 0 } }}
    >
      <IconButton color={'primary'} onClick={onClear} sx={{ padding: '1' }}>
        <Tooltip title={t('module.reports.tittleFilterClear')}>
          <ClearIcon fontSize={'large'} />
        </Tooltip>
      </IconButton>
    </OutlineBox>
  );
});
