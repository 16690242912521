import { getCardLimits, getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { CardLimitsServiceProps, CardsProps, CardsPropService, LimitsServiceProps, PursesServiceProps } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';
import { pursesListService } from './purse.list.service';
import { limitListService } from './limit.list.service';


export const cardDriverService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getCardDriver: builder.query<CardsPropService, any>({
      async queryFn({}, {}, extraOptions, fetchWithBQ) {
        try {

          const result = await fetchWithBQ({
            url: `/users/v2/cards`,
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          data.cards = data.cards && data.cards.map((card: CardsProps) => ({
            ...card,
            cardStatus: card.status === 0,
            firstName: card.cardInfo?.firstName || '',
            lastName: card.cardInfo?.lastName || '',
            mobile: card.cardInfo?.mobile || '',
            email: card.cardInfo?.email || '',
          }));

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),
    getCardDriverLimits: builder.query<CardLimitsServiceProps, { cardId: string }>({
      async queryFn({ cardId }, { dispatch }, extraOptions, fetchWithBQ) {
        try {

          const result = await fetchWithBQ({
            url: `/users/cards/${cardId}/limits`,
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          const purses = await dispatch(pursesListService.endpoints.getPurses.initiate(''));
          const limits = await dispatch(limitListService.endpoints.getLimits.initiate(''));

          getCardLimits({
            data,
            dataPurses: purses.data as PursesServiceProps,
            dataLimits: limits.data as LimitsServiceProps,
          });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),
  }),
});
