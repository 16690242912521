import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialModalState } from './initial/initial-modal';
import { ModalReducerState } from '../../interface';

//import { t } from 'i18next';
// labelOk: t('modal.labelOk'),
// labelCancel: t('modal.labelCancel'),

export const modalReducer = createSlice({
  name: 'modalReducer',
  initialState: initialModalState,
  reducers: {
    showModal(state: ModalReducerState, action: PayloadAction<Omit<ModalReducerState, 'isShowModal'>>) {
      return {
        ...initialModalState,

        ...action.payload,
        isShowModal: true,
      };
    },
    removeModal() {
      return { ...initialModalState };
    },
  },
});
