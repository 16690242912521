import { memo, useMemo, useState } from 'react';
import { Checkbox } from '@mui/material';

interface ICheckboxAll {
  onChange(checked: boolean | undefined): void;

  arrayChecked: boolean[];
}

export const CheckboxAll = memo(({ onChange, arrayChecked }: ICheckboxAll) => {

  const [checked, setChecked] = useState<boolean | undefined>(undefined);

  const handleChange = () => {
    setChecked(!checked);
    onChange(!checked);
  };

  const isIndeterminate = useMemo(() => {
    if (arrayChecked.length === 0) {
      return true; // Пустой массив считается неоднородным
    }

    const firstElement = arrayChecked[0];

    return !arrayChecked.every(element => element === firstElement);
  }, [arrayChecked]);

  const isChecked = useMemo(() => {
    if (checked) return checked;

    const allTrue = arrayChecked.every(element => element);
    if (allTrue) {
      return true;
    }

    const allFalse = arrayChecked.every(element => !element);
    if (allFalse) {
      return false;
    }
  }, [arrayChecked, checked]);

  return (
    <Checkbox
      checked={isChecked}
      indeterminate={isIndeterminate}
      onChange={handleChange}
    />
  );
});
