import { getLangIso } from '../../utils';
import { checkErrors } from '../api/errors/check-errors';
import { apiSlice } from '../api/api.slice';
import { HolderAttributes } from '../../interface';
import { transformCatchError } from '../api/errors/transform-catch-error';


export const attributesByHolderIdService = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getAttributesByHolderId: builder.query<HolderAttributes, { holderId: number }>({
      async queryFn({ holderId }, {}, extraOptions, fetchWithBQ) {
        try {

          /* Если холдер не указан */
          if (!holderId) {
            return { data : { attributes: [] } };
          }

          const result = await fetchWithBQ({
            url: `/holders/${holderId}/attributes`,
            params: { lang: getLangIso() },
          });

          const data = checkErrors<any>({ result, meta: result.meta });

          return { data };
        } catch (err: any) {
          return { error: transformCatchError(err) };
        }
      },
    }),
  }),
});
