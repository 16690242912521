import { Box, CardMedia, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';
import { TTypeCollapsibleColumn } from '../../../../interface/interface.custom';
import { useEffect, useState } from 'react';

import { IRow } from '../../../Rows/Rows';

import { styled } from '@mui/material/styles';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import { getMediaQuery } from '../../../../../../../utils';
import { useTranslation } from 'react-i18next';
import { ReportProducts } from '../../../../../../../interface';

interface ICollapsibleType {
  type?: TTypeCollapsibleColumn;
  row: IRow;
}

const TableCellCenter = styled(TableCell)(() => ({
  textAlign: 'center',
}));

export default function ReportTrnGoodsList({ type, row }: ICollapsibleType) {
  const { t } = useTranslation();
  /* Инициализация медиа  */
  const matches = useMediaQuery(getMediaQuery());

  const [newTable, setNewTable] = useState<IRow>(row);
  const [imageExists, setImageExists] = useState(true);

  useEffect(() => {
    setNewTable(row);

    const img = new Image();
    img.src = `./img/vendors/${row.vendorName}.png`;

    img.onload = () => {
      setImageExists(true);
    };

    img.onerror = () => {
      setImageExists(false);
    };

  }, [row]);

  const nullCheck = (field: string) => {
    return !field ? ' - ' : field;
  };

  return (<Grid item xs={7} sx={{ margin: `${matches ? '1rem 0 1rem 1rem' : '1rem 0 1rem 10rem'}` }}>
    <Box sx={{
      display: 'flex',
      alignItems: imageExists ? 'center' : 'flex-start',
      justifyContent: 'center',
      width: '100%',
      minHeight: '29px',
      borderTopLeftRadius: '3px',
      borderTopRightRadius: '3px',
      background: 'rgba(204,204,204,0.48)',
    }}>
      <>
        {
          imageExists
            ? <CardMedia style={{
              width: '48px',
              height: '24px',
              marginRight: '1rem',
              paddingTop: '.1rem',
              paddingBottom: '.5rem',
              justifySelf: 'left',
            }} component='img'
                         image={`./img/vendors/${row.vendorName}.png`} />
            : <><LocalGasStationIcon /><Typography ml={2} mr={2}>{row.vendorName}</Typography></>
        }
        <Typography align={'center'}>
          {row.terminalAddress}
        </Typography>
      </>
    </Box>
    <Table size='small' sx={{
      borderBottomLeftRadius: '3px',
      borderBottomRightRadius: '3px',
      marginBottom: '.5rem',
      background: 'rgba(204,204,204,0.48)',
    }}>
      <TableHead>
        <TableRow>
          <TableCellCenter
            style={{ fontWeight: '800' }}>{t('module.cards.titleVin')}</TableCellCenter>
          <TableCellCenter
            style={{ fontWeight: '800' }}>{t('module.cards.titleName')}</TableCellCenter>
          <TableCellCenter
            style={{ fontWeight: '800' }}>{t('module.cards.titlePhone')}</TableCellCenter>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCellCenter>{nullCheck(row.cardHolder?.numberPlate)}</TableCellCenter>
          <TableCellCenter>{`${nullCheck(row.cardHolder?.firstName)} ${nullCheck(row.cardHolder?.lastName)}`}</TableCellCenter>
          <TableCellCenter>{nullCheck(row.cardHolder?.phone)}</TableCellCenter>
        </TableRow>
      </TableBody>
    </Table>
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell>{t('module.reports.titleName')}</TableCell>
          <TableCellCenter>{t('module.reports.titlePrice')}</TableCellCenter>
          <TableCellCenter>{t('module.reports.titleQty')}</TableCellCenter>
          <TableCellCenter>{t('module.reports.titleDiscount')}</TableCellCenter>
          <TableCellCenter>{t('module.reports.titleSum')}</TableCellCenter>
        </TableRow>
      </TableHead>
      <TableBody>
        {newTable.products && newTable.products.map((p: ReportProducts, idx: number) => (
          <TableRow hover key={p.goodsId}>
            <TableCell>{p.name}</TableCell>
            <TableCellCenter> {p.price} </TableCellCenter>
            <TableCellCenter>{p.quantity}</TableCellCenter>
            <TableCellCenter>{p.discount}</TableCellCenter>
            <TableCellCenter>{p.amount}</TableCellCenter>
          </TableRow>))}
      </TableBody>
    </Table>
  </Grid>);
}
