import { getLangIso } from '../../utils';
import { apiSlice } from '../api/api.slice';
import { saveAs } from 'file-saver';

export const downloadFileMutation = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    downloadFile: builder.query<Blob, { url: string, fileName: string, body?: any }>({
      query: ({ url, fileName, body }) => ({
        url,
        method: body ? 'POST' : 'GET',
        body: body && { ...body },
        params: { lang: getLangIso() },
        responseType: 'blob',
        responseHandler: async (response: any) => {
          const blob = await response.blob();
          saveAs(blob, `${fileName}`);
          //const url = window.URL.createObjectURL(blob);
          //window.location.assign(url);
          //window.location.assign(window.URL.createObjectURL(await response.blob()))
        },
        cache: 'no-cache',
      }),
    }),

  }),
});
