/* Конвертирование числа месяца в текст */
export const convertMonthToString = (monthNumber: string) => {
  switch (monthNumber) {
    case '01':
      return 'січня';
    case '02':
      return 'лютого';
    case '03':
      return 'березня';
    case '04':
      return 'квітня';
    case '05':
      return 'травня';
    case '06':
      return 'червня';
    case '07':
      return 'липня';
    case '08':
      return 'серпня';
    case '09':
      return 'вересня';
    case '10':
      return 'жовтня';
    case '11':
      return 'листопада';
    case '12':
      return 'грудня';

  }
};
