import { styled } from '@mui/material/styles';
import { Typography, TypographyProps } from '@mui/material';

type OutlineTitleProps = TypographyProps & { textposition: 'left' | 'right' }

export const OutlineTitle = styled((props: OutlineTitleProps) => (<Typography {...props} />))(({ textposition }) => ({
  display: 'inline-block',
  position: 'relative',
  fontSize: '.8rem',
  padding: '0 .2rem',
  top: '-.65rem',
  color: ' rgba(0, 0, 0, 0.54)',
  backgroundColor: '#fff',
  right: textposition === 'left' ? '.1rem' : '0rem',
  left: textposition === 'right' ? '0rem' : '.1rem',
}));
